import Draggable from "react-draggable";
import * as FilterActionsThunk from "../../redux/features/filterSlice/thunk";
import * as FilterActions from "../../redux/features/filterSlice";
import * as UIActions from "../../redux/features/uiSlice";
import Collapse from "@mui/material/Collapse";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Status from "./Status";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import { useEffect, useState } from "react";
import ButtonList from "./ButtonList";
import SelectIcon from "@mui/icons-material/ArrowDropDown";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { css } from "@emotion/css";
import { BREAKPOINTS } from "../../constants";
import { CancerGroupPiece } from "../../types";
import { useAppDispatch, useAppSelector } from "../../redux/types";
import { SContentsDiv, SFormControl, SHeaderDiv, SIconButton, SOutterPaper } from "./emotion";
import useMediaQuery from "@mui/material/useMediaQuery";
const COMPONENT_NAME = "cancerType";

const CancerSelect = (props: any) => {
  const component = useAppSelector((state) => state.ui.cancerType);
  const { pos, expanded } = component;
  const { screen, order } = useAppSelector((state) => state.ui);
  const cancerFilter = useAppSelector((state) => state.filter.filters.cancergrp);
  const pcFilter = useAppSelector((state: any) => state.filter.filters.pc);
  const [dragging, setDragging] = useState<boolean>(false);
  const theme: any = useTheme();
  const dispatch = useAppDispatch();
  const { i18n, t } = useTranslation();
  const order2 = order.indexOf(COMPONENT_NAME);
  let zIndex = pos.x === 0 && pos.y === 0 ? UIActions.LAYERS.DRAGGABLE + order.length : UIActions.LAYERS.DRAGGABLE + order2;

  const TOOLTIP_STYLE = {
    tooltip: css(theme.components.tooltip.tooltip),
    tooltipPlacementTop: css(theme.components.tooltip.tooltipPlacementTop),
    tooltipPlacementBottom: css(theme.components.tooltip.tooltipPlacementBottom),
  };

  // Fetch cancer group data
  useEffect(() => {
    if (cancerFilter?.values.length === 0 && !cancerFilter?.loading) {
      dispatch(FilterActionsThunk.getCancerGroups({ lang: i18n.language }));
    }
  }, [cancerFilter?.values, cancerFilter?.loading, i18n.language, dispatch]);

  const handleChange = async (name: string, value: any) => {
    dispatch(FilterActionsThunk.updateFilter({ fieldName: pcFilter.FieldName, type: pcFilter.Type, _name: "", _value: 3 }));
    dispatch(FilterActionsThunk.updateFilter({ fieldName: cancerFilter.FieldName as keyof FilterActions.FilterState["filters"], type: cancerFilter.Type, _name: name, _value: parseInt(value) }));
    // Close select
    if (component.pos.x === 0 && component.pos.y === 0) {
      dispatch(UIActions.updateComponent({ name: COMPONENT_NAME, data: { expanded: false } }));
    }
  };
  /* ========================================== */
  // Handle Drag Start
  // ----------------
  // Sets the draggable state and permits drag
  // if target element is a div container
  // PROPS:
  //  event: Draggable component event
  /* ========================================== */
  const handleDragStart = (event: any) => {
    // Set dragging state if element is a div
    if (event.target.nodeName !== "DIV") return false;
    setDragging(true);
  };
  /* ========================================== */
  // Handle Drag Stop
  // ----------------
  // Sets the updated position state for the draggable
  // container after a drag action has occured
  // PROPS:
  //  event: drag event
  //  pos: updated position with x and y values
  /* ========================================== */
  const handleDragStop = (event: any, posLocal: any) => {
    // Update component's position via the UI Reducer
    const { x, y } = posLocal;
    dispatch(UIActions.updateComponent({ name: COMPONENT_NAME, data: { pos: { x: x, y: y } } }));
    // Bring component to front
    dispatch(UIActions.reorderDraggables(COMPONENT_NAME));
    // Stop Drag
    setDragging(false);
  };

  const isVerySmallScreen = useMediaQuery("(max-width:400px)");
  const iPhone6_7_8 = useMediaQuery("(max-width:490px)");

  function renderHeader() {
    return (
      <SHeaderDiv className={"dragHandle"} theme={theme}>
        <Tooltip id={`cancerType_select_tooltip`} classes={TOOLTIP_STYLE} title={t("cancer_select.tooltip")} placement="top" arrow disableFocusListener>
          <SFormControl theme={theme} screen={screen}>
            <Select
              IconComponent={() => null}
              // Only use select component for small widths
              classes={{
                //root: css(theme.components.select.select),
                select: css(theme.components.select.selectField),
              }}
              open={screen.width < BREAKPOINTS.WIDTH || screen.height < BREAKPOINTS.HEIGHT ? expanded : false}
              onOpen={() => {
                dispatch(UIActions.updateComponent({ name: COMPONENT_NAME, data: { expanded: true } }));
              }}
              onClose={() => {
                dispatch(UIActions.updateComponent({ name: COMPONENT_NAME, data: { expanded: false } }));
              }}
              onChange={(event: any) => {
                handleChange(cancerFilter.Title, event.target.value);
              }}
              input={
                // Disable underline
                <Input classes={{ root: css(theme.components.select.input) }} disableUnderline={true} />
              }
              value={cancerFilter?.value}
              name="cancerType"
            >
              {cancerFilter?.values.map((item: CancerGroupPiece, index: number) => {
                return (
                  <MenuItem key={index} value={item.grp}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </SFormControl>
        </Tooltip>
        <SIconButton
          theme={theme}
          aria-label={t("cancer_select.expand_tooltip")}
          color="primary"
          // Toggle Expand
          onClick={() => {
            dispatch(UIActions.updateComponent({ name: COMPONENT_NAME, data: { expanded: !expanded } }));
          }}
          size="large"
        >
          <SelectIcon />
        </SIconButton>
      </SHeaderDiv>
    );
  }

  return (
    <>
      {!cancerFilter?.loading ? (
        <Draggable
          defaultClassNameDragging={theme.components.draggable.dragging}
          handle=".dragHandle"
          bounds="body"
          position={{ x: pos.x, y: pos.y }}
          disabled={true}
          onStart={handleDragStart}
          onStop={handleDragStop}
        >
          {/* Style local as bug with layering */}
          <SOutterPaper
            theme={theme}
            className={props.className + `${screen.width < BREAKPOINTS.WIDTH || screen.height < BREAKPOINTS.HEIGHT ? "mobile" : ""}`}
            style={dragging ? { zIndex: 1000 } : { zIndex: zIndex }}
            isVerySmallScreen={isVerySmallScreen}
            iPhone6_7_8={iPhone6_7_8}
          >
            {renderHeader()}
            {screen.width >= BREAKPOINTS.WIDTH && screen.height >= BREAKPOINTS.HEIGHT && (
              <Collapse in={expanded} mountOnEnter unmountOnExit>
                <SContentsDiv>
                  <Status />
                  <ButtonList handleClick={handleChange} listValues={cancerFilter?.values ?? []} selectedValue={cancerFilter?.value ?? undefined} />
                </SContentsDiv>
              </Collapse>
            )}
          </SOutterPaper>
        </Draggable>
      ) : (
        <SOutterPaper
          theme={theme}
          className={props.className + `${screen.width < BREAKPOINTS.WIDTH || screen.height < BREAKPOINTS.HEIGHT ? "mobile" : ""}`}
          style={dragging ? { zIndex: 1000 } : { zIndex: zIndex }}
          isVerySmallScreen={isVerySmallScreen}
          iPhone6_7_8={iPhone6_7_8}
        >
          {t("messages:loading.data")}
        </SOutterPaper>
      )}
    </>
  );
};

export default CancerSelect;
