import Paper from "@mui/material/Paper";
import CheckIcon from "@mui/icons-material/CheckBox";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import styled from "@emotion/styled";
import { BREAKPOINTS } from "../../constants";

export const SFormControl = styled(FormControl)(({ theme, screen }: { theme: any; screen: any }) =>
	screen.width >= BREAKPOINTS.WIDTH
		? {
				...theme.components.select.formControlSmall,
				paddingLeft: ".5rem",
		  }
		: {
				...theme.components.select.formControlSmall,
				paddingLeft: "0.5rem",
				width: "calc(100% - 3rem)",
		  },
);

export const SIconButton = styled(IconButton)(({ theme }: { theme: any }) => ({
	...theme.components.select.selectButton,
}));

export const SHeaderDiv = styled("div")(({ theme }: { theme: any }) => ({
	...theme.components.select.header,
}));

export const SCheckIcon = styled(CheckIcon)(({ theme }: { theme: any }) => ({
	...theme.components.select.checkIcon,
	...theme.components.icon,
}));

export const SContentsDiv = styled("div")({
	paddingLeft: "1rem",
	paddingRight: "1rem",
	paddingTop: "0.5rem",
	paddingBottom: "0.5rem",
});

export const SOutterPaper = styled(Paper)(({ theme, isVerySmallScreen, iPhone6_7_8 }: { theme: any; isVerySmallScreen: boolean; iPhone6_7_8: boolean }) => ({
	//root
	...theme.layers.draggable,
	...theme.components.border,
	width: isVerySmallScreen ? "18rem" : "20rem",
	height: "auto",
	...(isVerySmallScreen && {
		width: "100%",
		maxWidth: "18rem",
		minWidth: "15rem",
	}),
	...(iPhone6_7_8 && {
		maxWidth: "15rem",
	}),
	"&.mobile": {
		margin: " 0 3rem",
		width: "100%", //"calc(100% - 0.5rem)"
	},
}));
