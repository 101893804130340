import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

export const SContainerDiv = styled("div")(({ theme }: { theme: any }) => ({
  position: "relative",
  color: theme.palette.text.primary,
  display: "inline-block",
  width: "98%",
  height: "100%",
  verticalAlign: "top",
}));

export const SLoadingSequenceDiv = styled("div")(({ theme }: { theme: any }) => ({
  ...theme.components.componentLoading.loadingSequence,
}));

export const SLoadingImg = styled("img")(({ theme }: { theme: any }) => ({
  ...theme.components.componentLoading.loadingImage,
}));

export const SLoadingTypography = styled(Typography)(({ theme }: { theme: any }) => ({
  ...theme.components.componentLoading.loadingText,
}));

export const SExpandedDiv = styled("div")(({ theme }: { theme: any }) => ({
  ...theme.layers.overlayMenu,
  left: 0,
  width: "22.5rem",
  height: "100%",
  position: "relative",
  transition: "all 300ms cubic-bezier(0, 0, 0.2, 1) 0ms",
  "&.mobile": {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
  },

  "&.exiting": {
    position: "absolute",
  },
}));

export const SMobileDiv = styled("div")(({ theme, expanded }: { theme: any; expanded: boolean }) => ({
  ...(expanded
    ? {
        ...theme.layers.overlayMenu,
        left: 0,
        width: "22.5rem",
        height: "100%",
        position: "relative",
        transition: "all 300ms cubic-bezier(0, 0, 0.2, 1) 0ms",
        "&.mobile": {
          position: "absolute",
          top: 0,
          width: "100%",
          height: "100%",
        },
        "&.exiting": {
          position: "absolute",
        },
      }
    : {
        ...theme.layers.overlayMenu,
        transition: "all 300ms cubic-bezier(0, 0, 0.2, 1) 0ms",
        width: "100%",
        height: "15rem",
      }),
}));
