import { useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import Tooltip from "@mui/material/Tooltip";
import SelectIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { css } from "@emotion/css";
import { SSelectedMenuItem, SIconButton, SHeaderDiv, SFormControl, SHeaderIconButton } from "./emotion";

interface ValuesProps {
  title: string;
  value: number;
}

interface SelectHeaderProps {
  title: string;
  label: string;
  tooltip: string;
  value: number;
  values: Array<ValuesProps>;
  disableSelect?: boolean;
  closeButton?: boolean;
  open?: boolean;
  onUpdate: Function;
  onClose?: any;
}

const SelectHeader = (props: SelectHeaderProps) => {
  const { disableSelect = true, closeButton = false, title, value, onUpdate, tooltip, label, onClose, values } = props;
  const [open, setOpen] = useState<any>(props.open ?? false);
  const theme = useTheme<any>();
  const { t } = useTranslation();
  const TOOLTIP_STYLE = {
    tooltip: css(theme.components.tooltip.tooltip),
    tooltipPlacementTop: css(theme.components.tooltip.tooltipPlacementTop),
    tooltipPlacementBottom: css(theme.components.tooltip.tooltipPlacementBottom),
  };

  const handleSelect = (event: any) => {
    const localValue = event.target.value;
    onUpdate(localValue);
    handleClose();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getSelectList = (values: ValuesProps[]) => {
    let list: Array<any> = [];
    list.push(
      values.map((item, index: number) => {
        return (
          <SSelectedMenuItem key={`${title}_select_${index}`} valueGlobal={value} index={index} theme={theme} value={item.value}>
            {item.title}
          </SSelectedMenuItem>
        );
      })
    );
    return list;
  };

  function renderButtons() {
    return [
      // Select Button
      <Tooltip key={`${title}_btnSelect`} id={`${title}_btnSelect_tooltip`} classes={TOOLTIP_STYLE} title={tooltip} placement="top" arrow disableFocusListener>
        <SIconButton
          theme={theme}
          aria-label={label}
          color="primary"
          // Toggle Select Menu State
          onClick={open ? handleClose : handleOpen}
          size="large"
        >
          <SelectIcon />
        </SIconButton>
      </Tooltip>,
      // Close Button
      closeButton && (
        <Tooltip key={`${title}_btnClose`} id={`${title}_btnClose_tooltip`} classes={TOOLTIP_STYLE} title={t("close")} placement="top" arrow disableFocusListener>
          <SHeaderIconButton theme={theme} aria-label="Close" color="primary" onClick={onClose} size="large">
            <CloseIcon />
          </SHeaderIconButton>
        </Tooltip>
      ),
    ];
  }

  return (
    <SHeaderDiv theme={theme}>
      <SFormControl theme={theme} closeButton={closeButton}>
        <Select
          IconComponent={() => null}
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          onChange={handleSelect}
          classes={{
            select: css(theme.components.select.selectField),
          }}
          input={
            // Disable underline
            <Input classes={{ root: css(theme.components.select.input) }} disableUnderline={true} />
          }
          disabled={disableSelect}
          value={value}
          name={`${title}Select`}
        >
          {getSelectList(values)}
        </Select>
      </SFormControl>
      {renderButtons()}
    </SHeaderDiv>
  );
};

export default SelectHeader;
