import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as HTMLVisualisationActions from "../redux/features/htmlVisualisationSlice";
import Popup from "./Popup";
import { styled } from "@mui/system";
import { useCallback, useEffect, useRef, useState } from "react";
import { PopupOrigin } from "../types";
import { remToPx } from "../styles/theme";
import { useAppDispatch, useAppSelector } from "../redux/types";
import { checkPopupBounds } from "./Popup/checkPopupBounds";

const SHTMLVisualisations = styled("div")(() => ({
  overflow: "hidden",
  zIndex: 900,
}));

const SCont = styled("div")(() => ({
  position: "absolute",
  zIndex: 900,
}));

interface HTMLVisualisationsProps {
  type?: PopupOrigin;
}

const HTMLVisualisations = ({ type = PopupOrigin.MAP }: HTMLVisualisationsProps) => {
  const { visPanels, errorId, message, sa2s } = useAppSelector((state) => state.htmlVisualisation);
  const { screen, dimensions } = useAppSelector((state) => state.ui);
  const [errorDialog, setErrorDialog] = useState<null | JSX.Element>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    window.addEventListener("keydown", handleWhenEscIsPressed);

    return () => {
      window.removeEventListener("keydown", handleWhenEscIsPressed);
    };
  }, []);

  const handleClose = (id: number) => {
    dispatch(HTMLVisualisationActions.removeHTMLVis({ id }));
  };

  const handleRequestClose = () => {
    dispatch(HTMLVisualisationActions.clearError());
  };

  const handleRequestDelete = () => {
    //dispatch(deleteLayer(errorId));
    dispatch(HTMLVisualisationActions.clearError());
  };

  const handleReorder = (id: number) => {
    dispatch(HTMLVisualisationActions.bringToFront({ id }));
  };

  function handleWhenEscIsPressed(e: KeyboardEvent) {
    if (e.key === "Escape") {
      dispatch(HTMLVisualisationActions.removeAllHTMLVis({ parentId: "iknl_popups" }));
    }
  }

  if (errorId) {
    setErrorDialog(
      <div>
        <Dialog open={true}>
          <DialogTitle>{"Error Visualising Popup"}</DialogTitle>
          <DialogContent>
            <DialogContentText>{message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRequestClose} color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  return (
    <SHTMLVisualisations id="iknl_popups">
      {visPanels.map((item: any, i: number) => {
        const { adj, x, y } = checkPopupBounds(item.coords, screen, dimensions);
        return (
          <Popup
            key={"popup_" + item.id}
            origin={item.origin}
            adjusted={adj}
            title={item?.panelConfig?.title}
            posDefault={item.coords}
            sa2={item.id}
            handleClose={handleClose}
            bringToFront={handleReorder}
            order={sa2s.findIndex((idx: any) => idx == item.id)}
          />
        );
      })}
      {errorDialog}
    </SHTMLVisualisations>
  );
};

export default HTMLVisualisations;
