import { useTheme } from "@mui/material/styles";
import { SScrollBarDiv, SButton } from "./emotion";

export enum VARIANTS {
  HORIZONTAL = "HORIZONTAL",
  VERTICAL = "VERTICAL",
}

interface ScrollbarProps {
  index?: number;
  length?: number;
  variant?: VARIANTS;
  handleSelect?: Function;
}

const Scrollbar = ({ index = 0, length = 1, variant = VARIANTS.HORIZONTAL, handleSelect }: ScrollbarProps) => {
  const theme = useTheme();

  const handleClick = (event: any) => {
    // Get value of selected scroll element
    let value = parseInt(event.target.value);
    handleSelect && handleSelect(value);
  };
  let elements = [];

  for (let i = 0; i < length; i++) {
    elements.push(<SButton key={i} value={i} onClick={handleClick} index={index} i={i} variant={variant} theme={theme} />);
  }
  return (
    <SScrollBarDiv theme={theme} variant={variant}>
      {elements}
    </SScrollBarDiv>
  );
};

// Export component
export default Scrollbar;
