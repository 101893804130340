import styled from "@emotion/styled";
import WindowButton from "../../WindowButton";

export const SRootDiv = styled("div")(({ width, height }: { width: number; height: number }) => ({
  cursor: "auto  !important",
  fontSize: "1rem",
  position: "relative",
  width: width + "rem",
  height: height + "rem",
  overflow: "hidden",
}));

export const SButton = styled("button")({
  margin: 0,
  textAlign: "center",
  border: "none",
  background: "#2f2f2f",
  color: "#888",
  padding: "0 4px",
  borderTop: "1px solid #0a0a0a",
});

export const SWindowBottom = styled(WindowButton)({});

export const S_ControlsDiv = styled("div")({
  position: "absolute",
  right: "1rem",
  top: "1rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  gap: "0.5rem",
});
