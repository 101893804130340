import Paper from "@mui/material/Paper";
import DraggableWindow from "../DraggableWindow/DraggableWindow";
import { styled } from "@mui/system";
import { BREAKPOINTS } from "../../constants";

export const SDraggableWindow = styled(DraggableWindow)(({ width, height }: { width: number; height: number }) => ({
  ...(width > BREAKPOINTS.WIDTH && height > BREAKPOINTS.HEIGHT && { maxHeight: "calc(100vh - 4rem)", overflowY: "auto" }),
}));

export const SSeperatorDiv = styled("div")(({ theme }: { theme: any }) => ({
  width: "100%",
  height: "0.5rem",
  borderTop: "0.05rem solid #6D6E70",
  borderBottom: "0.05rem solid #6D6E70",
  backgroundColor: theme.palette.background.dark,
}));

export const SPaper = styled(Paper)(({ theme }: { theme: any }) => ({ ...theme.components.border }));
