import { POPUP_HEIGHT, POPUP_WIDTH } from "../../constants";
import { Dimension } from "../../redux/features/uiSlice";
import { remToPx } from "../../styles/theme";

export const checkPopupBounds = (posDefault: { x: number; y: number }, screen: { width: number; height: number }, dimensions: Dimension[]) => {
  let { x, y } = posDefault;
  let adj: boolean = false;
  const popupWidth = remToPx(POPUP_WIDTH);
  const popupHeight = remToPx(POPUP_HEIGHT);
  const halfWidth = popupWidth / 2;

  if (x < halfWidth) {
    x = halfWidth;
    adj = true;
  } else if (x > screen.width - halfWidth) {
    x = screen.width - halfWidth;
    adj = true;
  }
  // Adjust y coordinate if out of bounds
  if (y < popupHeight) {
    y = popupHeight;
    adj = true;
  } else if (y >= screen.height) {
    y = screen.height - popupHeight;
    adj = true;
  }

  return { x, y, adj } as { x: number; y: number; adj: boolean };
};
