import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AppState } from "../../store";
import * as CancerAtlas from "../cancerAtlasSlice";
let ENDPOINTS = {
  DATA_URL: process.env.REACT_APP_DATA_URL,
};

export const requestPopupData = createAsyncThunk("cancerAtlas/requestPopupDataThunk", async ({ sex, postcode }: { sex: any; postcode: any }, thunkAPI) => {
  axios
    .get(`${ENDPOINTS.DATA_URL}cancer-datas/getbysexpostcode/${sex}/${postcode}`)
    .then((response) => {
      thunkAPI.dispatch(CancerAtlas.gotPopupData({ sex, postcode, data: response.data }));
    })
    .catch((error) => {
      return thunkAPI.dispatch(CancerAtlas.dataError());
    });
});

export const requestPCCancerData = createAsyncThunk("cancerAtlas/requestPCCancerDataThunk", async ({ ind, grp, sex, pc }: { ind: number; grp: number; sex: number; pc: number }, thunkAPI) => {
  thunkAPI.dispatch(CancerAtlas.fetchCancerData());
  axios
    .get(`${ENDPOINTS.DATA_URL}cancer-datas/getbygroupsexpostcode/${grp}/${sex}/${pc}`)
    .then((response) => {
      return thunkAPI.dispatch(CancerAtlas.gotCancerData({ ind, grp, sex, pc, data: response.data.res }));
    })
    .catch((error) => {
      return thunkAPI.dispatch(CancerAtlas.dataError());
    });
});

export const requestBothPCCancerData = createAsyncThunk("cancerAtlas/requestBothPCCancerDataThunk", async ({ sex }: { sex: any }, thunkAPI) => {
  const state = thunkAPI.getState() as AppState;

  const { cancerData } = state.cancerAtlas;
  if (cancerData != null && cancerData[0] != null && cancerData[0][sex] != null) {
    return;
  }
  thunkAPI.dispatch(CancerAtlas.fetchCancerData());
  axios
    .get(`${ENDPOINTS.DATA_URL}ncadata/${sex}`)
    .then((response) => {
      thunkAPI.dispatch(CancerAtlas.gotAllCancerData({ sex: sex, data: response.data }));
    })
    .catch((error) => {
      thunkAPI.dispatch(CancerAtlas.dataError());
    });
});

export const requestNewCancerData = createAsyncThunk("cancerAtlas/requestNewCancerDataThunk", async ({ indicator, sex }: { indicator: any; sex: any }, thunkAPI) => {
  thunkAPI.dispatch(CancerAtlas.fetchCancerData());
  axios
    .get(`${ENDPOINTS.DATA_URL}ncadata/${sex}/${indicator}`)
    .then((response) => {
      thunkAPI.dispatch(CancerAtlas.gotCancerData({ indicator: indicator, sex: sex, data: response.data }));
    })
    .catch((error) => {
      thunkAPI.dispatch(CancerAtlas.dataError());
    });
});

export const requestBothCancerData = createAsyncThunk("cancerAtlas/requestBothCancerDataThunk", async ({ sex }: { sex: number }, thunkAPI) => {
  const state = thunkAPI.getState() as AppState;

  const { cancerData } = state.cancerAtlas;

  if (cancerData != null && cancerData[0] != null && cancerData[0][sex] != null) {
    return;
  }
  thunkAPI.dispatch(CancerAtlas.fetchCancerData());
  axios
    .get(`${ENDPOINTS.DATA_URL}ncadata/${sex}`)
    .then((response) => {
      thunkAPI.dispatch(CancerAtlas.gotAllCancerData({ sex: sex, data: response.data }));
    })
    .catch((error) => {
      thunkAPI.dispatch(CancerAtlas.dataError());
    });
});

export const requestSA2Data = createAsyncThunk("cancerAtlas/requestSA2DataThunk", async ({ pc }: { pc: number }, thunkAPI) => {
  thunkAPI.dispatch(CancerAtlas.fetchSA2Data());
  axios
    .get(`${ENDPOINTS.DATA_URL}postcodes/getbypc/${pc}`)
    .then((response) => {
      thunkAPI.dispatch(CancerAtlas.gotSA2Data(response.data.res));
    })
    .catch((error) => {
      thunkAPI.dispatch(CancerAtlas.dataError());
    });
});

export const requestCancerDensity = createAsyncThunk(
  "cancerAtlas/requestCancerDensityThunk",
  async ({ sex, indicator, sa2, cancergrp }: { sex: any; indicator: any; sa2: any; cancergrp: any }, thunkAPI) => {
  const state = thunkAPI.getState() as AppState;
    
    const { densityLoadingList } = state.cancerAtlas;
    if (densityLoadingList.indexOf(indicator + "-" + sex + "-" + cancergrp + "-" + sa2) < 0) {
      thunkAPI.dispatch(CancerAtlas.fetchDensityData({ sa2, sex, indicator, cancergrp }));
      axios
        .get(`${ENDPOINTS.DATA_URL}ncadata/density/${sex}/${indicator}/${sa2}/${cancergrp}`)
        .then((response) => {
          thunkAPI.dispatch(CancerAtlas.gotDensityData({ sa2, data: response.data, sex, indicator, cancergrp }));
        })
        .catch((error) => {
          thunkAPI.dispatch(CancerAtlas.failedDensityData({ sa2, sex, indicator, cancergrp }));
        });
    }
  }
);

export const requestStoryData = createAsyncThunk("cancerAtlas/requestStoryDataThunk", async ({ locale }: { locale: string }, thunkAPI) => {
  const state = thunkAPI.getState() as AppState;
  
  const { loadingStories } = state.cancerAtlas;
  if (loadingStories) return;
  thunkAPI.dispatch(CancerAtlas.fetchStoryData());
  axios
    .get(`${ENDPOINTS.DATA_URL}stories?locale=${locale}&populate=*&sort=order`)
    .then((response) => {
      var storiesWithDefinedPanels = response.data.data.filter((story: any) => story.attributes.panels.length);
      thunkAPI.dispatch(CancerAtlas.gotStoryData(storiesWithDefinedPanels as any));
    })
    .catch((error) => {
      thunkAPI.dispatch(CancerAtlas.dataError());
    });
});

export const requestSummaryData = createAsyncThunk("cancerAtlas/requestSummaryDataThunk", async (_, thunkAPI) => {
  thunkAPI.dispatch(CancerAtlas.fetchSummaryData());
  axios
    .get(`${ENDPOINTS.DATA_URL}summaries`)
    .then((response) => {
      thunkAPI.dispatch(CancerAtlas.gotSummaryData(response.data.data));
    })
    .catch((error) => {
      thunkAPI.dispatch(CancerAtlas.summariesDataError());
    });
});
