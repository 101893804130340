// map range to val
export function map_range(value: number, low1: number, high1: number, low2: number, high2: number): number {
  var outputVal: number = low2 + ((high2 - low2) * (value - low1)) / (high1 - low1);
  if (outputVal < low2) outputVal = low2;
  else if (outputVal > high2) outputVal = high2;
  return outputVal;
}
// When one window.map_range just isn't enough..
export function map_range2(value: number, input_start: number, input_end: number, output_start: number, output_end: number): number {
  return ((value - input_start) / (input_end - input_start)) * (output_end - output_start) + output_start;
}

export function clamp(num:any, min:any, max:any){
  return Math.min(Math.max(num, min), max)
};

export const ChromeCheck = () => {
  // please note,
  // that IE11 now returns undefined again for window.chrome
  // and new Opera 30 outputs true for window.chrome
  // but needs to check if window.opr is not undefined
  // and new IE Edge outputs to true now for window.chrome
  // and if not iOS Chrome check
  // so use the below updated condition
  var isChromium: any = window.chrome;
  var winNav: any = window.navigator;
  var vendorName = winNav.vendor;
  // @ts-ignore
  var isOpera = typeof window.opr !== "undefined";
  var isIEedge = winNav.userAgent.indexOf("Edge") > -1;
  var isAndroid = winNav.userAgent.match("Android");
  var isIOSChrome = winNav.userAgent.match("CriOS");
  var isNotWindows = winNav.appVersion.indexOf("Win") == -1;
  var chromeVersionBad = false;
  try {
    chromeVersionBad = parseInt(winNav.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./)[2], 10) < 69;
  } catch (e) {}

  if (isIOSChrome || isAndroid || isNotWindows) {
    return false; // is Google Chrome on IOS
  } else if (isChromium !== null && typeof isChromium !== "undefined" && vendorName === "Google Inc." && isOpera === false && isIEedge === false && chromeVersionBad) {
    return true; // is Google Chrome
  } else {
    return false; // not Google Chrome
  }
};
