import Typography from "@mui/material/Typography";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import styled from "@emotion/styled";
import { VPLOT_FULLSCREEN_BG_HEIGHT, VPLOT_FULLSCREEN_MAX_WIDTH } from "../../../../constants";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import Button from "@mui/material/Button";

export const SVPlotContainerDiv = styled("div")(({ isFullscreen }: { isFullscreen: boolean }) => ({
  position: "relative",
  overflow: "visible",
  minHeight: "3rem",
  textAlign: "center",
  fontSize: `${isFullscreen ? "1.2rem" : ".65rem"}`,
  fontWeight: "300",
  padding: ".313rem .625rem",
  maxWidth: `${isFullscreen ? VPLOT_FULLSCREEN_MAX_WIDTH + "rem" : "18.75rem"}`,
  margin: "0 auto",
  lineHeight: "",
  ...(isFullscreen ? { marginTop: "2rem" } : {}),
  "& strong": {
    fontSize: "1.333em",
    fontWeight: "500",
    color: "#fff",
  },
}));

export const SFlexDiv = styled("div")(() => ({
  fontSize: ".94em",
  display: "flex",
  justifyContent: "space-between",
}));

export const SLowHighSpan = styled("span")(() => ({
  padding: "0 1rem",
  paddingBottom: "0.5rem",
}));

export const SArrowDownward = styled(ArrowDownward)(({ isFullscreen }: { isFullscreen: boolean }) => ({
  fontSize: `${isFullscreen ? "2em" : "1rem"}`,
  color: "rgb(255,244,188)",
}));

export const SGradientContainerDiv = styled("div")(() => ({
  height: ".4em",
  marginBottom: ".4em",
  display: "flex",
  flexFlow: "row nowrap",
  alignContent: "stretch",
}));

export const SGradientClampLDiv = styled("div")(() => ({
  background: "#2C7BB6",
  width: "35.375%",
}));

export const SGradientClampRDiv = styled("div")(() => ({
  background: "#D7191C",
  width: "35.375%",
}));

export const SGradientDiv = styled("div")(() => ({
  width: "29.25%",
  background: "linear-gradient(to right, #2C7BB6 0%, #ABD9E9 25%, #FFFFBF 50%, #FDAE61 75%, #D7191C 100%)",
}));

export const SVPlotBGDiv = styled("div")(({ isFullscreen }: { isFullscreen: boolean }) => ({
  position: "relative",
  width: "100%",
  height: `${isFullscreen ? VPLOT_FULLSCREEN_BG_HEIGHT + "rem" : "3.75rem"}`,
  borderBottom: ".063rem solid #59595c",
  "& div": { position: "absolute" },
  "& .halfMark": {
    height: "40%",
    width: "100%",
    background: "rgba(51,51,51,.8)",
  },
  "& .yAxis": {
    zIndex: 1,
    width: "3.75rem",
    transform: "rotate(-90deg)",
    left: "-1.563rem",
    top: "50%",
    marginTop: "-.938rem",
    textAlign: "left",
  },
  "& .yMarker50": {
    height: "100%",
    left: "50%",
    borderLeft: ".063rem dashed #59595c",
    marginLeft: "-.063rem",
  },
  "& .yMarker0": {
    height: "100%",
    left: "0",
    borderLeft: ".063rem solid #59595c",
  },
}));

export const SLoadingSequenceDiv = styled("div")(({ theme }: { theme: any }) => ({
  ...theme.components.componentLoading.loadingSequence,
}));

export const SScatterPlotFullscreenButton = styled(Button)({
  padding: "0",
  margin: "0",
  minHeight: "1.25rem",
  minWidth: "1.25rem",
  position: "absolute",
  bottom: "0.25rem",
  right: "0.25rem",
  border: "1px white solid",
});

export const SScatterPlotIcon = styled(ScatterPlotIcon)({
  fontSize: "1rem",
  padding: "0.25rem",
});

export const SHeaderTypography = styled(Typography)(({ theme }: { theme: any }) => ({
  color: theme.palette.common.white,
}));
