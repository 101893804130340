export const calcPCSelectPos = (numIndicators: number) => {
  switch (numIndicators) {
    case 0:
      return "19rem";
    case 1:
      return "29rem";
    case 2:
      return "33rem";
    default:
      return "";
  }
};
