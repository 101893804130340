import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as CancerAtlasActionsThunk from "../../redux/features/cancerAtlasSlice/thunk";
import { useAppDispatch, useAppSelector } from "../../redux/types";
import * as UIActions from "../../redux/features/uiSlice";
import DialogMenu from "../DialogMenu";
const COMPONENT_NAME = "storiesMenu";

const StoriesMenu = () => {
  const { visible } = useAppSelector((state) => state.ui.storiesMenu);
  const storyData = useAppSelector((state) => state.cancerAtlas.stories);
  const { cancerData, loadingStories } = useAppSelector((state) => state.cancerAtlas);
  const [values, setValues] = useState<Array<{ title: string; value: number; link: string }>>([]);
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    cancerData !== null && storyData.length === 0 && !loadingStories && dispatch(CancerAtlasActionsThunk.requestStoryData({ locale: i18n.language }));
  }, [cancerData, storyData, loadingStories]);

  //If storyData exists then setValues
  useEffect(() => {
    storyData &&
      setValues(
        storyData.map((story, index: number) => {
          return { title: story.attributes.title, value: index, link: `/tour/${story.slug}` };
        })
      );
  }, [storyData]);

  const handleSelect = (value: number) => {};

  const handleClose = () => {
    // Hide the component and reset index to 0
    dispatch(UIActions.updateComponent({ name: COMPONENT_NAME, data: { visible: false } }));
  };

  return <DialogMenu title={t("messages:tour.tourMenu")} open={visible} items={values} handleSelect={handleSelect} handleClose={handleClose} />;
};

export default StoriesMenu;
