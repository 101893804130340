import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PrevIcon from "@mui/icons-material/KeyboardArrowLeft";
import NextIcon from "@mui/icons-material/KeyboardArrowRight";
import styled from "@emotion/styled";

export const SOverlayImg = styled("img")(({ theme }: { theme: any }) => ({
  zIndex: theme.layers.overlay.zIndex - 1,
  position: "absolute",
  top: 0,
  left: 0,
}));

export const SCloseButtonIconButton = styled(IconButton)(({ theme }: { theme: any }) => ({
  display: "inline-block",
  verticalAlign: "top",
  padding: 0,
  minWidth: "1rem",
  minHeight: "1rem",
  width: "2rem",
  height: "2rem",
  color: theme.palette.common.black,
}));

export const SHeaderDiv = styled("div")({
  width: "calc(100% - 2rem)",
  minHeight: "2rem",
  height: "auto",
  margin: "1rem",
});

export const SSeparatorHr = styled("hr")(({ theme }: { theme: any }) => ({
  borderColor: theme.palette.common.black,
  margin: "0 1rem",
}));

export const SContentDiv = styled("div")(() => ({
  width: "calc(100% - 2rem)",
  height: "auto",
  margin: "1rem",
}));

export const SDescriptionTypography = styled(Typography)(({ theme }: { theme: any }) => ({
  color: theme.palette.common.black,
}));

export const SStepperDiv = styled("div")(({ theme }: { theme: any }) => ({
  width: "100%",
  height: "2rem",
  display: "inline-flex",
  alignItems: "center",
  backgroundColor: theme.palette.background.dark,
}));

export const SNextIcon = styled(NextIcon)({
  fontSize: "1.5rem",
});

export const SPrevIcon = styled(PrevIcon)({
  fontSize: "1.5rem",
});

export const SRootPaper = styled(Paper)(({ theme, pos, widthOvRd }: { theme: any; pos: any; widthOvRd: boolean }) => ({
  ...theme.layers.overlay,
  position: "absolute",
  width: `${widthOvRd ? 30 : 20}rem`,
  height: "auto",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.black,
  borderRadius: "0.5rem",
  ...pos,
}));

export const STitleTypography = styled(Typography)(({ theme }: { theme: any }) => ({
  display: "inline-block",
  width: "calc(100% - 2rem)",
  color: theme.palette.common.black,
}));
