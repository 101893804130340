import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import VPlotContainer from "../../Indicator/visualisations/VPlotContainer";
import { useAppSelector, useAppDispatch } from "../../../redux/types";
import * as UIActions from "../../../redux/features/uiSlice";
import * as HTMLVisualisationActions from "../../../redux/features/htmlVisualisationSlice";
import { SIconButton, SCloseIcon } from "./emotion";
interface Props {}

const ScatterplotDialog = (props: Props) => {
  const dispatch = useAppDispatch();
  const isScatterOpen = useAppSelector((state) => state.ui.scatterplotFullscreenVisible);
  const { sex, value } = useAppSelector((state) => state.ui["indicators"][0]);
  const { popupData } = useAppSelector((state) => state.htmlVisualisation);

  const handleClose = () => {
    dispatch(UIActions.updateFullscreenScatterplot({ isVisible: !isScatterOpen }));
    if (popupData !== null) {
      //Add the popup to visPanels
      dispatch(
        HTMLVisualisationActions.addHTMLVis({
          parentId: "iknl_popups",
          id: popupData.id,
          panelConfig: popupData.panelConfig,
          anchor: popupData.anchor,
          coords: popupData.coords,
        })
      );
    }
    //remove the popup from the redux store for scatterpopups
    dispatch(HTMLVisualisationActions.removeScatterplotPopup());
  };

  const handleClosePopup = () => {
    //Clear the store
    dispatch(HTMLVisualisationActions.removeScatterplotPopup());
  };

  return (
    <Dialog fullScreen open={isScatterOpen} onClose={handleClose} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">
        <SIconButton color="inherit" onClick={handleClose} aria-label="close">
          <SCloseIcon />
        </SIconButton>
      </DialogTitle>
      <DialogContent>
        <VPlotContainer isFullscreen={true} sexValue={sex} indicatorValue={value}></VPlotContainer>
      </DialogContent>
    </Dialog>
  );
};

export default ScatterplotDialog;
