import { styled } from "@mui/system";

export const SContentDiv = styled("div")({
  width: "calc(100% - 0.5rem)",
  height: "auto",
  paddingLeft: "0.5rem",
  textAlign: "center",
  overflowY: "auto",
  overflowX: "hidden",
});
