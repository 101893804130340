import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import TutorialIcon from "@mui/icons-material/HelpOutline";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import styled from "@emotion/styled";
import InfoIcon from "@mui/icons-material/Info";

export const SRootDialog = styled(({ ...other }: any) => {
  return (
    <Dialog
      {...other}
      classes={{
        paper: "paperClassesLocal",
      }}
    />
  );
})({
  "& .paperClassesLocal": {
    fontSize: "inherit",
    zIndex: 1000,
    backgroundColor: "rgba(64, 64, 65, 0.8)",
    "&.mobile": {
      fontSize: "12px",
    },
  },
});

export const SDialogContent = styled(DialogContent)({
  position: "relative",
  width: "100%",
  height: "calc(100% - 5em)",
  padding: 0,
  margin: 0,
  fontFamily: '"Source Sans Pro",sans-serif',
  "&:first-of-type": {
    padding: 0,
  },
});

export const SOverlayDesktopContDiv = styled("div")({
  fontSize: "1.2em",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  position: "absolute",
  height: "100%",
  width: "100%",
});

export const SOverlayMobileContDiv = styled("div")({
  fontSize: "1em",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  position: "absolute",
  height: "100%",
  width: "100%",
});

export const SCloseIconButton = styled(IconButton)(({ theme }: { theme: any }) => ({
  position: "absolute",
  right: "1rem",
  top: "1rem",
  color: theme.palette.text.primary,
}));

export const SOverlayMobileInnerDiv = styled("div")({
  width: "18em",
  textAlign: "center",
  color: "#FFF",
  fontWeight: 300,
});

export const STitle1H1 = styled("h1")({
  fontSize: "1.5em",
  "&.mobile": {
    fontSize: "1.5em",
  },
});

export const SBubbleContMobDiv = styled("div")({
  width: "100%",
  margin: "0 auto",
  display: "flex",
  justifyContent: "space-between",
});

export const SBubbleMobDiv = styled("div")({
  background: "#FFFFBF",
  width: "48%",
  borderRadius: ".375em",
  height: "10.375em",
  boxSize: "border-box",
  color: "#000",
  "&:first-of-type": {
    textAlign: "left",
    background: "#2C7BB6",
    color: "#FFF",
  },
  "&:last-of-type": {
    textAlign: "right",
    background: "#D7191C",
    color: "#FFF",
  },
  "& span": {
    display: "block",
    fontSize: ".875em",
    lineHeight: "1.25em",
    margin: "1.429em .857em",
  },
});

export const SBubbleArrowMobDiv = styled("div")(() => ({
  width: "2em",
  textAlign: "center",
  fontSize: ".85em",
  "& span": {
    display: "block",
    margin: "0 auto",
    width: 0,
    height: 0,
    borderLeft: ".375em solid transparent",
    borderRight: ".375em solid transparent",
    borderTop: ".375em solid #FFFFBF",
  },
  "&:first-of-type span": { borderTopColor: "#2C7BB6" },
  "&:last-of-type span": { borderTopColor: "#D7191C" },
}));

export const SFlexFullMobDiv = styled("div")(() => ({
  fontSize: ".94em",
  margin: ".625em auto 0",
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
}));

export const SGradientContainerFullMobDiv = styled("div")(() => ({
  height: ".625em",
  margin: "0 auto",
  display: "flex",
  flexFlow: "row nowrap",
  alignContent: "stretch",
  width: "100%",
}));

export const SGradientClampLDiv = styled("div")({
  background: "#2C7BB6",
  width: "37.5%",
});

export const SGradientDiv = styled("div")(() => ({
  width: "25%",
  background: "linear-gradient(to right, #2C7BB6 0%, #ABD9E9 25%, #FFFFBF 50%, #FDAE61 75%, #D7191C 100%)",
}));

export const SGradientClampRDiv = styled("div")(() => ({
  background: "#D7191C",
  width: "37.5%",
}));

export const SBubbleArrowFullMobArrowDiv = styled("div")({
  marginTop: ".375em",
  "& span": {
    display: "block",
    margin: "0 auto",
    width: 0,
    height: 0,
    borderLeft: ".375em solid transparent",
    borderRight: ".375em solid transparent",
    borderBottom: ".375em solid #FFFFBF",
  },
  "&:last-of-type span": { borderBottomColor: "#D7191C" },
  width: "100%",
  textAlign: "center",
  "&:first-of-type span": { borderBottomColor: "#FFFFBF" },
});

export const SBubbleMobBubbleFullDiv = styled("div")({
  background: "#FFFFBF",
  borderRadius: ".375em",
  boxSize: "border-box",
  color: "#000",
  "&:last-child": {
    textAlign: "right",
    background: "#D7191C",
    color: "#FFF",
  },
  "& span": {
    display: "block",
    fontSize: ".87em",
    lineHeight: "1.25em",
    margin: "1.429em .857em",
  },
  width: "100%",
  height: "auto",
  "&:first-of-type": {
    background: "#FFFFBF",
    color: "#000",
    textAlign: "center",
  },
});

export const SDisclaimerDiv = styled("div")(({ theme }: { theme: any }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: "0.375em",
  boxSize: "border-box",
  border: "1px solid " + theme.palette.primary.main,
}));

export const SDisclaimerContDiv = styled("div")(() => ({
  display: "flex",
  margin: "0.5em 0.857em",
  gap: "0.25rem",
  flexDirection: "column",
  alignItems: "center",
  "& span": {
    fontSize: "1em",
    lineHeight: "1.25em",
  },
}));

export const SDialogActions = styled(DialogActions)(() => ({
  margin: 0,
  height: "5em",
  justifyContent: "center",
}));

export const SButton = styled(Button)(({ theme }: { theme: any }) => ({
  margin: 0,
  height: "100%",
  width: "25%",
  borderTop: "0.05em solid " + theme.palette.primary.main,
  color: theme.palette.common.white,
  transition: "all 200ms ease",
  borderRadius: 0,
  "&:hover": {
    color: theme.palette.common.black,
    backgroundColor: "rgba(17, 181, 233, 0.8)",
    "& a": {
      color: theme.palette.common.black,
    },
  },
  "& a": {
    color: theme.palette.common.white,
    textDecoration: "none",
  },
}));

export const SCheckIcon = styled(CheckIcon)(() => ({
  marginRight: "1em",
}));

export const SCloseIcon = styled(CloseIcon)(() => ({
  fontSize: "1.5em",
}));

export const SInfoIcon = styled(InfoIcon)(() => ({
  fontSize: "1.5em",
}));

export const SOverlayDesktopInnerDiv = styled("div")(() => ({
  textAlign: "center",
  color: "#FFF",
  fontWeight: 300,
}));

export const SFlexDiv = styled("div")(() => ({
  fontSize: ".94em",
  width: "23.5em",
  margin: ".625em auto 0",
  display: "flex",
  justifyContent: "space-between",
}));

export const SArrowDownward = styled(ArrowDownward)(() => ({
  fontSize: "1.5em",
  color: "rgb(255,244,188)",
}));

export const SGradientContainer = styled("div")({
  height: ".625em",
  width: "23.55em",
  margin: "0 auto",
  display: "flex",
  flexFlow: "row nowrap",
  alignContent: "stretch",
});

export const SBubbleContDiv = styled("div")(() => ({
  width: "32em",
  margin: "0 auto",
  display: "flex",
  justifyContent: "space-between",
}));

export const SBubbleArrowDiv = styled("div")({
  width: "10.25em",
  textAlign: "center",
  marginTop: ".375em",
  "& span": {
    display: "block",
    margin: "0 auto",
    width: 0,
    height: 0,
    borderLeft: ".375em solid transparent",
    borderRight: ".375em solid transparent",
    borderBottom: ".375em solid #FFFFBF",
  },
  "&:first-of-type span": { borderBottomColor: "#2C7BB6" },
  "&:last-of-type span": { borderBottomColor: "#D7191C" },
});

export const SBubbleDiv = styled("div")(() => ({
  background: "#FFFFBF",
  width: "10.25em",
  borderRadius: ".375em",
  height: "9.375em",

  boxSize: "border-box",
  color: "#000",
  "&:first-of-type": {
    textAlign: "left",
    background: "#2C7BB6",
    color: "#FFF",
  },
  "&:last-of-type": {
    textAlign: "right",
    background: "#D7191C",
    color: "#FFF",
  },
  "& span": {
    display: "block",
    fontSize: ".85em",
    lineHeight: "1.25em",
    margin: "1.429em .857em",
  },
}));

export const STutorialIcon = styled(TutorialIcon)({
  marginRight: "1em",
});

export const SImg = styled("img")(({ theme }: { theme: any }) => ({
  width: "25em",
  height: "10em",
  [theme.breakpoints.down("sm")]: {
    width: "20em",
    height: "7.5em",
  },
  "&.mobile": {
    height: "clamp(10vh, 50px, 20vh)",
    marginBottom: "0.5rem",
  },
}));
