export const tutorial: any = [
  {
    // 1 - Cancer Type
    popup: [101],
    ui: {
      mapFilters: { visible: false },
      cancerType: { pos: { x: 0, y: 0 }, visible: true, expanded: false },
      barcodes: {
        visible: false,
      },
    },
    filters: {
      cancergrp: {
        value: 1,
      },
    },
    indicators: [{ active: true, value: 0, grp: 1, view: 0, sex: 3, pc: 3 }],
    pos: { top: "1rem", right: "22rem" },
    target: {
      lang: {
        en: {
          pos: { top: "1rem", right: "1rem" },
          width: "20.2rem",
          height: "2.6rem",
        },
        nl: {
          pos: { top: "1rem", right: "1rem" },
          width: "20.2rem",
          height: "2.6rem",
        },
      },
    },
  },

  {
    // 2 - Indicator Select
    popup: [],
    filters: {
      cancergrp: {
        value: 1,
      },
    },
    ui: {
      mapFilters: { visible: false },
      indicatorPanel: { pos: { x: 0, y: 0 }, visible: true, mode: 0 },
      indicators: [
        { active: true, value: 0, view: 0, sex: 3, pc: 3 },
      ],
    },
    pos: { top: "4rem", right: "22rem" },
    target: {
      lang: {
        en: {
          pos: { top: "4rem", right: "1rem" },
          width: "20.2rem",
          height: "2.6rem",
        },
        nl: {
          pos: { top: "4rem", right: "1rem" },
          width: "20.2rem",
          height: "2.6rem",
        },
      },
    },
  },
  {
    // 3 - Sex Select
    popup: [],
    ui: {
      mapFilters: { visible: false },
      indicatorPanel: { pos: { x: 0, y: 0 }, visible: true, mode: 0 },
      barcodes: {
        visible: false,
      },
    },
    filters: {
      ra5cat: {
        values: [{ value: true }, { value: true }, { value: true }, { value: true }, { value: true }],
      },
    },
    pos: { top: "6.5rem", right: "22rem" },
    target: {
      lang: {
        en: {
          pos: { top: "6.5rem", right: "1rem" },
          width: "20.1rem",
          height: "2rem",
        },
        nl: {
          pos: { top: "6.5rem", right: "1rem" },
          width: "20.1rem",
          height: "2rem",
        },
      },
    },
  },
  {
    // 4 - Search
    popup: [],
    ui: {
      mapFilters: { visible: false },
      indicatorPanel: { pos: { x: 0, y: 0 }, visible: true, mode: 0 },
      barcodes: {
        visible: false,
      },
    },
    filters: {
      ra5cat: {
        values: [{ value: true }, { value: true }, { value: true }, { value: true }, { value: true }],
      },
    },
    map: {
      center: ["4.899431", "52.379189"],
      zoom: 6,
    },
    pos: {
      main: {
        top: "1rem",
        left: "31rem",
      },
      alt: {
        top: "1rem",
        left: "15rem",
      },
    },
    target: {
      lang: {
        en: {
          pos: { top: "1rem", left: "12rem" },
          width: {
            main: "18.25rem",
            alt: "2.6rem",
          },
          height: "2.6rem",
        },
        nl: {
          pos: { top: "1rem", left: "12rem" },
          width: {
            main: "18.25rem",
            alt: "2.6rem",
          },
          height: "2.6rem",
        },
      },
    },
  },
  {
    // 5 Popup
    popup: [],
    ui: {
      mapFilters: { visible: false },
      barcodes: {
        visible: false,
      },
    },
    map: {
      center: ["4.899431", "52.379189"],
      zoom: 7,
    },
    pos: {
      main: {
        top: "14.75rem",
        left: "5rem",
      },
      alt: {
        top: "2rem",
        left: "5rem",
      },
    },
    target: {
      lang: {
        en: {
          pos: {
            main: {
              top: "14.8rem",
              left: "25.4rem",
            },
            alt: {
              top: "2rem",
              left: "25.4rem",
            },
          },
          width: "20.125rem",
          height: "21.25rem",
        },
        nl: {
          pos: {
            main: {
              top: "14.8rem",
              left: "25.4rem",
            },
            alt: {
              top: "2rem",
              left: "25.4rem",
            },
          },
          width: "20.1rem",
          height: "21.25rem",
        },
      },
    },
  },
  {
    // 6 Indicator V Plot
    popup: [101],
    ui: {
      mapFilters: { visible: false },
      indicatorPanel: { pos: { x: 0, y: 0 }, visible: true, mode: 0 },
      indicators: [
        { active: true, value: 0, view: 1, sex: 3, pc: 3 },
      ],
    },
    pos: {
      main: {
        top: "16.1rem",
        right: "22rem",
      },
      alt: {
        top: "1rem",
        right: "22rem",
      },
    },
    target: {
      lang: {
        en: {
          pos: {
            main: {
              top: "16.2rem",
              right: "1rem",
            },
            alt: {
              top: "1rem",
              right: "1rem",
            },
          },
          width: "20rem",
          height: "6.5rem",
        },
        nl: {
          pos: {
            main: {
              top: "16.2rem",
              right: "1rem",
            },
            alt: {
              top: "1rem",
              right: "1rem",
            },
          },
          width: "20rem",
          height: "6.5rem",
        },
      },
    },
  },
  {
    // 7 Indicator Density
    popup: [101],
    ui: {
      mapFilters: { visible: false },
      indicatorPanel: { pos: { x: 0, y: 0 }, visible: true, mode: 1, draggable: true },
      indicators: [
        { active: true, value: 0, view: 2, sex: 3, pc: 3 },
      ],
      barcodes: {
        visible: false,
      },
    },
    filters: {
      ra5cat: {
        values: [{ value: true }, { value: true }, { value: true }, { value: true }, { value: true }],
      },
    },
    pos: {
      main: {
        top: "21.0rem",
        right: "22rem",
      },
      alt: {
        top: "4rem",
        right: "22rem",
      },
    },
    target: {
      lang: {
        en: {
          pos: {
            main: {
              top: "22.5rem",
              right: "1rem",
            },
            alt: { top: "22rem", right: "1rem" },
          },
          width: "20rem",
          height: "6.1rem",
        },
        nl: {
          pos: {
            main: {
              top: "22.5rem",
              right: "1rem",
            },
            alt: { top: "22.5rem", right: "1rem" },
          },
          width: "20rem",
          height: "6.1rem",
        },
      },
    },
  },

  {
    // 12 Tour Menu Button
    popup: [],
    ui: {
      mapFilters: { visible: false },
      barcodes: {
        visible: false,
      },
    },
    filters: {
      ra5cat: {
        values: [{ value: true }, { value: true }, { value: true }, { value: true }, { value: true }],
      },
    },
    pos: { bottom: "2rem", left: "24.5rem" },
    target: {
      lang: {
        en: {
          pos: { bottom: "2rem", left: "9rem" },
          width: "12.75rem",
          height: "2.6rem",
        },
        nl: {
          pos: { bottom: "2rem", left: "9rem" },
          width: "12.5rem",
          height: "2.6rem",
        },
      },
    },
  },
];
