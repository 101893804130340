import Button from "@mui/material/Button";
import styled from "@emotion/styled";

export const SRootDiv = styled("div")(({ theme }: { theme: any }) => ({
  ...theme.layers.mapOverlay,
  position: "absolute",
  backgroundColor: theme.palette.background.overlay,
  width: "20rem",
  height: "10rem",
  top: "50%",
  left: "50%",
  transform: "translateY(-50%) translateX(-50%)",
}));

export const SContentDiv = styled("div")({
  zIndex: 1,
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  textAlign: "center",
  width: "100%",
  height: "auto",
  top: "50%",
  transform: "translateY(-50%)",
});

export const SFilterButton = styled(Button)(({ theme }: { theme: any }) => ({
  fontSize: "0.8rem",
  width: "33%",
  minWidth: "3rem",
  minHeight: "1rem",
  padding: "0.25rem",
  ...theme.components.border,
  margin: "0 auto",
  display: "inline-block",
  "& span": {
    pointerEvents: "none",
  },
}));
