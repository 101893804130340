import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export const BASE_URL = process.env.REACT_APP_BASE_URL;

export interface URIState {
  uriObj: any;
  stateLoading: boolean;
  urlVisible: boolean;
}

const initialState: URIState = {
  uriObj: {},
  stateLoading: false,
  urlVisible: false,
};

export const uriSlice = createSlice({
  name: "uri",
  initialState,
  reducers: {
    setLoadingState: (state, action: PayloadAction<URIState["stateLoading"]>) => {
      state.stateLoading = action.payload;
    },
    setURLVisibility: (state, action: PayloadAction<URIState["urlVisible"]>) => {
      state.urlVisible = action.payload;
    },
    setURIState: (state, action: PayloadAction<any>) => {
      var newUriObj = { ...state.uriObj };
      if (action.payload.location) newUriObj.location = action.payload.location;
      if (action.payload.data) newUriObj.data = action.payload.data;
      state.uriObj = newUriObj;
      state.stateLoading = action.payload.stateLoading || true;
    },
    resetURIState: (state) => {
      state.uriObj = initialState.uriObj;
      state.stateLoading = false;
    },
  },
});

export const { setLoadingState, setURIState, setURLVisibility, resetURIState } = uriSlice.actions;
export default uriSlice.reducer;
