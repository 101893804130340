import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { css } from "@emotion/css";
import { useAppDispatch, useAppSelector } from "../../redux/types";
import * as UIActions from "../../redux/features/uiSlice";
import { visiblityHelper } from "./utils";
import { SButton } from "./emotion";

interface WindowButtonProps {
  windowName: string;
  icon?: JSX.Element;
  label?: string;
  clickOverride?: any;
  visible?: boolean;
  className?: string;
  buttonText?: string;
}

const WindowButton = (props: WindowButtonProps) => {
  const ui = useAppSelector((state) => state.ui);
  const { label = "", clickOverride = null, windowName, icon, visible = visiblityHelper(windowName, ui), buttonText = "" } = props;
  const dispatch = useAppDispatch();
  const theme: any = useTheme();
  const TOOLTIP_STYLE = {
    tooltip: css(theme.components.tooltip.tooltip),
    tooltipPlacementTop: css(theme.components.tooltip.tooltipPlacementTop),
    tooltipPlacementBottom: css(theme.components.tooltip.tooltipPlacementBottom),
  };

  const toggleWindow = (windowName: keyof UIActions.UIState, visible: boolean) => {
    dispatch(UIActions.updateComponent({ name: windowName, data: { visible: visible } }));
  };

  return (
    <Tooltip id={`windowButton_${windowName}_tooltip`} classes={TOOLTIP_STYLE} title={label} placement="top" arrow disableFocusListener>
      <SButton
        onClick={() => {
          if (clickOverride != null) clickOverride();
          else toggleWindow(windowName as keyof UIActions.UIState, !visible);
        }}
        variant="contained"
        color="secondary"
        hasText={buttonText === "" ? false : true}
        isActive={visible}
        aria-label={label}
        className={props.className + " noPrint"}
        startIcon={icon && buttonText !== "" ? icon : undefined}
      >
        {buttonText}
        {buttonText === "" && icon}
      </SButton>
    </Tooltip>
  );
};

export default WindowButton;
