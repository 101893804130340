import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { OrderTypes } from "../../../types";
import { DataPieceType } from "../../../types";
import { useAppSelector } from "../../../redux/types";
import { SContentDiv } from "./emotion";
import ListTable from "./ListTable";

interface PC4ContainerProps {
  sa2?: string;
}
const PC4Container = ({ sa2 }: PC4ContainerProps) => {
  const { sa2Data, popupData } = useAppSelector((store) => store.cancerAtlas);
  const sexIndex = useAppSelector((state) => state.filter.filters.sex.value);
  let relevantPC4: Array<any> = [];

  if (popupData[`${sa2}-${sexIndex}`]?.length > 0) {
    relevantPC4 = popupData[`${sa2}-${sexIndex}`][0].childpostcodes;
  }

  return relevantPC4 && relevantPC4.length > 0 ? (
    <div style={{ textAlign: "left", margin: "0 .5rem" }}>
      <Typography style={{ paddingBottom: "0.5rem" }} variant="body1">
        PC4s:&nbsp;
        {relevantPC4.join(", ")}
      </Typography>
    </div>
  ) : (
    // Insert blank div because scroll ref is indexing child elems via a constant index
    <div></div>
  );
};

interface ListViewProps {
  isMobile: boolean;
  data: Array<DataPieceType>;
  boxes: Array<Array<JSX.Element>>;
  selected: number;
  indicatorIndex: number;
  order: OrderTypes;
  orderIndex: string;
  onSort: Function;
  cancerGroups: any;
  sa2: string | undefined;
  scrollbarRef: any;
}

const ListView = ({ isMobile, data, boxes, selected, indicatorIndex, order, orderIndex, onSort, cancerGroups, sa2, scrollbarRef }: ListViewProps) => {
  const [dataL, setDataL] = useState(data);
  const [orderIndexL, setOrderIndexL] = useState(orderIndex);

  const handleSort = (key: string) => {
    onSort && onSort(key);
  };

  return (
    <SContentDiv ref={scrollbarRef}>
      {isMobile ? (
        <ListTable
          key={"listtableMobile"}
          sa2={sa2}
          cancerGroups={cancerGroups}
          selected={selected}
          data={data}
          boxes={boxes}
          indicatorIndex={indicatorIndex}
          orderIndex={orderIndex}
          order={order}
          handleSort={handleSort}
        />
      ) : (
        <ListTable
          key={"listtable"}
          sa2={sa2}
          cancerGroups={cancerGroups}
          selected={selected}
          data={data}
          boxes={boxes}
          indicatorIndex={indicatorIndex}
          orderIndex={orderIndex}
          order={order}
          handleSort={handleSort}
        />
      )}
    </SContentDiv>
  );
};

export default ListView;
