import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LngLatLike } from "mapbox-gl";
import { arrayCompare } from "../utils";
export const BASE_URL = process.env.REACT_APP_BASE_URL;

const MAX_POPUPS = 2;

export interface HTMLVisualisationState {
  visPanels: Array<{
    anchor: LngLatLike;
    coords: { x: number; y: number };
    id: number;
    panelConfig: any;
    parentId: string;
  }>;
  sa2s: Array<any>;
  errorId: null | any;
  message: string;
  popupData: null | any;
}

const initialState: HTMLVisualisationState = {
  visPanels: [],
  sa2s: [],
  errorId: null,
  message: "",
  popupData: null,
};

export const htmlVisualisationSlice = createSlice({
  name: "htmlVisualisation",
  initialState,
  reducers: {
    addScatterplotPopup: (state, action: PayloadAction<HTMLVisualisationState["popupData"]>) => {
      state.popupData = action.payload;
    },
    removeScatterplotPopup: (state) => {
      state.popupData = null;
    },

    addHTMLVis: (state, action: PayloadAction<any>) => {
      if (state.visPanels.filter((t) => t.id === action.payload.id).length > 0) {
        state.visPanels = state.visPanels.filter((panel) => action.payload.id !== panel.id);
        state.sa2s = state.sa2s.filter((s) => action.payload.id !== s);
      } else {
        if (state.visPanels.length === MAX_POPUPS) {
          state.visPanels = [...state.visPanels.slice(1), action.payload].filter((thing, index, self) => self.findIndex((t) => t.id === thing.id) === index);
          state.sa2s = [...state.sa2s.slice(1), action.payload.id].filter((thing, index, self) => self.findIndex((t) => t === thing) === index);
        } else {
          state.visPanels = [...state.visPanels, action.payload].filter((thing, index, self) => self.findIndex((t) => t.id === thing.id) === index);
          state.sa2s = [...state.sa2s, action.payload.id].filter((thing, index, self) => self.findIndex((t) => t === thing) === index);
        }
      }
    },
    displayError: (state, action: PayloadAction<any>) => {
      state.errorId = action.payload.errorId;
      state.message = action.payload.message;
    },
    clearError: (state) => {
      state.errorId = initialState.errorId;
      state.message = initialState.message;
    },
    removeHTMLVis: (state, action: PayloadAction<{ id: number }>) => {
      state.visPanels = state.visPanels.filter((panel) => action.payload.id !== panel.id);
      state.sa2s = state.sa2s.filter((s) => action.payload.id !== s);
    },
    removeAllHTMLVis: (state, action: PayloadAction<{ parentId: string }>) => {
      state.visPanels = state.visPanels.filter((panel) => action.payload.parentId !== panel.parentId);
      state.sa2s = initialState.sa2s;
    },
    updateHTMLVis: (state, action: PayloadAction<{ visUpdate: any }>) => {
      var newData = state.visPanels.map((panel) => {
        var updateData = action.payload.visUpdate.find((update: any) => update.id === panel.id);
        if (updateData) return { ...panel, coords: updateData.coords };
        else return { ...panel };
      });
      state.visPanels = newData;
    },

    bringToFront: (state, action: PayloadAction<{ id: number }>) => {
      var reOrdered = [...state.visPanels];
      reOrdered.sort((a, b) => {
        return a.id === action.payload.id ? 1 : b.id === action.payload.id ? -1 : 0;
      });
      var reOrderedSA2s = [...state.sa2s];
      reOrderedSA2s.sort((a, b) => {
        return a === action.payload.id ? 1 : b === action.payload.id ? -1 : 0;
      });
      // Only update sa2s if order has changed..
      state.visPanels = reOrdered;
      state.sa2s = arrayCompare(reOrderedSA2s, state.sa2s) ? state.sa2s : reOrderedSA2s;
    },
    applyStateHTML: (state, action: PayloadAction<HTMLVisualisationState["sa2s"]>) => {
      state.sa2s = action.payload;
    },
  },
});

export const {
  addScatterplotPopup,
  removeScatterplotPopup,
  addHTMLVis,
  displayError,
  clearError,
  removeHTMLVis,
  removeAllHTMLVis,
  updateHTMLVis,
  bringToFront,
  applyStateHTML,
} = htmlVisualisationSlice.actions;
export default htmlVisualisationSlice.reducer;
