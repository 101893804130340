import styled from "@emotion/styled";
import LinearProgress from "@mui/material/LinearProgress";

const SDiv = styled("div")(() => ({
  position: "absolute",
  textAlign: "center",
  bottom: 0,
  width: "100%",
  color: " #FFF",
  zIndex: 200,
  fontSize: "0.9rem",
  "& p": {
    paddingBottom: "1em",
  },
}));

const SLinearProgress = styled(LinearProgress)(() => ({
  background: "none",
}));

interface PassiveLoaderProps {
  message?: string;
  visible?: boolean;
}
const PassiveLoader = (props: PassiveLoaderProps) => {
  const { message, visible } = props;
  if (!visible) return null;

  return (
    <SDiv>
      <p>{message}</p>
      <SLinearProgress color="primary" variant="query" />
    </SDiv>
  );
};

export default PassiveLoader;
