import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../../redux/types";
import * as UIActions from "../../../redux/features/uiSlice";
import PassiveLoader from "../../PassiveLoader";
import { useTheme } from "@mui/material/styles";
import {
  SArrowDownward,
  SBubbleArrowDiv,
  SBubbleArrowFullMobArrowDiv,
  SBubbleArrowMobDiv,
  SBubbleContDiv,
  SBubbleContMobDiv,
  SBubbleDiv,
  SBubbleMobBubbleFullDiv,
  SDisclaimerContDiv,
  SDisclaimerDiv,
  SBubbleMobDiv,
  SButton,
  SCheckIcon,
  SCloseIcon,
  SCloseIconButton,
  SDialogActions,
  SDialogContent,
  SFlexDiv,
  SFlexFullMobDiv,
  SGradientClampLDiv,
  SGradientClampRDiv,
  SGradientContainer,
  SGradientContainerFullMobDiv,
  SGradientDiv,
  SImg,
  SInfoIcon,
  SOverlayDesktopContDiv,
  SOverlayDesktopInnerDiv,
  SOverlayMobileContDiv,
  SOverlayMobileInnerDiv,
  SRootDialog,
  STitle1H1,
  STutorialIcon,
} from "./emotion";
const COMPONENT_NAME = "landingOverlay";

interface Props {
  loading: boolean;
}

const LandingOverlay = ({ loading }: Props) => {
  const { visible } = useAppSelector((state) => state.ui[COMPONENT_NAME]);
  const { screen, isMobile } = useAppSelector((state) => state.ui);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const handleClose = (event?: any) => {
    dispatch(UIActions.updateComponent({ name: COMPONENT_NAME, data: { visible: false } }));
  };

  const handleTutorial = () => {
    dispatch(UIActions.updateComponent({ name: "tutorial", data: { visible: true } }));
    handleClose();
  };

  return (
    <SRootDialog
      className={isMobile ? "mobile" : ""}
      fullScreen
      open={visible}
      onClick={() => {
        !loading && isMobile && handleClose();
      }}
      onClose={() => !loading && handleClose()}
      aria-labelledby={"Landing Overlay"}
    >
      <SDialogContent>
        {isMobile ? (
          <SOverlayMobileContDiv>
            <SImg src="/assets/iknl_logo.svg" theme={theme} className="mobile" />
            <SCloseIconButton theme={theme} onClick={handleClose} aria-label={t("close")} size="large">
              <SCloseIcon />
            </SCloseIconButton>
            <SOverlayMobileInnerDiv>
              <div style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
                <div>
                  <STitle1H1 className="mobile">
                    {t("comparison.description", {
                      item: t("indicator.diagnoses.rate_description"),
                      subject: t("common:demonym"),
                    })}
                  </STitle1H1>
                  <br />
                  <SBubbleContMobDiv>
                    <SBubbleMobDiv>
                      <span>
                        {t("comparison.description_long", {
                          comparator: t("comparison.lower_short").toLowerCase(),
                        })}
                      </span>
                    </SBubbleMobDiv>
                    <SBubbleMobDiv>
                      <span>
                        {t("comparison.description_long", {
                          comparator: t("comparison.higher").toLowerCase(),
                        })}
                      </span>
                    </SBubbleMobDiv>
                  </SBubbleContMobDiv>
                  <SBubbleContMobDiv>
                    <SBubbleArrowMobDiv>
                      <span></span>
                    </SBubbleArrowMobDiv>
                    <div></div>
                    <SBubbleArrowMobDiv>
                      <span></span>
                    </SBubbleArrowMobDiv>
                  </SBubbleContMobDiv>
                  <SFlexFullMobDiv>
                    <span>{t("low")}</span>
                    <SArrowDownward />
                    <span>{t("high")}</span>
                  </SFlexFullMobDiv>
                  <SGradientContainerFullMobDiv>
                    <SGradientClampLDiv />
                    <SGradientDiv />
                    <SGradientClampRDiv />
                  </SGradientContainerFullMobDiv>
                  <SBubbleContMobDiv>
                    <SBubbleArrowFullMobArrowDiv>
                      <span></span>
                    </SBubbleArrowFullMobArrowDiv>
                  </SBubbleContMobDiv>
                  <SBubbleContMobDiv>
                    <SBubbleMobBubbleFullDiv>
                      <span>
                        {t("comparison.description_long", {
                          comparator: t("comparison.equal").toLowerCase(),
                        })}
                      </span>
                    </SBubbleMobBubbleFullDiv>
                  </SBubbleContMobDiv>
                </div>
                <SDisclaimerDiv theme={theme}>
                  <SDisclaimerContDiv>
                    <SInfoIcon></SInfoIcon>
                    <span>{t("best_viewed_on_desktop")}</span>
                  </SDisclaimerContDiv>
                </SDisclaimerDiv>
              </div>
            </SOverlayMobileInnerDiv>
          </SOverlayMobileContDiv>
        ) : (
          <SOverlayDesktopContDiv>
            {!loading && (
              <SCloseIconButton theme={theme} onClick={handleClose} aria-label={t("close")} size="large">
                <SCloseIcon />
              </SCloseIconButton>
            )}

            <SImg src="/assets/iknl_logo.svg" theme={theme} />

            <SOverlayDesktopInnerDiv>
              <STitle1H1>
                {t("comparison.description", {
                  item: t("indicator.diagnoses.rate_description"),
                  subject: t("common:demonym"),
                })}
              </STitle1H1>
              <SFlexDiv>
                <span>{t("low")}</span>
                <SArrowDownward />
                <span>{t("high")}</span>
              </SFlexDiv>
              <SGradientContainer>
                <SGradientClampLDiv />
                <SGradientDiv />
                <SGradientClampRDiv />
              </SGradientContainer>
              <SBubbleContDiv>
                <SBubbleArrowDiv>
                  <span></span>
                </SBubbleArrowDiv>
                <SBubbleArrowDiv>
                  <span></span>
                </SBubbleArrowDiv>
                <SBubbleArrowDiv>
                  <span></span>
                </SBubbleArrowDiv>
              </SBubbleContDiv>
              <SBubbleContDiv>
                <SBubbleDiv>
                  <span>
                    {t("comparison.description_long", {
                      comparator: t("comparison.lower").toLowerCase(),
                    })}
                  </span>
                </SBubbleDiv>
                <SBubbleDiv>
                  <span>
                    {t("comparison.description_long", {
                      comparator: t("comparison.equal").toLowerCase(),
                    })}
                  </span>
                </SBubbleDiv>
                <SBubbleDiv>
                  <span>
                    {t("comparison.description_long", {
                      comparator: t("comparison.higher").toLowerCase(),
                    })}
                  </span>
                </SBubbleDiv>
              </SBubbleContDiv>
            </SOverlayDesktopInnerDiv>
          </SOverlayDesktopContDiv>
        )}
      </SDialogContent>
      {!isMobile && (
        <SDialogActions>
          {!loading && [
            <SButton key={"landing_btn_close"} theme={theme} aria-label={"Explore the Atlas"} variant="text" onClick={handleClose}>
              <SCheckIcon />
              {t("start_prompt")}
            </SButton>,
            <SButton key={"landing_btn_tutorial"} theme={theme} aria-label={"Open Tutorial"} variant="text" onClick={handleTutorial}>
              <STutorialIcon />
              {t(`tutorial.tutorialBtn`)}
            </SButton>,
          ]}
        </SDialogActions>
      )}
      <PassiveLoader visible={loading} message={screen.width >= 600 ? t("loading.data") : ""} />
    </SRootDialog>
  );
};

export default LandingOverlay;
