/* eslint-disable no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NCAColorGradient } from "../../../constants";
import update from "immutability-helper";
import { colorIdx } from "../utils";
import { SA2DataPieceType } from "../../../types";
import { map_range2 } from "../../../utils";
export const BASE_URL = process.env.REACT_APP_BASE_URL;

export interface CancerAtlasState {
  cancerData: null | any;
  sa2Data: null | Array<SA2DataPieceType>;
  densityData: null | any;
  densityLoadingList: any[];
  summaryData: null | any;
  loading: boolean;
  loadingPDF: boolean;
  loadingEmail: boolean;
  loadingSummaries: boolean;
  loadingCancerData: boolean;
  loadingDensityData: boolean;
  loadingDataError: boolean;
  loadingStories: boolean;
  loadingSA2s: boolean;
  popupData: null | any;
  stories: Array<{
    id: number;
    slug: string;
    attributes?: any;
  }>;
}

const initialState: CancerAtlasState = {
  cancerData: null,
  sa2Data: null,
  densityData: null,
  densityLoadingList: [],
  summaryData: null,
  loading: false,
  loadingPDF: false,
  loadingEmail: false,
  loadingSummaries: false,
  loadingCancerData: false,
  loadingDensityData: false,
  loadingDataError: false,
  loadingStories: false,
  loadingSA2s: false,
  popupData: null,
  stories: [],
};

export const cancerAtlasSlice = createSlice({
  name: "cancerAtlas",
  initialState,
  reducers: {
    dataError: (state) => {
      state.loadingDataError = true;
    },
    fetchStoryData: (state) => {
      state.loadingStories = true;
    },
    gotStoryData: (state, action: PayloadAction<[]>) => {
      // parse the app state
      action.payload.forEach((story: any) => {
        story["slug"] = encodeURIComponent(story.attributes.title.toLowerCase().split(" ").join(""));
        story.attributes.panels.forEach((panel: any) => {
          panel["appState"] = panel.state ?? null; 
        });
      });
      state.loadingStories = false;
      state.stories = action.payload;
    },
    fetchCancerData: (state) => {
      state.loading = true;
      state.loadingCancerData = true;
    },
    fetchSA2Data: (state) => {
      state.loadingSA2s = true;
      state.sa2Data=[];
    },
    gotSA2Data: (state, action: PayloadAction<CancerAtlasState["sa2Data"]>) => {
      state.sa2Data = action.payload;
      state.loadingSA2s = false;
    },
    fetchDensityData: (state, action: PayloadAction<any>) => {
      state.densityLoadingList = [...state.densityLoadingList, action.payload.indicator + "-" + action.payload.sex + "-" + action.payload.cancergrp + "-" + action.payload.sa2];
      state.loadingDensityData = true;
    },
    gotDensityData: (state, action: PayloadAction<any>) => {
      var colorHex = state.cancerData[action.payload.indicator][action.payload.sex].find((x: any) => x.sa2 == action.payload.sa2 && x.cancergrp == action.payload.cancergrp).colorHex;
      action.payload.data.colorHex = colorHex;
      var array = [...state.densityLoadingList]; // make a separate copy of the array
      var index = array.indexOf(action.payload.indicator + "-" + action.payload.sex + "-" + action.payload.cancergrp + "-" + action.payload.sa2);
      array.splice(index, 1);
      state.densityLoadingList = array;
      state.densityData = { ...state.densityData, [action.payload.indicator + "-" + action.payload.sex + "-" + action.payload.cancergrp + "-" + action.payload.sa2]: action.payload.data };
      state.loadingDensityData = false;
    },
    failedDensityData: (state, action: PayloadAction<any>) => {
      var array = [...state.densityLoadingList]; // make a separate copy of the array
      var index = array.indexOf(action.payload.indicator + "-" + action.payload.sex + "-" + action.payload.cancergrp + "-" + action.payload.sa2);
      array.splice(index, 1);
      state.loadingDensityData = false;
      state.densityLoadingList = array;
    },
    gotNewCancerData: (state, action: PayloadAction<any>) => {},
    gotAllCancerData: (state, action: PayloadAction<any>) => {
      const sex = action.payload.sex;
      const cancerData = action.payload.data;
      let processedCancerData: any = [];
      for (var j = 0; j < cancerData.length; j++) {
        for (var i = 0; i < cancerData[j].length; i++) {
          const idx = colorIdx(cancerData[j][i].logp50);
          cancerData[j][i]["colorIdx"] = idx;
          cancerData[j][i]["colorHex"] = NCAColorGradient[idx];
        }
        processedCancerData[j] = { [sex]: cancerData[j] };
      }
      if (state.cancerData == null) {
        state.cancerData = processedCancerData;
        state.loading = false;
      } else {
        // the sex updated
        for (var j = 0; j < processedCancerData.length; j++) {
          processedCancerData[j] = update(state.cancerData[j], { $merge: processedCancerData[j] });
        }
        state.cancerData = processedCancerData;
        state.loading = false;
        state.loadingCancerData = false;
      }
    },
    gotCancerData: (state, action: PayloadAction<any>) => {
      var indicator = action.payload.ind;
      var sex = action.payload.sex;
      var cancerData = action.payload.data;
      var grp = action.payload.grp;
      state.loadingCancerData = false;
      for (var i = 0; i < cancerData.length; i++) {
        var idx = Math.floor(map_range2(cancerData[i].logp50, -0.585, 0.585, 0, 63));
        if (idx > 63) idx = 63;
        else if (idx < 0) idx = 0;
        cancerData[i]["colorIdx"] = idx;
        cancerData[i]["colorHex"] = NCAColorGradient[idx];
      }
      var processedCancerData = cancerData;

      if (state.cancerData == null) {
        state.cancerData = { [indicator]: { [sex]: processedCancerData } };
        state.loading = false;
        return;
      }
      if (state.cancerData[indicator] == null) {
        state.cancerData = {
          ...state.cancerData,
          [indicator]: { [sex]: processedCancerData },
        };
        state.loading = false;
        
        return;
      }

      state.cancerData = {
        ...state.cancerData,
        [indicator]: {
          ...state.cancerData[indicator],
          [sex]: processedCancerData,
        },
      };
      state.loading = false;
    },
    fetchSummaryData: (state) => {
      state.loadingSummaries = true;
    },
    gotSummaryData: (state, action: PayloadAction<any>) => {
      var sd = update(state.summaryData, { $set: action.payload });
      state.summaryData = sd;
      state.loadingSummaries = false;
    },
    summariesDataError: (state) => {
      state.loadingSummaries = false;
    },
    emailSignup: (state) => {
      state.loadingEmail = true;
    },
    emailSuccess: (state) => {
      state.loadingEmail = false;
    },
    gotPopupData: (state, action: PayloadAction<any>) => {
      const { data, postcode, sex } = action.payload;
      data.map((x: any) => {
        const idx = colorIdx(x.logp50);
        x.colorIdx = idx;
        x.colorHex = NCAColorGradient[idx];
      });
      state.popupData = { ...state.popupData, [`${postcode}-${sex}`]: data };
    },
  },
});

export const {
  dataError,
  fetchStoryData,
  gotStoryData,
  fetchCancerData,
  gotSA2Data,
  fetchDensityData,
  gotDensityData,
  failedDensityData,
  gotNewCancerData,
  gotAllCancerData,
  gotCancerData,
  fetchSummaryData,
  gotSummaryData,
  summariesDataError,
  emailSignup,
  emailSuccess,
  gotPopupData,
  fetchSA2Data
} = cancerAtlasSlice.actions;
export default cancerAtlasSlice.reducer;
