import { Component, ErrorInfo, ReactNode } from "react";
import { ERROR_ACTIONS } from "../../types";
import ErrorDialog from "./ErrorDialog";

interface Props {
  name: string;
  code?: string;
  message?: string;
  action?: ERROR_ACTIONS;
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorDialog name={this.props.name} code={this.props.code || ""} message={this.props.message || ""} action={this.props.action || ERROR_ACTIONS.REFRESH} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
