import { useEffect, useState, useRef, useMemo, SyntheticEvent, RefObject } from "react";
import { Zoom } from "@visx/zoom";
import { RectClipPath } from "@visx/clip-path";
import { scaleLinear } from "@visx/scale";
import { Grid } from "@visx/grid";
import Tooltip from "../Tooltip/Tooltip";
import { remToPx, pxToRem } from "../../../styles/theme";
import { FS_SCATTERPLOT_CIRCLE_SIZE, SCATTERPLOT_CIRCLE_SIZE, VPLOT_FULLSCREEN_BG_HEIGHT, VPLOT_FULLSCREEN_MAX_WIDTH } from "../../../constants";
import { DataPieceType, MatrixType, SA2DataPieceType } from "../../../types";
import AddIcon from "@mui/icons-material/Add";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ClearIcon from "@mui/icons-material/Clear";
import { useAppSelector } from "../../../redux/types";
import { HTMLVisualisationState } from "../../../redux/features/htmlVisualisationSlice";
import { CancerAtlasState } from "../../../redux/features/cancerAtlasSlice";
import { GlobeState } from "../../../redux/features/globeSlice";
import { extractName, y, x, color } from "../utils";
import { SRootDiv, SWindowBottom, S_ControlsDiv } from "./emotion";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import RemoveIcon from "@mui/icons-material/Remove";
import { TOOLTIP_BALL_SIZE } from "../Tooltip/emotion";
const MAX_ZOOM_LEVEL = 20;

interface ScatterplotFullscreenProps {
  data?: Array<DataPieceType> | any;
  sa2Data: CancerAtlasState["sa2Data"];
  activeSA2s: GlobeState["activeSA2s"];
  selectedSA2s: HTMLVisualisationState["sa2s"];
  callFlyToSA2?: Function;
  loadingDensityData?: boolean;
  flyToActive: GlobeState["flyToActive"];
  containerRef: RefObject<null | HTMLDivElement>;
}

enum CIRCLE_TYPES {
  MAIN = "MAIN",
  MINIMAP = "MINIMAP",
}

const INITIAL_MATRIX = {
  scaleX: 1,
  scaleY: 1,
  translateX: 0,
  translateY: 0,
  skewX: 0,
  skewY: 0,
};

const ScatterplotFullscreen = ({ data, sa2Data, activeSA2s, selectedSA2s, callFlyToSA2, loadingDensityData, flyToActive, containerRef }: ScatterplotFullscreenProps) => {
  const [zoomMatrix, setZoomMatrix] = useState<MatrixType>(INITIAL_MATRIX);
  const [tooltipLeft, setTooltipLeft] = useState<number>(0);
  const [tooltipTop, setTooltipTop] = useState<number>(0);
  const tooltipRef = useRef<null | any>(null);
  const [tooltipData, setTooltipData]: any = useState<string | null>(null);
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const svgref = useRef<null | any>(null);
  const [svgBounds, setSvgBounds] = useState<DOMRect | null>(null);
  const { screen } = useAppSelector((state) => state.ui);
  const [width, setWidth] = useState<number>(screen.width > 320 ? VPLOT_FULLSCREEN_MAX_WIDTH : pxToRem(screen.width - 52));
  const height: number = VPLOT_FULLSCREEN_BG_HEIGHT;
  const itemsRef = useRef<Array<any>>([]);
  const testRef = useRef<any>(null);
  const [selectedSA2Tooltips, setSelectedSA2Tooltips] = useState<any>([]);
  const zoomRef = useRef<null | any>(null);

  //set local width every time the screen is resized
  useEffect(() => {
    containerRef?.current && setWidth(pxToRem(containerRef.current.offsetWidth));
    setZoomMatrix(INITIAL_MATRIX);
  }, [screen]);

  //Rerender the tooltips when the user interacts with the chart (zoom, drag), the screen changes
  useEffect(() => {
    if (svgref.current && svgBounds) {
      setSelectedSA2Tooltips(generateSA2Tooltips());
    }
  }, [zoomMatrix, svgBounds, width, selectedSA2s]);

  useEffect(() => {
    if (svgref.current) {
      const myBounds = svgref.current.getBoundingClientRect();
      setSvgBounds(myBounds);
      setSelectedSA2Tooltips(generateSA2Tooltips(myBounds));
    }
  }, [svgref, svgref.current, itemsRef]);

  const xScale = scaleLinear<any>({
    domain: [-2, 2],
    range: [0, width],
    clamp: true,
  });

  const yScale = scaleLinear<any>({
    domain: [0, 1],
    range: [height - 0.25, 0],
    clamp: true,
  });

  //Special X scaler for the grid so that it's in tandem (scales) with the zoom amount
  const myXScale = scaleLinear<any>({
    domain: [0, 1],
    range: [0, remToPx(`${width * zoomMatrix.scaleX}rem`)],
    nice: true,
  });

  //Special Yscaler for the grid so that it's in tandem (scales) with the zoom amount
  const myYScale = scaleLinear<any>({
    domain: [0, 1],
    range: [remToPx(`${height * zoomMatrix.scaleY}rem`), 0],
    nice: true,
  });

  const generateSA2Tooltips = (myBounds?: any) => {
    const myTooltips = selectedSA2s?.map((obj) => {
      //if myBounds isn't passed in, we know that svgBounds has been assigned post mount
      const svgBoundsLocal = myBounds || svgBounds;
      const wantedCircle: any = data?.find((x: any) => x.postcode === obj.postcode);
      //Grab the index of the where the SA2 is suitated inside the data array
      const wantedCircleIndex: any = data?.indexOf(wantedCircle);
      //The array of refs is in the same order as data therefore we can use the index to grab the respective ref
      const wantedBoundary = itemsRef?.current[wantedCircleIndex]?.getBoundingClientRect();

      return (
        <Tooltip
          key={`tooltip_${obj.cancer_grp}_${obj.postcode}`}
          visible={true}
          color={"#000"}
          textColor={"#fff"}
          type={"active"}
          left={pxToRem(wantedBoundary.x - svgBoundsLocal.x + wantedBoundary.width / 2 + remToPx(TOOLTIP_BALL_SIZE) / 2)}
          top={pxToRem(wantedBoundary.y - svgBoundsLocal.y + wantedBoundary.height / 2 + remToPx(TOOLTIP_BALL_SIZE) / 2)}
          reverse={yScale(y(obj)) > height - 1.2}
          data={extractName(
            obj,
            sa2Data?.find((x) => x.areacode == obj.postcode)
          )}
        />
      );
    });
    return myTooltips;
  };

  function constrain(transformMatrix: MatrixType, prevTransformMatrix: MatrixType) {
    //set a min zoom amount
    if (transformMatrix.scaleX < 1 || transformMatrix.scaleY < 1) {
      const minZoom = {
        scaleX: 1,
        scaleY: 1,
        skewX: prevTransformMatrix.skewX,
        skewY: prevTransformMatrix.skewY,
        translateX: 0,
        translateY: 0,
      };
      setZoomMatrix(minZoom);
      return minZoom;
    }
    //set a max zoom amount
    else if (transformMatrix.scaleX > MAX_ZOOM_LEVEL || transformMatrix.scaleY > MAX_ZOOM_LEVEL) {
      setZoomMatrix(prevTransformMatrix);
      return prevTransformMatrix;
    }
    setZoomMatrix(transformMatrix);
    return transformMatrix;
  }

  //Ouput circles for either the fullscreen or minimap version
  const CircleFactory = (circleType: CIRCLE_TYPES) => {
    return data?.map((d: DataPieceType, i: number) => {
      switch (circleType) {
        case CIRCLE_TYPES.MAIN:
          return (
            <circle
              style={{ cursor: "pointer !important" }}
              ref={(el) => (itemsRef.current[i] = el)}
              key={`vpoint-${d.postcode}`}
              fill={color(activeSA2s, d)}
              cx={xScale(x(d)) + "rem"}
              cy={yScale(y(d)) + 0.125 + "rem"}
              r={FS_SCATTERPLOT_CIRCLE_SIZE}
              onClick={(e: SyntheticEvent) => {
                testRef.current = e.target;
                if (!loadingDensityData && !flyToActive) {
                  if (callFlyToSA2) {
                    callFlyToSA2(d.postcode);
                  }
                }
              }}
              onMouseOver={(e: SyntheticEvent) => {
                if (!svgBounds) return;
                tooltipRef.current = e.target;
                setTooltipData(
                  extractName(
                    d,
                    sa2Data?.find((x: SA2DataPieceType) => x.areacode === d.postcode)
                  )
                );
                setTooltipVisible(true);
              }}
              onMouseLeave={() => {
                setTooltipVisible(false);
              }}
              onTouchEnd={() => {
                setTooltipVisible(false);
              }}
            />
          );
          break;
        case CIRCLE_TYPES.MINIMAP:
          return (
            <circle
              style={{ cursor: "pointer !important" }}
              key={`vpoint-${d.postcode}`}
              fill={color(activeSA2s, d)}
              cx={xScale(x(d)) + "rem"}
              cy={yScale(y(d)) + 0.125 + "rem"}
              r={SCATTERPLOT_CIRCLE_SIZE}
            />
          );
          break;
        default:
          break;
      }
    });
  };

  const myCirclesMemo = useMemo(() => CircleFactory(CIRCLE_TYPES.MAIN), [svgBounds, width]);
  const minimapCirclesMemo = useMemo(() => CircleFactory(CIRCLE_TYPES.MINIMAP), [width]);

  useEffect(() => {
    const node = zoomRef.current;
    if (!node) return;
    const svg = node?.children[0];
    if (svg) svgref.current = svg;
  }, [zoomRef, zoomRef.current]);

  return (
    <>
      <SRootDiv width={width} height={height} ref={zoomRef}>
        <Zoom width={width} height={height} scaleXMin={1} scaleXMax={4} scaleYMin={1} scaleYMax={4} initialTransformMatrix={zoomMatrix} constrain={constrain}>
          {(zoom: any) => (
            <>
              <svg
                ref={zoom.containerRef}
                width={width + "rem"}
                height={height + "rem"}
                style={{ cursor: zoom.isDragging ? "grabbing" : "grab", touchAction: "none" }}
                onTouchStart={zoom.dragStart}
                onTouchMove={zoom.dragMove}
                onTouchEnd={zoom.dragEnd}
                onMouseDown={zoom.dragStart}
                onMouseMove={zoom.dragMove}
                onMouseUp={zoom.dragEnd}
                onMouseLeave={(e: any) => {
                  if (zoom.isDragging) zoom.dragEnd();
                }}
              >
                <Grid
                  top={0}
                  left={0}
                  xOffset={zoomMatrix.translateX}
                  yOffset={zoomMatrix.translateY}
                  xScale={myXScale}
                  yScale={myYScale}
                  width={remToPx(width + 1000 + "rem")}
                  height={remToPx(height + 1000 + "rem")}
                  strokeOpacity={0.1}
                />

                <RectClipPath id="zoom-clip" width={width + "rem"} height={height + "rem"} />
                <g transform={zoom.toString()}>{myCirclesMemo}</g>
                <g
                  clipPath="url(#zoom-clip)"
                  transform={`
                    scale(0.25)
                    translate(${remToPx(width * 3 + "rem") - remToPx("4rem")}, ${remToPx(height * 3 + "rem") - remToPx("4rem")})
                  `}
                >
                  <rect width={width + "rem"} height={height + "rem"} fill="#1a1a1a" />
                  {minimapCirclesMemo}
                  <rect width={width + "rem"} height={height + "rem"} fill="white" fillOpacity={0.2} stroke="white" strokeWidth={4} transform={zoom.toStringInvert()} />
                </g>
              </svg>
              {selectedSA2Tooltips}
              <S_ControlsDiv>
                <SWindowBottom
                  label="Zoom In"
                  icon={<AddIcon />}
                  visible={true}
                  windowName="zoom_in_scatterplot_fullscreen"
                  clickOverride={() => {
                    zoom.scale({ scaleX: 1.2, scaleY: 1.2 });
                  }}
                />
                <SWindowBottom label="Zoom Out" icon={<RemoveIcon />} visible={true} windowName="zoom_out_scatterplot_fullscreen" clickOverride={() => zoom.scale({ scaleX: 0.8, scaleY: 0.8 })} />
                <SWindowBottom label="Reset" visible={true} windowName="reset_scatterplot_fullscreen" icon={<RestartAltIcon />} clickOverride={zoom.clear} />
              </S_ControlsDiv>
            </>
          )}
        </Zoom>

        {svgBounds && tooltipRef.current && (
          <Tooltip
            key={"tooltip"}
            visible={tooltipVisible}
            left={pxToRem(tooltipRef.current.getBoundingClientRect().x - svgBounds.x + tooltipRef.current.getBoundingClientRect().width / 2)}
            top={pxToRem(tooltipRef.current.getBoundingClientRect().y - svgBounds.y) + 0.125}
            data={tooltipData}
          />
        )}
      </SRootDiv>
    </>
  );
};

export default ScatterplotFullscreen;
