import styled from "@emotion/styled";
import theme from "../../styles/theme";
import CancerSelect from "../CancerSelect";
import Indicators from "../Indicator";
import WindowButton from "../WindowButton";
import StoriesIcon from "@mui/icons-material/Assignment";
import Navigation from "../Navigation";
import TutorialIcon from "@mui/icons-material/HelpOutline";
import PCSelect from "../PCSelect";
import SearchBar from "../SearchBar";
import { calcPCSelectPos } from "./utils";
import { lazy, MutableRefObject } from "react";
const MobileViewer = lazy(() => import("../MobileViewer"));

export const SRoot = styled("div")(({ theme: Theme }) => ({
  display: "flex",
  flexDirection: "row",
  fontFamily: "'Source Sans Pro', sans-serif",
  fontSize: 16,
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  [theme.breakpoints.up("xl")]: {
    htmlFontSize: 38,
    fontSize: 38,
  },
  [theme.breakpoints.up("md")]: {
    htmlFontSize: 16,
    fontSize: 16,
  },
  [theme.breakpoints.between("sm", "xl")]: {
    htmlFontSize: 14,
    fontSize: 14,
  },
  [theme.breakpoints.down("md")]: {
    // Chrome perma addressbar hack
    position: "relative",
    htmlFontSize: 12,
    fontSize: 12,
  },

  "&.mobile": {
    flexDirection: "column-reverse",
  },
}));

export const SAtlas = styled("div")({
  width: "100%",
  height: "100%",
  position: "relative",
  top: 0,
  left: 0,
});

export const SCancerSelect = styled(CancerSelect)(({ isMobile, xs, cancerSelectRef }: { isMobile: boolean; xs?: boolean; cancerSelectRef?: MutableRefObject<HTMLDivElement | null> }) => ({
  position: "absolute",
  top: "1rem",
  right: "1rem",
  ...(isMobile && {
    position: "relative",
    ...(xs && {
      position: "fixed",
      maxWidth: "calc(100% - 2rem)",
      top: "1rem",
      left: "1rem",
    }),
  }),
  fontSize: "1.5rem",
}));

export const SLogoLink = styled("a")({
  position: "absolute",
  top: "1rem",
  left: "1rem",
  zIndex: "75",
});

export const SNLWindowButton = styled(WindowButton)({
  position: "absolute",
  right: "5rem",
  bottom: "2rem",
});

export const SENWindowButton = styled(WindowButton)({
  position: "absolute",
  right: "1rem",
  bottom: "2rem",
});

export const SStoriesWindowButton = styled(WindowButton)({
  position: "absolute",
  left: "11.5rem",
  bottom: "2rem",
});

export const STutorialWindowButton = styled(WindowButton)({
  position: "absolute",
  left: "18rem",
  bottom: "2rem",
});

export const SStoriesIcon = styled(StoriesIcon)({
  fontSize: "1.5rem",
});

export const STutorialIcon = styled(TutorialIcon)({
  fontSize: "1.5rem",
});

export const SMobileContainerMobileViewer = styled(MobileViewer)(({ theme: Theme }) => ({
  zIndex: 70,
  position: "absolute",
  left: "1rem",
  bottom: "2rem",
  [theme.breakpoints.down("md")]: {
    width: "calc(100% - 2rem)",
  },
}));

export const SMobileSearchSelectDiv = styled("div")({
  position: "absolute",
  width: "auto",
  display: "flex",
  flexFlow: "wrap",
  top: "1rem",
  right: "1rem",
});

export const SIndicators = styled(Indicators)(({ theme: Theme }) => ({
  position: "absolute",
  right: "1rem",
  top: "4rem",
}));

export const SNavigation = styled(Navigation)(({ theme: Theme }) => ({
  position: "absolute",
  bottom: "2rem",
  left: "1rem",
}));

export const SSearchBar = styled(SearchBar)(({ xs }: { xs?: boolean }) => ({
  position: "absolute",
  top: "1rem",
  left: "12rem",
  "&.mobile": xs
    ? {
        position: "absolute",
        maxWidth: "calc(100% - 2rem)",
        top: "1rem",
        left: "1rem",
      }
    : {
        position: "absolute",
        maxWidth: "auto",
        top: "auto",
        left: "-3rem",
      },
}));

export const SPCSelect = styled(PCSelect)(({ numIndicators }: { numIndicators: number }) => ({
  position: "absolute",
  top: calcPCSelectPos(numIndicators),
  right: "1rem",
  zIndex: 73,
}));

export const SShareWindowButton = styled(WindowButton)({
  position: "absolute",
  bottom: "2rem",
  left: "26rem",
});

export const SWindowButtonDiv = styled("div")(() => ({
  position: "absolute",
  bottom: "2rem",
  left: "9rem",
}));

export const SWindowButton = styled(WindowButton)(({ needMarginTop }: { needMarginTop?: boolean }) => ({
  marginRight: "0.5rem",
  ...(needMarginTop && { marginTop: "0.5rem" }),
}));

export const SButtonsDiv = styled("div")(() => ({
  position: "absolute",
  bottom: "2rem",
  left: "1rem",
}));
