import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import CheckIcon from "@mui/icons-material/CheckBox";
import styled from "@emotion/styled";
import { BREAKPOINTS } from "../../../constants";

export const SSpan = styled("span")(({ theme }: { theme: any }) => ({
  pointerEvents: "none",
  paddingLeft: "0.5rem",
  color: theme.palette.text.primary,
  fontSize: "1rem",
}));

export const SFormControl = styled(FormControl)(({ theme, active, screen }: { theme: any; active: boolean; screen: any }) =>
  active && screen.width >= BREAKPOINTS.WIDTH && screen.height >= BREAKPOINTS.HEIGHT ? 
  { ...theme.components.select.formControlIndicator, 
    paddingLeft:".5rem"
  } : { ...theme.components.select.formControlSmall }
);

export const SStatsDiv = styled("div")({
  width: "100%",
  height: "auto",
  display: "flex",
  alignItems: "flex-end",
  "& div:first-of-type": {
    paddingLeft: 0,
    borderLeft: "none",
  },
  "& div:last-of-type": {},
});

export const SStatDiv = styled("div")(({ theme }: { theme: any }) => ({
  alignSelf: "stretch",
  width: "50%",
  padding: "2%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  borderLeft: "0.05rem solid " + theme.palette.text.primary,
  "& p": {
    lineHeight: "1rem",
    fontSize: "1.1rem",
    flexGrow: "1",

    alignSelf: "stretch",
  },
  "& h3": {
    fontSize: "1.5rem",
  },
}));

export const SPaper = styled(Paper)(({ theme }: { theme: any }) => ({
  ...theme.layers.draggable,
  color: theme.palette.text.primary,
  width: "100%",
}));

export const SSection = styled("section")(({ theme }: { theme: any }) => ({
  borderBottom: theme.components.border.border,
  borderRadius: theme.components.border.borderRadius,
  paddingLeft: "1em",
  paddingRight: "1em",
}));

export const SHeadingDiv = styled("div")(({ theme }: { theme: any }) => ({
  ...theme.typography.label,
  fontSize: "1.33rem",
  width: "100%",
  color: "#FFF",
}));

export const SHeadingTextSpan = styled("span")({
  display: "inline-block",
  width: "100%",
  paddingTop: "0.5rem",
  paddingBottom: "0.25rem",
});

export const SContentsDiv = styled("div")((props: any) => ({}));

export const SViewButton = styled(Button)({
  padding: "0.25rem 0 ",
  minHeight: "1.5rem",
});

export const SCheckIcon = styled(CheckIcon)(({ theme }: { theme: any }) => ({
  ...theme.components.select.checkIcon,
  ...theme.components.icon,
}));
