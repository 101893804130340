import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { SDialog, SDialogContent, SDialogContentTitle, SErrorIcon, SDialogContentName, SDialogContentMessage } from "./emotion";
import { ERROR_ACTIONS } from "../../../types";

interface Props {
  name: string;
  action?: ERROR_ACTIONS;
  handleAction?: Function;
  code?: string;
  string?: string;
  title?: string;
  message?: string;
}

const ErrorDialog = (props: Props) => {
  const { code = "", string = "", action = ERROR_ACTIONS.NONE, name, title, message } = props;
  const errorTitle = code ? `${name} - ${code}` : name;

  const handleAction = () => {
    switch (action) {
      case ERROR_ACTIONS.REFRESH:
        window.location.reload();
        break;
      case ERROR_ACTIONS.HOMEPAGE:
        window.location.href = "/";
        break;
      case ERROR_ACTIONS.TRIGGER:
        if (props.handleAction) props.handleAction();
        else {
        }
        break;
      default:
        if (props.handleAction) props.handleAction();
        else {
        }
    }
  };
  return (
    <SDialog fullScreen open={true} onClose={handleAction} onClick={handleAction} aria-labelledby={title}>
      <SDialogContent>
        <SDialogContentTitle>Something went wrong...</SDialogContentTitle>
        <SErrorIcon />
        <SDialogContentName>{errorTitle}</SDialogContentName>
        <SDialogContentMessage>{message}</SDialogContentMessage>
      </SDialogContent>
      <DialogActions>
        <Button variant="contained" fullWidth onClick={handleAction} color="secondary">
          Back
        </Button>
      </DialogActions>
    </SDialog>
  );
};

export default ErrorDialog;
