import { useState, useRef, useEffect } from "react";
import Indicator from "./Indicator";
import { useTheme } from "@mui/material/styles";
import { IndicatorType } from "../../types";
import { useAppDispatch, useAppSelector } from "../../redux/types";
import { SDraggableWindow, SSeperatorDiv, SPaper } from "./emotion";
import * as UIActions from "../../redux/features/uiSlice";

const COMPONENT_NAME = "indicatorPanel";

interface IndicatactorsProps {
  isStatic?: boolean;
  staticState?: any;
  className?: string;
}

const Indicators = (props: IndicatactorsProps) => {
  const {
    staticState = {
      visible: true,
      mode: 0,
      pos: { x: 0, y: 0 },
      indicators: [],
    },
    isStatic = false,
  } = props;

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const component = useAppSelector((state) => state.ui.indicatorPanel);
  const { indicators, order, screen } = useAppSelector((state) => state.ui);

  const ref = useRef<HTMLDivElement | null>(null);
  const [visible, setVisible] = useState<boolean>(isStatic ? staticState.visible : component.visible);
  const [mode, setMode] = useState<number>(isStatic ? staticState.mode : component.mode);
  const [pos, setPos] = useState<{ x: number; y: number }>(isStatic ? staticState.pos : component.pos);

  let orderIndex: number = order.indexOf(COMPONENT_NAME);

  const resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      const t = entry.target;
      if (t) {
        // Firefox implements `contentBoxSize` as a single content rect, rather than an array
        const { x, y, width, height } = t.getBoundingClientRect();

        dispatch(
          UIActions.updateDimensions({
            name: COMPONENT_NAME,
            pos: {
              x,
              y,
            },
            width,
            height,
          })
        );
      }
    }
  });

  useEffect(() => {
    if (!ref.current) return;
    const el = ref.current?.children[0];
    if (!el) return;
    resizeObserver.observe(el);
  }, [ref.current]);

  useEffect(() => {
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const RenderIndicators = (props: any) => {
    return (
      <div>
        {mode === 0 ? (
          <Indicator index={0} isStatic={isStatic} staticState={indicators[0]} />
        ) : (
          indicators.map((indicator: IndicatorType, key: number) => {
            return [
              <Indicator key={`indicator_${key}`} index={key} isStatic={isStatic} staticState={indicators[key] && indicators[key]} />,
              key < indicators.length - 1 && <SSeperatorDiv theme={theme} key={`separator_${key}`} />,
            ];
          })
        )}
      </div>
    );
  };

  return (
    <div ref={ref}>
      {!isStatic ? (
        <SDraggableWindow
          disabled={true}
          width={screen.width}
          height={screen.height}
          windowName="indicatorPanel"
          visible={component.visible}
          pos={component.pos}
          order={orderIndex}
          className={props.className}
        >
          <RenderIndicators />
        </SDraggableWindow>
      ) : (
        <SPaper theme={theme} className={props.className}>
          <RenderIndicators />
        </SPaper>
      )}
    </div>
  );
};

export default Indicators;
