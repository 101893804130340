import Button from "@mui/material/Button";
import styled from "@emotion/styled";

export const SButtonsDiv = styled("div")({
  display: "flex",
  flexWrap: "nowrap",
  alignItems: "flex-start",
  width: "100%",
});

export const SFilterButton = styled(Button)(() => ({
  fontSize: "0.8rem",
  width: "33.33%",
  minWidth: "3rem",
  minHeight: "1rem",
  padding: "0.25rem",
  border: "0.05rem solid #6D6E70",
  borderRadius: "0.25rem",
  display: "inline-block",
  "& span": {
    pointerEvents: "none",
  },
}));

export const S_H2 = styled("h2")({
  marginLeft: ".5rem",
  fontSize: "1.1rem"
});
