import { useTranslation } from "react-i18next";
import * as GlobeThunk from "../../../../redux/features/globeSlice/index";
import Typography from "@mui/material/Typography";
import Scatterplot from "../../../charting/Scatterplot";
import ScatterplotFullscreen from "../../../charting/ScatterplotFullscreen";
import { useMemo } from "react";
import { CancerAtlasState } from "../../../../redux/features/cancerAtlasSlice";
import { HTMLVisualisationState } from "../../../../redux/features/htmlVisualisationSlice";
import { FilterState } from "../../../../redux/features/filterSlice";
import { SLoadingSequenceDiv } from "./emotion";

interface VPlotProps {
  indicatorValue: number;
  sexValue: number;
  cancerValue: FilterState["filters"]["cancergrp"]["value"];
  cancerData: CancerAtlasState["cancerData"];
  sa2Data: CancerAtlasState["sa2Data"];
  activeSA2s: GlobeThunk.GlobeState["activeSA2s"];
  selectedSA2s: HTMLVisualisationState["sa2s"];
  flyToSA2: Function;
  flyToActive: GlobeThunk.GlobeState["flyToActive"];
  loadingDensityData?: boolean;
  isFullscreen?: boolean;
  screen: any;
  theme: any;
  containerRef: any;
}

export const VPlot = ({
  indicatorValue,
  sexValue,
  cancerValue,
  cancerData,
  sa2Data,
  activeSA2s,
  selectedSA2s,
  flyToSA2,
  flyToActive,
  loadingDensityData,
  isFullscreen,
  screen,
  theme,
  containerRef,
}: VPlotProps) => {
  const { t } = useTranslation();
  let nullTest: boolean = cancerData == null || cancerData[indicatorValue] == null || cancerData[indicatorValue][sexValue] == null || sa2Data == null;
  const handleData = () => (nullTest ? [] : cancerData[indicatorValue][sexValue].filter((x: any) => x.cancer_grp == cancerValue));
  let data: any = useMemo(() => handleData(), [indicatorValue, sexValue, cancerValue, cancerData]);
  const handleSelectedData = () => (data.length > 0 ? data.filter((x: any) => selectedSA2s.includes(x.postcode.toString())).sort((x: any) => selectedSA2s.indexOf(x.postcode.toString())) : []);
  let selectedData: any = useMemo(() => handleSelectedData(), [selectedSA2s, data]);

  if (nullTest)
    return (
      <SLoadingSequenceDiv theme={theme}>
        <Typography variant="caption" className={"loadingText"}>
          {t("loading.data")}
        </Typography>
      </SLoadingSequenceDiv>
    );
  if (data.length === 0) {
    return (
      <SLoadingSequenceDiv theme={theme}>
        <Typography variant="caption" className={"loadingText"}>
          No Data
        </Typography>
      </SLoadingSequenceDiv>
    );
  }
  return isFullscreen ? (
    <ScatterplotFullscreen
      data={data}
      sa2Data={sa2Data}
      activeSA2s={activeSA2s}
      selectedSA2s={selectedData}
      callFlyToSA2={flyToSA2}
      loadingDensityData={loadingDensityData}
      flyToActive={flyToActive}
      containerRef={containerRef}
    />
  ) : (
    <Scatterplot data={data} sa2Data={sa2Data} activeSA2s={activeSA2s} selectedSA2s={selectedData} callFlyToSA2={flyToSA2} loadingDensityData={loadingDensityData} flyToActive={flyToActive} />
  );
};
