import { useTranslation } from "react-i18next";
import * as FilterActionsThunk from "../../../redux/features/filterSlice/thunk";
import * as UIActionsThunk from "../../../redux/features/uiSlice/thunk";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material/styles";
import { css } from "@emotion/css";
import { useAppDispatch, useAppSelector } from "../../../redux/types";
import PCSelectDisplay from "./PCSelectDisplay";
const COMPONENT_NAME = "indicators";

interface PCSelectContainerProps {
  index: number;
  RenderHeading?: any;
}

const PCSelectContainer = (props: PCSelectContainerProps) => {
  const { index, RenderHeading } = props;
  const { indicators } = useAppSelector((state) => state.ui);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handlePCFilter = (newValue: any) => {
    if (typeof newValue === "number" && (newValue !== null || newValue !== undefined)) {
      // Get current value according to indicator window active state
      const currentPC = indicators[index].pc;
      const pcTitle = t(`filters:pc.values.${currentPC}`);

      if (newValue !== currentPC) {
        // Update postcode filter value for active indicator
        if (indicators[index].active) {
          dispatch(FilterActionsThunk.updateFilter({ fieldName: "pc", type: "radio", _name: "", _value: newValue }));
        }
        // Update selected postcode for UI
        dispatch(UIActionsThunk.updateIndicator({ index, data: { pc: newValue } }));
      }
    }
  };

  return <PCSelectDisplay pc={indicators[index].pc} handleSelect={handlePCFilter} RenderHeading={RenderHeading} />;
};

export default PCSelectContainer;
