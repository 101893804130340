import styled from "@emotion/styled";

export const SRootDiv = styled("div")(({ top, left }: { top: number; left: number }) => ({
  fontSize: ".8rem",
  position: "absolute",
  width: "auto",
  zIndex: 10,
  pointerEvents: "none",
  top: `${top}rem`,
  left: `${left}rem`,
}));

export const SGenericDiv = styled("div")({
  background: "#333",
  border: ".063rem solid #dedede",
  borderRadius: ".25rem",
  textAlign: "center",
  top: "-.375rem",
  transform: "translate(-50%,-100%)",
  whiteSpace: "nowrap",
  fontSize: ".625rem",
  padding: ".25rem",
  "&:before": {
    content: "' '",
    top: "100%",
    left: "50%",
    width: "0",
    height: "0",
    display: "block",
    position: "absolute",
    transform: "translate(-50%,0%)",
    borderLeft: ".375rem solid transparent",
    borderRight: ".375rem solid transparent",
    borderTop: ".375rem solid #dedede",
  },
  "&:after": {
    content: "' '",
    top: "100%",
    left: "50%",
    width: "0",
    height: "0",
    display: "block",
    position: "absolute",
    transform: "translate(-50%,0%)",
    borderLeft: ".25rem solid transparent",
    borderRight: ".25rem solid transparent",
    borderTop: ".25rem solid #333",
  },
});

export const SActiveDiv = styled("div")({
  right: "100%",
  borderRight: ".125rem solid #FFF",
  height: "3.375rem",
  pointerEvents: "none",
  "&.reverse": {
    bottom: 0,
  },
});
export const TOOLTIP_BALL_SIZE = "0.25rem";
export const SCircleDiv = styled("div")(({ color }: { color: string }) => ({
  left: "100%",
  height: TOOLTIP_BALL_SIZE,
  width: TOOLTIP_BALL_SIZE,
  borderRadius: ".25rem",
  border: ".125rem solid #FFF",
  background: `${color ?? "green"}`,
  transform: "translate(-.188rem,-.188rem)",
  pointerEvents: "none",
  "&.reverse": {
    bottom: 0,
    transform: "translate(-.188rem,.188rem)",
  },
}));

export const SActiveContentDiv = styled("div")(({ color, textColor }: { color: string; textColor: string }) => ({
  position: "relative",
  right: "100%",
  bottom: 0,
  textAlign: "left",
  fontSize: ".625rem",
  padding: ".25rem",
  whiteSpace: "nowrap",
  color: `${textColor}`,
  pointerEvents: "none",
  "&.reverse": {
    top: 0,
    bottom: "auto",
  },
  background: `${color}`,
}));
