import React, { ReactElement, RefObject } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { SContentDiv, SGridDiv, SViewDiv } from "./emotion";

interface GridViewProps {
  data: any;
  boxes: ReactElement[][];
  selected: number;
  indicatorIndex: number;
}

const GridView = ({ data, boxes, selected, indicatorIndex }: GridViewProps) => {
  const { t } = useTranslation();

  return (
    <SContentDiv>
      <Typography variant="subtitle1" component="h3">
        {t(`filters:indicator.values.${indicatorIndex}`)}
      </Typography>
      <SViewDiv key={`cancer_grid_${indicatorIndex}`}>
        <SGridDiv children={boxes[indicatorIndex]}></SGridDiv>
      </SViewDiv>
    </SContentDiv>
  );
};
export default GridView;
