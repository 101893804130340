import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import DataContainer from "./DataContainer";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { remToPx } from "../../styles/theme";
import { PopupOrigin } from "../../types";
import { css } from "@emotion/css";
import { POPUP_WIDTH, POPUP_HEIGHT, BREAKPOINTS } from "../../constants";
import { useAppSelector } from "../../redux/types";
import { SButton, SButtonsDiv, SCloseIcon, SContainerDiv, SDraggableWindow, SFooterDiv, SGridIcon, SHeaderDiv, SPaper, STitleTypography } from "./emotion";
import PC4Container from "./PC4Container";
import { checkPopupBounds } from "./checkPopupBounds";

interface PopupProps {
  title: string;
  sa2: any;
  posDefault?: { x: number; y: number };
  mode?: number;
  staticBool?: boolean;
  staticState?: {
    visible: boolean;
    pos: {
      x: number;
      y: number;
    };
    mode: number;
  };
  handleClose?: (sa2: any) => void;
  bringToFront?: (sa2: any) => void;
  order?: any;
  docked?: boolean;
  origin?: PopupOrigin;
  adjusted?: boolean;
}

const Popup = (props: PopupProps) => {
  let { staticBool = false, sa2, bringToFront, title, adjusted = false, order, posDefault = { x: 0, y: 0 }, docked = true, origin = PopupOrigin.MAP } = props;
  const hasRerendered = useRef<boolean>(false);
  const [mode, setMode] = useState<any>(props.mode ?? 0);
  const { screen, dimensions } = useAppSelector((state) => state.ui);
  const touchScreen = useMediaQuery("(pointer: coarse)");
  const [pos, setPos] = useState<{ x: number; y: number }>(
    screen.width >= BREAKPOINTS.WIDTH || screen.height >= BREAKPOINTS.HEIGHT ? { x: Math.round(posDefault.x), y: Math.round(posDefault.y) } : { x: 0, y: 0 }
  );
  const [_docked, _setDocked] = useState<any>(screen.width >= BREAKPOINTS.WIDTH || screen.height >= BREAKPOINTS.HEIGHT ? docked : false);
  const [_adjusted, _setAdjusted] = useState<boolean>(adjusted);
  const theme = useTheme<any>();
  const { t } = useTranslation();
  const inputRef = useRef<null | HTMLDivElement>(null);
  const TOOLTIP_STYLE = {
    tooltip: css(theme.components.tooltip.tooltip),
    tooltipPlacementTop: css(theme.components.tooltip.tooltipPlacementTop),
    tooltipPlacementBottom: css(theme.components.tooltip.tooltipPlacementBottom),
  };

  useEffect(() => {
    //1st render
    if (!hasRerendered.current) {
      const { x, y, adj } = checkPopupBounds(posDefault, screen, dimensions);

      posDefault = { x, y };
      _setAdjusted(adj);
      hasRerendered.current = true;
    }
    //2nd...n -1 render
    else {
      adjusted = false;
      if (_adjusted) _setAdjusted(false);
    }
    if (_docked && pos) {
      if (inputRef.current) {
        let width = inputRef.current.offsetWidth;
        let height = inputRef.current.offsetHeight;
        setPos({ x: Math.round(posDefault.x - width / 2), y: Math.round(posDefault.y - (height + remToPx("1rem"))) });
      } else {
        setPos({ x: Math.round(posDefault.x), y: Math.round(posDefault.y) });
      }
    }
  }, [posDefault]);

  const handleClose = (event: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if(touchScreen && event.type === "click") {
      event.stopPropagation();
      return;
    }
    if (props.handleClose) props.handleClose(sa2);
  };

  const handlePosition = (pos: { x: number; y: number }) => {
    setPos(pos);
    _setDocked(false);
    if (bringToFront) bringToFront(sa2);
  };

  const toggleView = (event: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => {
    if(touchScreen && event.type === "click") {
      event.stopPropagation();
      return;
    }
    // Check current view mode
    if (bringToFront) bringToFront(sa2);
    if (mode === 0) {
      // Set mode to grid view
      setMode(1);
    } else {
      // Set mode to list view
      setMode(0);
    }
  };

  const renderContents = () => {
    return (
      <div key={`popup_${sa2}`} id={`popup_${sa2}`}>
        <SContainerDiv theme={theme} ref={inputRef}>
          <SHeaderDiv className={"dragHandle"}>
            <STitleTypography variant="h2">{title}</STitleTypography>
            <SButtonsDiv>
              <Tooltip id={`popup_btnViewToggle_tooltip`} classes={TOOLTIP_STYLE} title={mode === 0 ? t("popup.toggle.grid") : t("popup.toggle.list")} placement="top" arrow disableFocusListener>
                <SButton
                  // Label according to current view mode
                  aria-label={mode === 0 ? t("popup.toggle.grid") : t("popup.toggle.list")}
                  variant="text"
                  className={mode === 1 ? "selected" : ""}
                  // Toggle View Mode Change
                  onClick={toggleView}
                  onTouchEnd={toggleView}
                  // Use color and mode state value to determine active state
                  color={mode === 1 ? "primary" : "secondary"}
                >
                  <SGridIcon />
                </SButton>
              </Tooltip>
              {!staticBool && (
                <Tooltip id={`popup_btnClose_tooltip`} classes={TOOLTIP_STYLE} title={t("close")} placement="top" arrow disableFocusListener>
                  <SButton id={`${sa2}-popup-close`} aria-label={t("close")} variant="text" onClick={handleClose} onTouchEnd={handleClose} color="secondary">
                    <SCloseIcon />
                  </SButton>
                </Tooltip>
              )}
            </SButtonsDiv>
          </SHeaderDiv>
          <PC4Container sa2={sa2}></PC4Container>
          <DataContainer staticBool={staticBool} mode={mode} sa2={sa2} isMobile={screen.width < BREAKPOINTS.WIDTH || screen.height < BREAKPOINTS.HEIGHT} />
          <SFooterDiv className={"dragHandle"} />
        </SContainerDiv>
      </div>
    );
  };
  let visible = pos.x >= -remToPx(POPUP_WIDTH) && pos.x < screen.width && pos.y >= -remToPx(POPUP_HEIGHT) && pos.y < screen.height;

  return !staticBool ? (
    <SDraggableWindow
      adjusted={hasRerendered.current ? _adjusted : adjusted}
      docked={_docked}
      theme={theme}
      windowName={sa2}
      visible={visible}
      pos={pos}
      handleClose={handleClose}
      handleUpdate={handlePosition}
      order={order}
      screen={screen}
    >
      {renderContents()}
    </SDraggableWindow>
  ) : (
    <SPaper theme={theme}>{renderContents()}</SPaper>
  );
};

export default Popup;
