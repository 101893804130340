import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
import { Provider } from "react-redux";
import "./index.css";
import Layout from "./components/Layout";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import { FilterState, initFilterValues } from "./redux/features/filterSlice";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import PassiveLoader from "./components/PassiveLoader";

/* ========================================== */
// i18 INIT
/* ========================================== */
const populateFilters = () => {
  // Cancer Group Values
  const filterBundle = i18n.getResourceBundle(i18n.languages.find((lng) => i18n.hasResourceBundle(lng, "filters")) || "nl", "filters");

  Object.keys(filterBundle).map((filter) => {
    const f = filter as keyof FilterState["filters"];
    const defaultFilter = store.getState().filter.filters[f];

    if (defaultFilter && "values" in defaultFilter && Array.isArray(defaultFilter.values) && filterBundle[filter].values) {
      const filterValues = Object.keys(filterBundle[filter].values).map((key) => {
        return {
          ...defaultFilter.values.find(({ Id }: { Id: any }) => Id == key),
          Title: i18n.t(`filters:${filter}.values.${key}`),
        };
      });

      store.dispatch(initFilterValues({ fieldName: f, values: filterValues }));
    }
  });
};
i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: { order: ["cookie","path", "navigator"] },
    fallbackLng: "nl",
    ns: ["messages", "common", "filters"],
    defaultNS: "messages",
    interpolation: {
      escapeValue: false,
    },
    debug: false,
    supportedLngs: ["nl", "en"],
  })
  .then(() => populateFilters());
i18n.on("languageChanged", populateFilters);
const theme = createTheme();
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Suspense
            fallback={
              <>
                <PassiveLoader />
              </>
            }
          >
            <Layout />
          </Suspense>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
