import { styled } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import ErrorIcon from "@mui/icons-material/ErrorOutline";

export const SDialog = styled(Dialog)(({ theme }: { theme: any }) => ({
  backgroundColor: theme.palette.background.dark,
  textAlign: "center",
  cursor: "pointer",
}));

export const SDialogContent = styled(DialogContent)({
  display: "inline-flex",
  flexDirection: "column",
  justifyContent: "center",
});

export const SDialogContentTitle = styled(DialogContentText)({
  fontSize: "2rem",
  fontWeight: "bold",
});

export const SDialogContentMessage = styled(DialogContentText)(({ theme }: { theme: any }) => ({
  fontSize: "1.1rem",
  color: theme.palette.text.primary,
}));

export const SDialogContentName = styled(DialogContentText)(({ theme }: { theme: any }) => ({
  fontSize: "2rem",
  color: theme.palette.text.hint,
}));

export const SErrorIcon = styled(ErrorIcon)(({ theme }: { theme: any }) => ({
  width: "10rem",
  height: "10rem",
  fill: theme.palette.text.hint,
  margin: "0 auto",
}));
