import * as URIActions from "../uriSlice";
import * as Globe from "../globeSlice";
import * as UI from "../uiSlice";
import * as FilterThunk from "../filterSlice/thunk";
import * as HTMLVisualisation from "../htmlVisualisationSlice";
import geoViewport from "@mapbox/geo-viewport";
import { decodeHash } from "../utils";
import { buildHash } from "../utils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppState } from "../../store";

export const stateFromURI = createAsyncThunk("uri/stateFromURIThunk", async ({ hashURI }: { hashURI: string }, thunkAPI) => {
  const hash = hashURI.substr(1);
  let location: any;
  const locIdx = hash.indexOf("@");
  if (locIdx > 0) {
    const loc = hash.substr(locIdx + 1);
    const coords = loc.split(",");
    if (coords[2].indexOf("z") != -1) {
      const zoom = coords[2].substring(0, coords[2].indexOf("z"));
      const coord0 = parseInt(coords[0]);
      const coord1 = parseInt(coords[1]);
      const zoomInt = parseInt(zoom);
      if (coord0 && coord1 && zoomInt && !isNaN(coord0) && !isNaN(coord1) && !isNaN(zoomInt)) {
        if (coord0 >= -90 && coord0 <= 90 && coord1 >= -180 && coord1 <= 180 && zoomInt > 0 && zoomInt <= 22) {
          location = { center: [coords[1], coords[0]], zoom: zoom };
        }
      }
    }
  }
  var data;
  var dataIdx = hash.indexOf("&data=");
  if (dataIdx > 0) {
    data = hash.substr(hash.indexOf("&data=") + 6).split(",")[0];
  }
  thunkAPI.dispatch(URIActions.setLoadingState(true));
  thunkAPI.dispatch(URIActions.setURIState({ location, data }));
});

export const applyStoryPanelState = createAsyncThunk("uri/applyStoryPanelStateThunk", async ({ storyURI, screen }: { storyURI: any; screen: any }, thunkAPI) => {
  // take the data and decode it, replacing itself
  thunkAPI.dispatch(URIActions.setLoadingState(true));
  await thunkAPI.dispatch(stateFromURI({ hashURI: storyURI }));
  thunkAPI.dispatch(uriToState());
});

export const uriToState = createAsyncThunk("uri/uriToStateThunk", async (_, thunkAPI) => {
  // take the data and decode it, replacing itself
  const currState = thunkAPI.getState() as AppState;
  const location = currState.uri.uriObj.location;
  if (currState.uri.stateLoading) thunkAPI.dispatch(URIActions.setLoadingState(false));
  if (location) {
    var converted: any = [];
    for (let i = 0; i < location.center.length; i++) {
      if (typeof location.center[i] === "string") {
        converted.push(Number(location.center[i]));
        continue;
      }
      converted.push(location.center[i]);
    }
    var zoom = location.zoom;
    if (typeof zoom === "string") zoom = Number(zoom);
    if (location) {
      thunkAPI.dispatch(Globe.updateMap({ center: converted, zoom }));
    }
  }

  var data = currState.uri.uriObj.data;
  if (data) {
    const decodedState = decodeHash(data, currState.filter.filters, currState.cancerAtlas.sa2Data);
    if (typeof decodedState === "object") {
      thunkAPI.dispatch(UI.applyStateUI(decodedState.ui));
      thunkAPI.dispatch(FilterThunk.applyState({ state: decodedState.filters }));
      thunkAPI.dispatch(HTMLVisualisation.applyStateHTML(decodedState.sa2s));
    } else {
      thunkAPI.dispatch(URIActions.setURIState({ data: null }));
    }
  }
});

export const generateUri = createAsyncThunk("uri/generateUriThunk", async ({ location, screen }: { location: any; screen: any }, thunkAPI) => {
  const currState = thunkAPI.getState() as AppState;
  const filters = currState.filter.filters;
  const ui = { mapFilters: currState.ui.mapFilters, indicatorPanel: currState.ui.indicatorPanel, indicators: currState.ui.indicators, barcodes: currState.ui.barcodes };
  const sa2s = currState.htmlVisualisation.sa2s;
  const sa2Data = currState.cancerAtlas.sa2Data;
  let locationLocal = location;

  if (!locationLocal) {
    locationLocal = currState.globe.map;
  }

  const data = buildHash(filters, ui, sa2s, sa2Data);
  const bounds = geoViewport.bounds([locationLocal.center[0], locationLocal.center[1]], locationLocal.zoom, [screen.width, screen.height], 512);
  const coords = geoViewport.viewport(bounds, [1920, 1080], 2.25, 22, 512, true);
  const lat = (+coords.center[1]).toFixed(4);
  const lng = (+coords.center[0]).toFixed(4);
  let zoom: any = Math.round(+coords.zoom * 4) / 4;
  zoom = Number.isInteger(zoom) ? zoom : zoom.toFixed(2);
  var statefilters = {
    cancergrp: {
      value: filters.cancergrp.value,
    },
    sex: {
      value: filters.sex.value,
    },
    pc: {
      value: filters.pc.value,
    },
    diagnoses: {
      inverted: filters.diagnoses.inverted,
      value: filters.diagnoses.value,
    },
    confidence: {
      inverted: filters.confidence.inverted,
      value: filters.confidence.value,
    },
    indicator: {
      value: filters.indicator.value,
    },
  };

  var stateui = {
    mapFilters: {
      visible: ui.mapFilters.visible,
    },
    indicatorPanel: {
      visible: ui.indicatorPanel.visible,
      mode: ui.indicatorPanel.mode,
    },
    indicators: [
      { active: ui.indicators[0].active, value: ui.indicators[0].value, view: ui.indicators[0].view, sex: ui.indicators[0].sex },
    ],
    barcodes: {
      visible: ui.barcodes.visible,
      mode: ui.barcodes.mode,
      value: ui.barcodes.value,
    },
  };

  if (window.location.hash) {
    var hash = window.location.hash;
    var locIdx = hash.indexOf("@");
    var dataIdx = hash.indexOf("&data=");
    var url = locIdx > 0 ? "@" + lat + "," + lng + "," + zoom + "z" : "";
    url += dataIdx > 0 ? "&data=" + data : "";
    window.history.pushState({}, "/#/" + url);
  }

  thunkAPI.dispatch(
    URIActions.setURIState({
      location: { center: [lng, lat], zoom: zoom },
      data: data,
      stateLoading: false,
    })
  );
});
