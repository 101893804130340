import deepmerge from "deepmerge";
import { map_range2 } from "../../utils";
import { SA2DataPieceType } from "../../types";
import { FilterState } from "./filterSlice";
const rixits =
  //   0       8       16      24      32      40      48      56     63
  //   v       v       v       v       v       v       v       v      v
  "!#ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

export const emptyTarget = (value: any) => (Array.isArray(value) ? [] : {});
export const clone = (value: any, options: any) => deepmerge(emptyTarget(value), value, options);

export function combineMerge(target: any, source: any, options: any) {
  var destination = target.slice();
  if (source instanceof Array && target instanceof Array && !isNaN(source[0])) destination = [];

  source.forEach(function (e: any, i: any) {
    if (typeof destination[i] === "undefined") {
      const cloneRequested = options.clone !== false;
      const shouldClone = cloneRequested && options.isMergeableObject(e);
      destination[i] = shouldClone ? clone(e, options) : e;
    } else if (options.isMergeableObject(e)) {
      destination[i] = deepmerge(target[i], e, options);
    } else if (target.indexOf(e) === -1) {
      destination.push(e);
    }
  });

  return destination;
}

export const colorIdx = (logp50: number) => {
  var idx = Math.floor(map_range2(logp50, -0.585, 0.585, 0, 63));
  if (idx > 63) idx = 63;
  else if (idx < 0) idx = 0;
  return idx;
};

export function arrayCompare(arr1: Array<any>, arr2: Array<any>) {
  if (arr1.length !== arr2.length) return false;

  for (var i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
}

export function decodeHash(base64str: string, filters: FilterState["filters"], sa2Data: Array<SA2DataPieceType> | null) {
  var decodeStr = "";
  for (var i = 0; i < base64str.length; i++) {

    decodeStr += rixits.indexOf(base64str[i]).toString(2).padStart(6, "0");
  }
  var cancerIdx = parseInt(decodeStr.slice(0, 5), 2);
  const grpVal = filters.cancergrp.values.find((x) => x.grp === cancerIdx);
  if (grpVal === undefined) return -1;
  var cancerValue = cancerIdx;

  var sex = parseInt(decodeStr.slice(5, 7), 2);
  var validSex = [1, 2, 3];
  if (!validSex.includes(sex)) return -2;

  var validBinary = [0, 1];
  var pc = parseInt(decodeStr.slice(7, 10), 2);

  //INDICATORS
  var validIndicators = [0, 1];
  var indicatorSelected = parseInt(decodeStr.slice(10, 11), 2);
  if (!validIndicators.includes(indicatorSelected)) return -13;
  var indicatorVisible = parseInt(decodeStr.slice(11, 12), 2);
  if (!validBinary.includes(indicatorVisible)) return -14;
  var indicatorMode = parseInt(decodeStr.slice(12, 13), 2);
  if (!validBinary.includes(indicatorMode)) return -15;
  var validView = [0, 1, 2];
  var indicator0View = parseInt(decodeStr.slice(13, 15), 2);
  if (!validView.includes(indicator0View)) return -16;

  var selectedSA2sLength = parseInt(decodeStr.slice(15, 17), 2);
  var selectedSA2s = [];
  //bring back pc3 decimals when decoding for pc4
  const divisor = pc === 3 ? 10 : 1; 
  switch (selectedSA2sLength) {
    case 1: {
      var idx = parseInt(decodeStr.slice(17, 31), 2);
      if (sa2Data && sa2Data.find((x) => x.areacode === idx / divisor) === undefined) return -24;
      selectedSA2s.push(idx / divisor);
      break;
    }
    case 2: {
      var idx = parseInt(decodeStr.slice(17, 31), 2);
      var idx2 = parseInt(decodeStr.slice(31, 45), 2);
      if (sa2Data && (sa2Data.find((x) => x.areacode === idx / divisor) === undefined || sa2Data.find((x) => x.areacode === idx2 / divisor) === undefined)) return -25;
      selectedSA2s.push(idx / divisor);
      selectedSA2s.push(idx2 / divisor);
      break;
    }
  }

  const wantedFilters = {
    cancergrp: {
      value: cancerValue,
    },
    sex: {
      value: sex,
    },
    pc: {
      value: pc,
    },
    indicator: {
      value: indicatorSelected,
    },
  };

  var ui = {
    mapFilters: {
      visible: false, 
    },
    indicatorPanel: {
      visible: indicatorVisible ? true : false,
      mode: indicatorMode,
    },
    indicators: [
      { active: true, value: indicatorSelected, view: indicator0View, sex: sex, pc: pc },
    ],

  };
  let sa2s: Array<any> = [];
  if (selectedSA2sLength) sa2s = selectedSA2s;
  return { filters: wantedFilters, ui, sa2s };
}

export function buildHash(filters: any, ui: any, sa2s: Array<any>, sa2Data: SA2DataPieceType[] | null) {
  var str = [];
  var index = [];
  var runningCnt = 0;
  var binary = "";
  // CANCER
  var cancerIdx = filters.cancergrp.value; 
  str.push(cancerIdx.toString(2).padStart(5, "0"));
  index.push({ cancerId: runningCnt });
  runningCnt += str[str.length - 1].length;
  // SEX
  str.push(filters.sex.value.toString(2).padStart(2, "0"));
  index.push({ sex: runningCnt });
  runningCnt += str[str.length - 1].length;

  // POSTCODE
  str.push(filters.pc.value.toString(2).padStart(3, "0"));
  index.push({ pc: runningCnt });
  runningCnt += str[str.length - 1].length;

  // INDICATOR SELECTED
  str.push(filters.indicator.value.toString(2).padStart(1, "0")); //indicator 11-12
  index.push({ indicator: runningCnt });
  runningCnt += str[str.length - 1].length;
  // INDICATORS MODE
  str.push((ui.indicatorPanel.visible | 0).toString(2).padStart(1, "0")); //indicatorVisible 13-14
  index.push({ indvis: runningCnt });
  runningCnt += str[str.length - 1].length;
  str.push((ui.indicatorPanel.mode | 0).toString(2).padStart(1, "0")); // indicatorMode = 15
  index.push({ indmod: runningCnt });
  runningCnt += str[str.length - 1].length;
  str.push(ui.indicators[0].view.toString(2).padStart(2, "0")); //indicators[0].view 16
  index.push({ indview: runningCnt });
  runningCnt += str[str.length - 1].length;

  // SELECTED SA2s
  let selectedSA2s = sa2s;
  const multiplier = filters.pc.value === 3 ? 10 : 1;
  str.push(selectedSA2s.length.toString(2).padStart(2, "0"));
  index.push({ sa2num: runningCnt });
  runningCnt += str[str.length - 1].length;
  switch (selectedSA2s.length) {
    case 1: {
      var firstSA2 = +selectedSA2s[0] * multiplier; 

      str.push(firstSA2.toString(2).padStart(14, "0"));

      index.push({ sa21: runningCnt });
      runningCnt += str[str.length - 1].length;
      break;
    }
    case 2: {
      var firstSA2 = +selectedSA2s[0] * multiplier; 
      var secondSA2 = +selectedSA2s[1] * multiplier; 
      str.push(firstSA2.toString(2).padStart(14, "0"));
      index.push({ sa21: runningCnt });
      runningCnt += str[str.length - 1].length;
      str.push(secondSA2.toString(2).padStart(14, "0"));
      index.push({ sa22: runningCnt });
      runningCnt += str[str.length - 1].length;
      break;
    }
  }

  var outputstr = "";
  for (var i = 0; i < str.length; i++) {
    outputstr += str[i] + "";
  }

  //buffer for decode
  var mod6 = outputstr.length % 6;
  for (var i = 0; i < mod6; i++) outputstr += "0";
  var partNo = Math.ceil(outputstr.length / 6);
  var base64str = "";
  for (var p = 0; p < partNo; p++) {
    var piece = "";
    piece = outputstr.substring(p * 6, (p + 1) * 6);
    var int = parseInt(piece, 2);
    base64str += rixits.charAt(int) + "";
  }

  return base64str;
}
