import Button from "@mui/material/Button";
import styled from "@emotion/styled";
const MAX_CANCER_SELECT_HEIGHT = "35rem";

export const SList = styled("div")(({ height }: { height: number }) => ({
  width: "100%",
  height: `min(${height}rem, ${MAX_CANCER_SELECT_HEIGHT})`,
  overflowY: "auto",
}));

export const SButton = styled(Button)(({ selectedValue, itemValue, theme }: { selectedValue: number; itemValue: number; theme: any }) => ({
  width: "100%",
  minHeight: "2rem",
  justifyContent: "flex-start",
  padding: 0,
  paddingLeft: "0.5rem",
  textTransform: "none",
  textAlign: "left",
  color: "#A6A8AB",
  fontSize: ".7rem",
  "& span": {
    pointerEvents: "none",
  },
  ...(selectedValue === itemValue && {
    color: "white",
    borderLeft: "0.25rem solid " + theme.palette.primary.main,
    borderRadius: 0,
  }),
}));
