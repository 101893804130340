import Draggable from "react-draggable";
import ErrorBoundary from "../ErrorBoundary";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import * as UIActions from "../../redux/features/uiSlice";
import { useState } from "react";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import { BREAKPOINTS } from "../../constants";
import { useAppDispatch, useAppSelector } from "../../redux/types";
import { ERROR_ACTIONS } from "../../types";
import { SGrow, SPaper } from "./emotion";

interface Props {
  windowName: string;
  visible: boolean;
  pos?: { x: number; y: number };
  order?: number;
  handleClose?: any;
  handleUpdate?: any;
  handleDragStart?: any;
  handleDrag?: any;
  handleDragStop?: any;
  children?: any;
  className?: string;
  theme?: any;
  disabled?: boolean;
}

const DraggableWindow = (props: Props) => {
  const dispatch = useAppDispatch();
  const [dragging, setDragging] = useState(false);
  const { windowName, visible, pos = { x: 0, y: 0 }, order = 0, children, disabled = false } = props;
  const { isMobile, screen } = useAppSelector((state) => state.ui);
  const theme: any = useTheme();

  const handleClose = () => {
    if (props.handleClose) props.handleClose();
    else dispatch(UIActions.updateComponent({ name: windowName as keyof UIActions.UIState, data: { visible: false } }));
  };

  const handleDrag = (event: any, data: any) => {};

  const handleDragStop = (event: any, pos: any) => {
    const { x, y } = pos;
    if (props.handleUpdate) {
      // Pass position to parent if update function exists
      props.handleUpdate(pos);
    } else {
      // Pass position to reducer
      dispatch(UIActions.updateComponent({ name: windowName as keyof UIActions.UIState, data: { pos: { x, y } } }));
      dispatch(UIActions.reorderDraggables(windowName));
    }
    setDragging(false);
  };

  const handleDragStart = (event: any) => {
    const nodeName = event.target.nodeName.toLowerCase();
    if (nodeName === "button" || nodeName === "path" || nodeName === "svg") {
      return false;
    } else {
      setDragging(true);
    }
  };

  let zIndex = UIActions.LAYERS.DRAGGABLE + order;

  return (
    <ErrorBoundary key={`${windowName}-boundary`} name={"Window Error"} message={`${windowName} failed to load`} action={ERROR_ACTIONS.REFRESH}>
      {screen.width >= BREAKPOINTS.WIDTH && screen.height >= BREAKPOINTS.HEIGHT ? (
        <Draggable
          disabled={disabled}
          key={`${windowName}-draggable`}
          defaultClassNameDragging={theme.components.draggable.dragging}
          handle=".dragHandle"
          bounds="body"
          position={{ x: pos.x, y: pos.y }}
          onStart={props.handleDragStart !== undefined ? props.handleDragStart : handleDragStart}
          onDrag={props.handleDrag !== undefined ? props.handleDrag : handleDrag}
          onStop={props.handleDragStop !== undefined ? props.handleDragStop : handleDragStop}
        >
          <SGrow theme={theme} className={props.className} style={dragging ? { zIndex: 1000, transition: "none" } : { zIndex: zIndex }} in={visible} mountOnEnter unmountOnExit>
            <Paper key={`${windowName}-container`}>{props.children}</Paper>
          </SGrow>
        </Draggable>
      ) : (
        <SPaper theme={theme} key={`${windowName}-container`} className={props.className}>
          {props.children}
        </SPaper>
      )}
    </ErrorBoundary>
  );
};

export default DraggableWindow;
