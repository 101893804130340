import React, { Suspense, useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as FilterActionsThunk from "../../../redux/features/filterSlice/thunk";
import * as UIActionsThunk from "../../../redux/features/uiSlice/thunk";
import * as UIActions from "../../../redux/features/uiSlice";
import { FilterState } from "../../../redux/features/filterSlice";
import { requestSummaryData } from "../../../redux/features/cancerAtlasSlice/thunk";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Collapse from "@mui/material/Collapse";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import ViewToggleIcon from "@mui/icons-material/MoreHoriz";
import PassiveLoader from "../../PassiveLoader";
import SexSelectDisplay from "../SexSelect/SexSelectDisplay";
import SexSelectContainer from "../SexSelect";
import PCSelectContainer from "../PCSelect";
import PCSelectDisplay from "../PCSelect/PCSelectDisplay";
import { useTheme } from "@mui/material/styles";
import { css } from "@emotion/css";
import { SCheckIcon, SContentsDiv, SFormControl, SHeadingDiv, SHeadingTextSpan, SPaper, SSection, SSpan, SStatDiv, SStatsDiv, SViewButton } from "./emotion";
import { useAppDispatch, useAppSelector } from "../../../redux/types";

const VPlotContainer = React.lazy(() => import("../visualisations/VPlotContainer"));
const DensityPlotContainer = React.lazy(() => import("../visualisations/DensityPlotContainer"));
const VIEW_MODES = ["COMPACT", "MID", "FULL"];

interface IndicatorProps {
  filters?: any;
  index: number;
  isStatic?: boolean;
  showSelect?: boolean;
  staticState?: { value: number; view: number; sex: number; pc: number };
}

const Indicator = (props: IndicatorProps) => {
  const {
    //injected
    filters,
    index,
    isStatic = false,
    staticState = {
      value: 0,
      view: 0,
      sex: 0,
      pc: 0,
    },
  } = props;
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const { mode } = useAppSelector((state) => state.ui.indicatorPanel);
  const indicatorFilter = useAppSelector((state) => state.filter.filters.indicator);
  const curCancer = useAppSelector((state) => state.filter.filters.cancergrp.value);
  const cancervals = useAppSelector((state) => state.filter.filters.cancergrp.values);
  const { cancerData, summaryData, loadingSummaries } = useAppSelector((state) => state.cancerAtlas);
  const { indicators, screen, isMobile } = useAppSelector((state) => state.ui);
  const cancerFilter = useAppSelector((state) => state.filter.filters.cancergrp.value);
  const [showSelect, setShowSelect] = useState<boolean>(false);
  const [value, setValue] = useState<number>(isStatic ? staticState.value : indicators[index].value);
  const [view, setView] = useState<number>(isStatic ? staticState.view : indicators[index].view);
  const [sex, setSex] = useState<number>(isStatic ? staticState.sex : indicators[index].sex);
  const [pc, setPC] = useState<number>(isStatic ? staticState.pc : indicators[index].pc);
  const [pcVisible, setPcVisible] = useState<boolean>(false);
  const theme: any = useTheme();
  const COLLAPSE_ENTERED_STYLE = { entered: css({ overflow: "visible" }) };
  const TOOLTIP_STYLE = {
    tooltip: css(theme.components.tooltip.tooltip),
    tooltipPlacementTop: css(theme.components.tooltip.tooltipPlacementTop),
    tooltipPlacementBottom: css(theme.components.tooltip.tooltipPlacementBottom),
  };

  const TOOLTIP_WITH_POPPER_STYLE = {
    ...TOOLTIP_STYLE,
    popper: css(theme.components.tooltip.tooltip.tooltipPopper),
  };

  useEffect(() => {
    if (cancervals && cancervals.length > 0 && curCancer) {
      const cancer = cancervals.find((x) => x.grp === curCancer);
      if (cancer !== undefined) setPcVisible(cancer.pc3 && cancer.pc4);
    }
  }, [cancervals, curCancer]);

  // do componentDidMount logic
  useEffect(() => {
    if (!loadingSummaries && summaryData === null) {
      dispatch(requestSummaryData());
    }
  }, []);

  // do componentDidUpdate logic
  useEffect(() => {
    if (isStatic) {
      setValue(staticState.value);
      setView(staticState.view);
      setSex(staticState.sex);
    }
  }, [staticState]);

  const handleViewModeChange = () => {
    let updatedView = view;
    updatedView = updatedView === 1 ? (updatedView = 2) : updatedView === 2 ? (updatedView = 1) : (updatedView = 1);
    // Update indicator state according to static status
    if (!isStatic) {
      dispatch(UIActionsThunk.updateIndicator({ index, data: { view: updatedView } }));
    } else {
      setView(updatedView);
    }
  };

  const handleIndicator = (newValue: number) => {
    if (typeof newValue === "number" && (newValue !== null || newValue !== undefined)) {
      let currentValue = value;
      // Update when value is not current value
      if (newValue !== currentValue) {
        if (!isStatic) {
          // Update to store
          if (indicators[index].active) {
            // Update indicator filter
            dispatch(FilterActionsThunk.updateFilter({ fieldName: indicatorFilter.FieldName as keyof FilterState["filters"], type: indicatorFilter.Type, _name: "", _value: newValue }));
          }
          // Update selected indicator for UI
          dispatch(UIActionsThunk.updateIndicator({ index, data: { value: newValue } }));
        } else {
          setValue(newValue);
        }
      }
    }
  };

  const handleViewToggle = () => {
    if (mode === 0) dispatch(UIActions.updateComponent({ name: "indicatorPanel", data: { mode: 1 } }));
    else dispatch(UIActions.updateComponent({ name: "indicatorPanel", data: { mode: 0 } }));
  };

  const handleSexChange = (sex: number) => {
    setSex(sex);
  };

  const handlePCChange = (pc: number) => {
    setPC(pc);
  };

  const handleClose = () => {
    dispatch(UIActions.updateComponent({ name: "indicatorPanel", data: { visible: false } }));
  };

  const RenderHeader = (props: any) => {
    let active = !isStatic && indicators[index].active;

    let data: any = useMemo(() => summaryData != null && summaryData.filter((x: any) => x.attributes.cancer_grp == cancerFilter && x.attributes.sex == sex), [summaryData, cancerFilter, sex]);
    if (summaryData == null) return null;
    if (data.length == 0) return null;

    return (
      <div className={"dragHandle"}>
        <SFormControl theme={theme} active={active} screen={screen}>
          <Select
            IconComponent={() => null}
            classes={{ select: css(theme.components.select.selectField) }}
            open={showSelect}
            onOpen={() => {
              setShowSelect(true);
            }}
            onClose={() => {
              setShowSelect(false);
            }}
            onChange={(event: any) => {
              handleIndicator(event.target.value);
            }}
            // Disable default input select
            disabled={true}
            // Get filter value if active indicator
            value={active ? indicatorFilter.value : value}
            input={
              // Disable underline
              <Input classes={{ root: css(theme.components.select.input) }} disableUnderline={true} />
            }
            name="indicator"
          >
            {indicatorFilter.values.map((item: any, index: number) => {
              return (
                <MenuItem key={index} value={item.value}>
                  {item.Title}
                  {data[0].attributes.year_grp && <SSpan theme={theme}>{`(${data[0].attributes.year_grp})`}</SSpan>}
                </MenuItem>
              );
            })}
          </Select>
        </SFormControl>
      </div>
    );
  };

  const RenderStats = () => {
    let data: any = useMemo(
      () => summaryData !== null && summaryData.filter((x: any) => x.attributes.cancer_grp == cancerFilter && x.attributes.sex == sex && x.attributes.pc == pc),
      [summaryData, cancerFilter, sex, pc]
    );

    if (summaryData === null) return null;
    if (data.length === 0) return null;

    let title1 = value === 0 ? t("indicator.diagnoses.rate_header") : t("indicator.excess_deaths.rate_header");
    let value1 = value === 0 ? Math.round(data[0].attributes.avgct)?.toLocaleString() : data[0].attributes.exdthsyr?.toLocaleString();
    let title2 = value === 0 ? t("indicator.diagnoses.rate") : t("indicator.excess_deaths.rate");
    let value2 = value === 0 ? data[0].attributes.incasr?.toLocaleString(undefined, { minimumFractionDigits: 1 }) : data[0].attributes.exdthspct?.toFixed(1) + "%";
    let variation = value === 0 ? data[0].attributes.incmeet : data[0].attributes?.survmeet;
    let statYear = t("indicator.stats.year", { year: data[0].attributes.year_grp });

    return (
      <Tooltip classes={TOOLTIP_STYLE} id={`indicator_stats_tooltip`} title={statYear} placement="top" disableFocusListener>
        <SStatsDiv>
          <SStatDiv theme={theme}>
            <Typography variant="body1" component="p">
              {title1}
            </Typography>
            <Typography variant="h3" component="h3">
              {value1}
            </Typography>
          </SStatDiv>
          <SStatDiv theme={theme}>
            <Typography variant="body1" component="p">
              {title2}
            </Typography>
            <Typography variant="h3" component="h3">
              {value2}
            </Typography>
          </SStatDiv>
        </SStatsDiv>
      </Tooltip>
    );
  };

  const RenderHeading = ({ children }: { children: any }) => {
    return (
      <SHeadingDiv theme={theme}>
        <SHeadingTextSpan theme={theme}>{children}</SHeadingTextSpan>
      </SHeadingDiv>
    );
  };

  return useMemo(() => {
    return (
      <SPaper theme={theme}>
        <RenderHeader />
        {isStatic ? <SexSelectDisplay sex={sex} handleSelect={handleSexChange} /> : <SexSelectContainer index={index} />}
        {/* Mount the PCSelect into the indicators container on mobile only */}
        {isMobile && pcVisible && (
          <>{isStatic ? <PCSelectDisplay pc={pc} handleSelect={handlePCChange} RenderHeading={RenderHeading} /> : <PCSelectContainer index={index} RenderHeading={RenderHeading} />}</>
        )}
        <SContentsDiv>
          <SSection theme={theme}>
            <RenderHeading children={<h2>{t("indicator.stats.header")}</h2>}></RenderHeading>
            <RenderStats />
          </SSection>
          <Collapse classes={COLLAPSE_ENTERED_STYLE} in={true} mountOnEnter unmountOnExit>
            <Suspense fallback={<PassiveLoader />}>
              <VPlotContainer sexValue={sex} indicatorValue={value} />
            </Suspense>
          </Collapse>
          <Collapse classes={COLLAPSE_ENTERED_STYLE} in={true} mountOnEnter unmountOnExit>
            <Suspense fallback={<PassiveLoader />}>
              <DensityPlotContainer sexValue={sex} indicatorValue={value} pc={pc} />
            </Suspense>
          </Collapse>
        </SContentsDiv>
      </SPaper>
    );
  }, [isStatic, i18n, staticState, indicators[index], summaryData, cancerFilter, sex, pc, pcVisible]);
};

export default Indicator;
