import Button from "@mui/material/Button";
import GPSIcon from "@mui/icons-material/GpsNotFixed";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ResetIcon from "@mui/icons-material/Refresh";
import { styled } from "@mui/system";

export const SZoomOutIcon = styled(ZoomOutIcon)({
  fontSize: "1.5rem",
});

export const SGPSIcon = styled(GPSIcon)({
  fontSize: "1.5rem",
});

export const SZoomInIcon = styled(ZoomInIcon)({
  fontSize: "1.5rem",
});

export const SResetIcon = styled(ResetIcon)({
  fontSize: "1.5rem",
});

export const SNavigationDiv = styled("div")(({ theme }: { theme: any }) => ({
  ...theme.layers.primaryButton,
  ...theme.components.border,
  display: "inline-block",
  backgroundColor: theme.palette.background.paper,
}));

export const SButton = styled(Button)(({ theme }: { theme: any }) => ({
  ...theme.components.primaryButton,
}));

export const SGpsButton = styled(Button)(({ theme }: { theme: any }) => ({
  ...theme.components.primaryButton,
  borderRight: "0.05em solid #6D6E70",
  [theme.breakpoints.down("md")]: {
    border: "none",
  },
}));

export const SZoomButton = styled(Button)(({ theme }: { theme: any }) => ({
  ...theme.components.primaryButton,
}));
