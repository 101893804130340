import { useRef, useState } from "react";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled";
import { BREAKPOINTS, MAPBOX_TOKEN } from "../../constants";
import * as UIActions from "../../redux/features/uiSlice";
import * as GlobeActions from "../../redux/features/globeSlice";
import { useAppDispatch, useAppSelector } from "../../redux/types";
import { css } from "@emotion/css";
import { ComponentType, SA2DataPieceType, ScreenType } from "../../types";
const COMPONENT_NAME = "search";
export const SEARCHBAR_BREAKPOINT = 960;

const SSearchClassesDiv = styled("div")(({ theme, open }: { theme: any; open: boolean }) => ({
  ...theme.layers.primaryButton,
  ...theme.components.border,
  boxSizing: "content-box",
  display: "inline-block",
  verticalAlign: "middle",
  width: "2.5rem",
  height: "2.5rem",
  minWidth: "2.5rem",
  minHeight: "2.5rem",
  backgroundColor: theme.palette.background.paper,
  transition: "width 0.25s ease",
  ...(open && {
    [theme.breakpoints.up("md")]: {
      width: "18rem",
    },
    [theme.breakpoints.down("lg")]: {
      width: "19rem",
    },
    [theme.breakpoints.down("md")]: {
      width: "calc(40% + 3rem)",
    },
    "&.mobile": {
      width: "100%",
    },
  }),
}));

const SButton = styled(Button)(({ theme }: { theme: any }) => ({
  ...theme.components.primaryButton,
  borderRight: theme.components.border.border,
  verticalAlign: "top",
}));

const SSuggestionMenuItem = styled(MenuItem)({
  fontSize: "0.95rem",
});

const SSuggestionTextDiv = styled("div")`
  overflow: hidden;
  white-space: no-wrap;
  text-overflow: ellipsis;
`;

const SSpan = styled("span")({
  fontWeight: 300,
});

const SFormControl = styled(FormControl)({
  width: "100%",
});

const SStrong = styled("strong")({
  fontWeight: 500,
});

const SCloseIcon = styled(CloseIcon)({
  fontSize: "1.5rem",
});

const SSearchIcon = styled(SearchIcon)({
  fontSize: "1.5rem",
});

interface SearchBarProps {
  value?: string;
  className?: string;
}
const SearchBar = (props: SearchBarProps) => {
  const dispatch = useAppDispatch();
  const theme: any = useTheme();
  const { screen } = useAppSelector((state) => state.ui);
  const component = useAppSelector((state) => state.ui.search);
  const { sa2Data } = useAppSelector((state) => state.cancerAtlas);
  const storyOpen = useAppSelector((state) => state.ui.stories.visible);

  const sa2DataRef = useRef<any>(null);
  sa2DataRef.current = sa2Data;
  const [value, setValue] = useState<string>(props.value ?? "");
  const [suggestions, setSuggestions] = useState<Array<any>>([]);
  const [showKeyboard, setShowKeyboard] = useState<boolean>(false);
  const [keyboardPos, setKeyboardPos] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [target, setTarget] = useState(null);
  const input: any = useRef<any>(null);
  const open = component.open || (screen.width >= SEARCHBAR_BREAKPOINT && screen.height >= BREAKPOINTS.HEIGHT && !storyOpen);
  const { i18n, t } = useTranslation();

  const TOOLTIP_STYLE = {
    tooltip: css(theme.components.tooltip.tooltip),
    tooltipPlacementTop: css(theme.components.tooltip.tooltipPlacementTop),
    tooltipPlacementBottom: css(theme.components.tooltip.tooltipPlacementBottom),
  };

  const handleSuggestionsFetchRequested: any = ({ value }: { value: any }) => {
    if (value.length >= 3) {
      axios
        .get(
          "https://api.mapbox.com/" +
            "geocoding/v5/mapbox.places/" +
            encodeURI(value) +
            ".json" +
            "?access_token=" +
            MAPBOX_TOKEN +
            "&country=NL" +
            "&language=" +
            i18n.language +
            "&types=region,postcode,district,place,locality,neighborhood,address" +
            "&limit=5"
        )
        .then((response) => {
          var suggestionsLocal = [];
          var sa2suggestions: Array<{ value: { bbox: Array<number> }; label: string }> = [];
          // Find any matching SA2s by name from sa2Data prop
          if (sa2Data !== null) {
            var matchingSA2Names: Array<SA2DataPieceType> = sa2Data.filter((sa2: SA2DataPieceType) => {
              const lowerVal = value.toLowerCase();
              return (
                sa2.areacode?.toString() === lowerVal ||
                sa2.wnpalt?.toLowerCase().includes(lowerVal) ||
                sa2.province?.toLowerCase().includes(lowerVal) ||
                sa2.name?.toLowerCase().includes(lowerVal) ||
                false
              );
            });
            // If less than 5 results.. there can be a lot of results for "East" "King"
            //    could put all the matches but then if someone is searching for a street or a suburb then they would be listed below all the matches :-/
            if (matchingSA2Names.length < 5) {
              // regex needed for dodgy JSON parsing
              sa2suggestions = matchingSA2Names.map((s: SA2DataPieceType) => {
                return { value: { bbox: s.geo.pt }, label: `${s.areacode} ${s.province} ${s.wnpalt}` };
              });
            } else {
              sa2suggestions = [];
            }
          }
          suggestionsLocal = sa2suggestions.concat(
            response.data.features.map((feat: any) => {
              return { value: feat, label: feat.place_name };
            })
          );

          setSuggestions(suggestionsLocal);
        });
    } else {
      setSuggestions([]);
    }
  };
  const handleSuggestionsClearRequested: any = () => {
    setSuggestions([]);
  };
  const handleKeyDown = (event: any) => {
    switch (event.keyCode) {
      case 13: {
        // ENTER
        handleFlyTo(value);
        event.preventDefault();
        break;
      }
    }
  };
  const handleChange = (event: any, { newValue }: { newValue: any }) => {
    var parentElement;
    if (event.target.children.length > 0) parentElement = event.target.children[0];
    else parentElement = event.target.parentElement;
    if (parentElement && parentElement.dataset && parentElement.dataset.component) {
      handleFlyTo(parentElement.dataset.component);
    }
    setValue(newValue);
  };
  const handleSearch = (event: any) => {
    input.current && input.current.focus && input.current.focus();
    handleSuggestionsFetchRequested(value, "search-clicked");
  };
  const handleFlyTo = (value: string) => {
    input.current && input.current.blur && input.current.blur();
    // Trigger Search
    let suggestionLocal: any = suggestions.find((s: any) => s.label === value) ?? suggestions[0];
    if (suggestionLocal) {
      flyTo(suggestionLocal);
      setValue(suggestionLocal.label);
      setShowKeyboard(false);
    }
  };
  const flyTo = (suggestion: any) => {
    if (suggestion.value.bbox) {
      dispatch(GlobeActions.updateMap({ center: suggestion.value.center || suggestion.value.bbox, zoom: 12 })); //fly to
    } else {
      if (suggestion.value.place_type[0] === "address") {
        dispatch(GlobeActions.updateMap({ center: suggestion.value.center, zoom: 16 })); //fly to
      } else {
        dispatch(GlobeActions.updateMap({ center: suggestion.value.center, zoom: 12 })); //fly to
      }
    }
  };
  const handleKeyboardOpen = (event: any) => {
    setShowKeyboard(true);
    setTarget(event.target.id);
  };
  const handleKeyboardClose = () => {
    setShowKeyboard(false);
    setTarget(null);
  };
  const handleKeyboardChange = (newValue: string) => {
    setValue(newValue);
  };
  const handleKeyboardPos = (pos: { x: number; y: number }) => {
    setKeyboardPos(pos);
  };
  const renderInput: any = (inputProps: any) => {
    const { ref, ...other } = inputProps;
    return (
      <SFormControl>
        <TextField
          InputProps={{
            classes: {
              input: css({
                height: "auto",
              }),
            },
            inputRef: ref,
            ...other,
          }}
        />
      </SFormControl>
    );
  };
  // Render Suggestions Container
  const renderSuggestionsContainer: any = (options: any) => {
    const { containerProps, children } = options;
    return (
      <Paper {...containerProps} square>
        {children}
      </Paper>
    );
  };
  const renderSuggestion: any = (suggestion: any, { query, isHighlighted }: { query: any; isHighlighted: any }) => {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);
    return (
      <SSuggestionMenuItem selected={isHighlighted}>
        <SSuggestionTextDiv data-component={suggestion.label}>
          {parts.map((part: any, index: number) => {
            return part.highlight ? <SSpan key={String(index)}>{part.text}</SSpan> : <SStrong key={String(index)}>{part.text}</SStrong>;
          })}
        </SSuggestionTextDiv>
      </SSuggestionMenuItem>
    );
  };
  const inputProps = {
    placeholder: t("search"),
    disableUnderline: true,
    value: value,
    type: "search",
    results: 5,
    onFocus: handleKeyboardOpen,
    onChange: handleChange,
    onKeyDown: handleKeyDown,
    onClose: handleKeyboardClose,
  };

  const autoSuggestTheme = {
    container: css({
      width: "calc(100% - 2.5rem)",
      display: "inline-block",
      verticalAlign: "middle",
      opacity: 100,
      transition: "all 0.25s ease",
      transitionDelay: "0.3s",
      ...(!open && { display: "none" }),
    }),
    containerOpen: css({
      width: "calc(100% - 2.5rem)",
      height: "auto",
      display: "inline-block",
      verticalAlign: "middle",
    }),
    input: css({
      ...theme.components.input,
      margin: 0,
      width: "100%",
      fontSize: "1rem",
    }),
    suggestionsContainerOpen: css({
      zIndex: 1,
      marginTop: theme.spacing.unit,
      left: 0,
      right: 0,
    }),
    suggestionsList: css({
      margin: 0,
      padding: 0,
      listStyleType: "none",
    }),
  };

  return (
    <SSearchClassesDiv theme={theme} open={open} className={`${screen.width < BREAKPOINTS.WIDTH || screen.height < BREAKPOINTS.HEIGHT ? "mobile" : ""} ${props.className}`}>
      <Tooltip id={`searchbar_btnSearch_tooltip`} classes={TOOLTIP_STYLE} title={t("searchBtn")} placement="top" arrow disableFocusListener>
        <SButton
          theme={theme}
          aria-label="Search"
          onClick={
            screen.width >= SEARCHBAR_BREAKPOINT && screen.height >= BREAKPOINTS.HEIGHT && !storyOpen
              ? handleSearch
              : () => {
                  dispatch(UIActions.updateComponent({ name: COMPONENT_NAME, data: { open: !open } }));
                }
          }
        >
          {open && !(screen.width >= SEARCHBAR_BREAKPOINT && screen.height >= BREAKPOINTS.HEIGHT) ? <SCloseIcon /> : <SSearchIcon />}
        </SButton>
      </Tooltip>
      <Autosuggest
        ref={input}
        suggestions={suggestions}
        renderSuggestion={renderSuggestion}
        renderInputComponent={renderInput}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={handleSuggestionsClearRequested}
        renderSuggestionsContainer={renderSuggestionsContainer}
        getSuggestionValue={function getSuggestionValue(suggestion: any) {
          return suggestion.label;
        }}
        inputProps={inputProps}
        theme={autoSuggestTheme}
      />
    </SSearchClassesDiv>
  );
};

export default SearchBar;
