import Button from "@mui/material/Button";
import { styled } from "@mui/system";

type Props = {
  hasText: boolean;
  isActive: boolean;
  theme?: any;
};

export const SButton = styled(Button)(({ hasText, isActive, theme }: Props) => ({
  ...theme.layers.primaryButton,
  ...(hasText ? theme.components.primaryButtonWithText : theme.components.primaryButton),
  ...theme.components.border,
  boxSizing: "content-box",
  ...(isActive && theme.components.activeButton),
}));
