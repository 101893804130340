import { createTheme, ThemeOptions } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { PaletteOptions } from "@mui/material/styles/createPalette";

declare module "@mui/material/styles" {
  interface TypeBackground {
    [key: string]: any;
  }
  interface TypeText {
    [key: string]: any;
  }
  interface CommonColors {
    [key: string]: any;
    transparent: string;
  }

  interface Components {
    [key: string]: any;
  }
}
declare module "@mui/material/styles/createPalette" {
  interface CommonColors {
    [key: string]: any;
  }
  interface PaletteOptions {
    [key: string]: any;
  }
}
interface Layers {
  [key: string]: any;
}
interface IThemeOptions extends ThemeOptions {
  layers: Layers;
}

export function remToPx(rem: string): number {
  return parseFloat(window.getComputedStyle(document.body).getPropertyValue("font-size")) * parseFloat(rem);
}

export function pxToRem(px: number): number {
  return px / parseFloat(parseInt(window.getComputedStyle(document.body).getPropertyValue("font-size")).toPrecision(4));
}

export default createTheme({
  palette: {
    common: {
      black: "#000",
      white: "#fff",
      transparent: "rgba(0, 0, 0, 0)",
      fullBlack: "rgba(0, 0, 0, 1)",
      darkBlack: "rgba(0, 0, 0, 0.87)",
      lightBlack: "rgba(0, 0, 0, 0.54)",
      minBlack: "rgba(0, 0, 0, 0.26)",
      faintBlack: "rgba(0, 0, 0, 0.12)",
      fullWhite: "rgba(255, 255, 255, 1)",
      darkWhite: "rgba(255, 255, 255, 0.87)",
      lightWhite: "rgba(255, 255, 255, 0.54)",
    },
    primary: {
      main: "#11b5e9",
    },
    secondary: {
      ...grey,
    },

    text: {
      primary: "#A6A8AB",
      secondary: "#6D6E70",
      disabled: "rgb(255, 51, 0)",
      hint: "#FFFFFF",
      icon: "#231F20",
      divider: "#6D6E70",
      lightDivider: "#59595C",
    },
    input: {
      bottomLine: "rgba(0, 0, 0, 0.42)",
      helperText: "rgba(0, 0, 0, 0.54)",
      labelText: "rgba(0, 0, 0, 0.54)",
      inputText: "rgba(0, 0, 0, 0.87)",
      disabled: "rgba(0, 0, 0, 0.42)",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.26)",
    },
    background: {
      default: "#59595C",
      dark: "#2B2B2B",
      paper: "#404041",
      appBar: "#f5f5f5",
      contentFrame: "#6D6E70",
      overlay: "rgba(64, 64, 65, 0.8)",
      disabled: "rgba(0,0,0,)",
    },
  },
  typography: {
    fontFamily: '"Source Sans Pro", sans-serif',
    htmlFontSize: 16,
    fontSize: 16,
    body1: {
      "& a": {
        color: "#11b5e9",
        textDecoration: "none",
      },
      "& a:visited": {
        color: "white",
      },
    },
    h2: {
      fontSize: "1.5rem",
      color: "#FFFFFF",
    },
  },
  layers: {
    mapOverlay: {
      zIndex: 50,
    },
    draggable: {
      zIndex: 70,
    },
    primaryButton: {
      zIndex: 80,
    },
    menu: {
      zIndex: 90,
    },
    overlayMenu: {
      zIndex: 100,
    },
    overlay: {
      zIndex: 1000,
    },
  },
  overrides: {
    MuiSelect: {
      icon: {
        display: "none",
      },
    },
    MuiTooltip: {
      tooltipPlacementTop: {
        margin: "1.5rem 0 0 0 !important",
      },
      tooltipPlacementBottom: {
        margin: "0 !important",
      },
    },
    MuiIconButton: {
      root: {},
    },
  },
  components: {
    icon: {
      fontSize: "1.5rem",
    },
    border: {
      border: "0.05rem solid #6D6E70",
      borderRadius: "0.25rem",
    },
    // === Input ===
    input: {
      margin: "0 0.5rem",
      width: "calc(100% - 1rem)",
      height: "2.5rem",
      color: "#FFFFFF",
    },

    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: "#2B2B2B",
        },
      },
    },
    tooltip: {
      tooltip: {
        backgroundColor: "#2B2B2B",
        pointerEvents: "none",
      },
      tooltipPlacementTop: {
        pointerEvents: "none",
      },
      tooltipPlacementBottom: {
        pointerEvents: "none",
      },
      tooltipPlacementLeft: {
        pointerEvents: "none",
      },
      tooltipPlacementRight: {
        pointerEvents: "none",
      },
    },

    button: {
      width: "2.5rem",
      height: "2.5rem",
      minWidth: "2.5rem",
      minHeight: "2.5rem",
      padding: 0,
    },
    primaryButton: {
      width: "2.5rem",
      height: "2.5rem",
      minWidth: "2.5rem",
      minHeight: "2.5rem",
      padding: 0,
      backgroundColor: "#404041",
      color: "#A6A8AB",
    },
    primaryButtonWithText: {
      height: "2.5rem",
      minWidth: "2.5rem",
      minHeight: "2.5rem",
      padding: "0 0.5rem",
      backgroundColor: "#404041",
      color: "#A6A8AB",
    },
    activeButton: {
      color: "#11b5e9 ",
      backgroundColor: "#2B2B2B",
    },
    componentLoading: {
      loadingSequence: {
        position: "absolute",
        width: "50%",
        height: "50%",
        textAlign: "center",
        top: "50%",
        left: "50%",
        transform: "translateY(-50%) translateX(-50%)",
      },
      loadingImage: {
        width: "10rem",
        height: "10rem",
      },
    },
    draggable: {
      dragging: {
        border: "0.1rem solid #11b5e9",
        transition: "border 0.2s ease",
        boxSizing: "content-box",
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          "-webkit-text-fill-color": "#FFF !important",
        },
      },
    },
    select: {
      checkbox: {
        width: "2.5rem",
        height: "2.5rem",
      },
      checkIcon: {
        fontSize: "1rem",
        display: "inline-block",
        verticalAlign: "middle",
        marginLeft: "0.5rem",
      },
      header: {
        width: "100%",
        height: "2.5rem",
        borderBottom: "0.05rem solid #6D6E70",
        boxSizing: "content-box",
      },
      formControlSmall: {
        width: "calc(100% - 3.5rem)",
        height: "2.5rem",
        display: "inline-block",
        verticalAlign: "middle",
      },
      formControlSmallCloseable: {
        width: "calc(100% - 4.5rem)",
        height: "2.5rem",
        display: "inline-block",
        verticalAlign: "middle",
      },
      formControlIndicator: {
        width: "calc(100% - 4.5rem)",
        height: "2.5rem",
        display: "inline-block",
        verticalAlign: "middle",
      },
      formControl: {
        width: "calc(100% - 2.5rem)",
        height: "2.5rem",
        display: "inline-block",
        verticalAlign: "middle",
      },
      formControlCloseable: {
        width: "calc(100% - 4.5rem)",
        height: "2.5rem",
        display: "inline-block",
        verticalAlign: "middle",
      },
      disabled: {
        cursor: "inherit",
      },
      select: {
        width: "100%",

        "& svg": {
          display: "none",
        },
      },
      selectField: {
        padding: 0,
        marginLeft: "0.5rem",
        fontSize: "1.1rem",
        color: "#FFFFFF",
      },
      input: {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: "100%",
      },
      selectButton: {
        verticalAlign: "middle",
        width: "2rem",
        height: "2rem",
        "& svg": {
          fontSize: "2rem",
        },
      },
      headerButton: {
        verticalAlign: "middle",
        width: "2rem",
        height: "2rem",
        "& svg": {
          fontSize: "1.5rem",
        },
        "&.inactive": {
          "& svg": {
            fill: "#A6A8AB",
          },
        },
      },
      selectMenu: {},
    },
  },
} as IThemeOptions);
