import { useTranslation } from "react-i18next";
import * as GlobeThunk from "../../../../redux/features/globeSlice/index";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { useRef } from "react";
import { updateFullscreenScatterplot } from "../../../../redux/features/uiSlice";
import { css } from "@emotion/css";
import { useAppDispatch, useAppSelector } from "../../../../redux/types";
import { VPlot } from "./VPlot";
import {
  SArrowDownward,
  SFlexDiv,
  SGradientClampLDiv,
  SGradientClampRDiv,
  SGradientContainerDiv,
  SGradientDiv,
  SHeaderTypography,
  SLowHighSpan,
  SScatterPlotFullscreenButton,
  SScatterPlotIcon,
  SVPlotBGDiv,
  SVPlotContainerDiv,
} from "./emotion";

interface VPlotContainerProps {
  indicatorValue: number;
  sexValue: number;
  isFullscreen?: boolean;
  currentZoom?: number;
}

const VPlotContainer = (props: VPlotContainerProps) => {
  const { isFullscreen = false, sexValue, indicatorValue } = props;
  const { screen, isMobile } = useAppSelector((state) => state.ui);
  const cancerValue = useAppSelector((state) => state.filter.filters.cancergrp.value);
  const { cancerData, loadingDensityData, sa2Data } = useAppSelector((state) => state.cancerAtlas);
  const isScatterOpen = useAppSelector((state) => state.ui.scatterplotFullscreenVisible);
  const { activeSA2s, flyToActive } = useAppSelector((state) => state.globe);
  const selectedSA2s = useAppSelector((state) => state.htmlVisualisation.sa2s);
  const dispatch = useAppDispatch();
  const { t }: { t: any } = useTranslation();
  const theme: any = useTheme();
  const containerRef = useRef<null | HTMLDivElement>(null);

  const TOOLTIP_STYLE = {
    tooltip: css(theme.components.tooltip.tooltip),
    tooltipPlacementTop: css(theme.components.tooltip.tooltipPlacementTop),
    tooltipPlacementBottom: css(theme.components.tooltip.tooltipPlacementBottom),
  };

  const flyToSA2 = (id: number) => {
    dispatch(GlobeThunk.flyToRequest({ id }));
  };

  const VPlotHeader = () => {
    return (
      <>
        {t("comparison.description", {
          item: t(props.indicatorValue === 0 ? "indicator.diagnoses.rate_description" : "indicator.excess_deaths.rate_description"),
          subject: t("common:demonym"),
        })}
      </>
    );
  };

  return (
    <SVPlotContainerDiv isFullscreen={isFullscreen} ref={containerRef}>
      {isFullscreen ? (
        <SHeaderTypography variant="h5" theme={theme}>
          <VPlotHeader />
        </SHeaderTypography>
      ) : (
        <VPlotHeader />
      )}
      <SFlexDiv>
        <Tooltip id={`indicator_vPlotLow_tooltip`} classes={TOOLTIP_STYLE} title={t("comparison.lowVPlot")} placement="top" arrow disableFocusListener>
          <SLowHighSpan>{t("low")}</SLowHighSpan>
        </Tooltip>
        {!isFullscreen && (
          <Tooltip id={`indicator_vPlotAverage_tooltip`} classes={TOOLTIP_STYLE} title={t("comparison.averageVPlot")} placement="top" arrow disableFocusListener>
            <SArrowDownward isFullscreen={isFullscreen} />
          </Tooltip>
        )}
        <Tooltip id={`indicator_vPlotHigh_tooltip`} classes={TOOLTIP_STYLE} title={t("comparison.highVPlot")} placement="top" arrow disableFocusListener>
          <SLowHighSpan>{t("high")}</SLowHighSpan>
        </Tooltip>
      </SFlexDiv>
      <SGradientContainerDiv>
        <SGradientClampLDiv />
        <SGradientDiv />
        <SGradientClampRDiv />
      </SGradientContainerDiv>
      <SVPlotBGDiv isFullscreen={isFullscreen}>
        <div className="halfMark"></div>
        <div className="yMarker50"></div>
        <Tooltip id={`indicator_vPlotYAxis_tooltip`} classes={TOOLTIP_STYLE} title={t("v_plot.y_axis.description", { subject: t("common:demonym") })} placement="top" arrow disableFocusListener>
          <div className="yAxis">{t("v_plot.y_axis.label")}</div>
        </Tooltip>
        <VPlot
          indicatorValue={indicatorValue}
          sexValue={sexValue}
          cancerValue={cancerValue}
          cancerData={cancerData}
          sa2Data={sa2Data}
          activeSA2s={activeSA2s}
          selectedSA2s={selectedSA2s}
          flyToSA2={flyToSA2}
          flyToActive={flyToActive}
          loadingDensityData={loadingDensityData}
          isFullscreen={isFullscreen}
          screen={screen}
          containerRef={containerRef}
          theme={theme}
        />
        {/* Only show fullscreen scatterplot icon on > sm/mobile */}
        {!isMobile && !isFullscreen && (
          <Tooltip id={`indicator_btnScatterplotFullscreen_tooltip`} classes={TOOLTIP_STYLE} title={t(`messages:v_plot.fullscreen`)} placement="left" arrow disableFocusListener>
            <SScatterPlotFullscreenButton
              onClick={() => {
                dispatch(updateFullscreenScatterplot({ isVisible: !isScatterOpen }));
              }}
            >
              <SScatterPlotIcon />
            </SScatterPlotFullscreenButton>
          </Tooltip>
        )}
      </SVPlotBGDiv>
    </SVPlotContainerDiv>
  );
};

VPlotContainer.displayName = "Visualisation:VPlot";
export default VPlotContainer;
