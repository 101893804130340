// @ts-nocheck
import update from "immutability-helper";
import deepmerge from "deepmerge";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { combineMerge } from "../utils";
import { CancerGroupPiece } from "../../../types";

export interface FilterState {
  noDataFlag: {
    visible: boolean;
    validSex: null | any;
  };
  filters: {
    cancergrp: {
      FieldName: string;
      Title: string;
      Type: string;
      value: number;
      loading: boolean;
      values: Array<CancerGroupPiece>;
    };
    sex: {
      FieldName: string;
      Title: string;
      Type: string;
      value: number;
      values: Array<{
        Id: number;
        Title: string;
        PropName: string;
        value: number;
      }>;
    };
    pc: {
      FieldName: string;
      Title: string;
      Type: string;
      value: number;
      values: Array<{
        Id: number;
        Title: string;
        PropName: string;
        value: number;
      }>;
    };
    diagnoses: {
      FieldName: string;
      Title: string;
      Type: string;
      inverted: boolean;
      value: Array<number>;
      range: Array<number>;
      step: number;
    };
    confidence: {
      FieldName: string;
      Title: string;
      Type: string;
      inverted: false;
      value: Array<number>;
      range: Array<number>;
      step: number;
    };
    indicator: {
      FieldName: string;
      Title: string;
      Type: string;
      value: number;
      values: Array<{
        Id: number;
        Title: string;
        PropName: string;
        value: number;
      }>;
    };
    transparency: {
      FieldName: string;
      Type: string;
      Title: string;
      value: boolean;
      values: Array<any>;
    };
  };
}

const initialState: FilterState = {
  noDataFlag: {
    visible: false,
    validSex: null,
  },
  filters: {
    cancergrp: {
      FieldName: "cancergrp",
      Title: "Cancer Type",
      Type: "radio",
      value: 1,
      loading: false,
      values: [],
    },
    sex: {
      FieldName: "sex",
      Title: "Gender",
      Type: "radio",
      value: 3,
      values: [
        {
          Id: 0,
          Title: "Males",
          PropName: "Males",
          value: 1,
        },
        {
          Id: 1,
          Title: "Females",
          PropName: "Females",
          value: 2,
        },
        {
          Id: 2,
          Title: "All Persons",
          PropName: "All Persons",
          value: 3,
        },
      ],
    },
    pc: {
      FieldName: "pc",
      Title: "Postcode",
      Type: "radio",
      value: 3,
      values: [
        {
          Id: 0,
          Title: "PC3",
          PropName: "PC3",
          value: 3,
        },
        {
          Id: 1,
          Title: "PC4",
          PropName: "PC4",
          value: 4,
        },
      ],
    },
    diagnoses: {
      FieldName: "diagnoses",
      Title: "Diagnoses",
      Type: "range",
      inverted: false,
      value: [-0.585, 0.585],
      range: [-0.585, 0.585],
      step: 0.005,
    },
    confidence: {
      FieldName: "confidence",
      Title: "Probability",
      Type: "range",
      inverted: false,
      value: [0, 100],
      range: [0, 100],
      step: 1,
    },
    indicator: {
      FieldName: "indicator",
      Title: "Indicator",
      Type: "radio",
      value: 0,
      values: [
        {
          Id: 0,
          Title: "Diagnoses",
          PropName: "Diagnoses",
          value: 0,
        },
      ],
    },
    transparency: {
      FieldName: "transparency",
      Type: "radio",
      Title: "Transparency",
      value: true,
      values: [],
    },
  },
};

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    initFilterValues: (state, action: PayloadAction<{ fieldName: keyof FilterState["filters"]; values: any }>) => {
      const { fieldName, values } = action.payload;
      state.filters = {
        ...state.filters,

        [fieldName]: {
          ...state.filters[fieldName],
          values: values,
        },
      };
    },
    updateFilter: (state, action: PayloadAction<any>) => {
      const { fieldName, type, _name, _value } = action.payload;

      switch (type) {
        case "range": {
          let newFilter = state.filters[fieldName];
          if (_value && _value.inverted !== null) {
            newFilter.value = _value.value;
            if ("inverted" in newFilter) newFilter.inverted = _value.inverted;
          }
          state.filters = { ...state.filters, [fieldName]: newFilter };

          break;
        }
        case "radio": {
          let modStateFilters = update(state.filters, { [action.payload.fieldName]: { value: { $set: _value } } });
          state.filters = modStateFilters;
          break;
        }
        default: {
          let newFilter = state.filters[fieldName];
          if ("values" in newFilter) {
            newFilter.values = newFilter.values.map((value: any) => {
              if (value.Title == _name && (newFilter.value > 1 || (_value && !value.value))) {
                value.value = _value;
                newFilter.value = _value ? newFilter.value + 1 : newFilter.value - 1;
              }
              return value;
            });
            state.filters = { ...state.filters, [fieldName]: newFilter };
          }
        }
      }
    },
    setNoDataFlag: (state, action: PayloadAction<{ visible: FilterState.noDataFlag["visible"]; validSex: FilterState.noDataFlag["validSex"] }>) => {
      state.noDataFlag = { ...state.noDataFlag, visible: action.payload.visible, validSex: action.payload.validSex };
    },
    selectAll: (state, action: PayloadAction<any>) => {
      var updatedFilters = JSON.parse(JSON.stringify(state.filters));
      // Enable all checkboxes for given category
      Object.keys(updatedFilters).map((filter) => {
        if (updatedFilters[filter].FieldName == action.payload.fieldName) {
          updatedFilters[filter].values = updatedFilters[filter].values.map((value) => {
            value.value = true;
            return value;
          });
          updatedFilters[filter].value = updatedFilters[filter].values.length;
        }
      });
      state.filters = updatedFilters;
    },
    applyStateFilters: (state, action: PayloadAction<any>) => {
      let newFilters = { ...state.filters };
      for (let key in action.payload) {
        newFilters[key] = update(newFilters[key], { $set: deepmerge(newFilters[key], action.payload[key], { arrayMerge: combineMerge }) });
      }
      state.filters = newFilters;
    },
    fetchCancerGrp: (state) => {
      state.filters = { ...state.filters, cancergrp: { ...state.filters.cancergrp, loading: true } };
    },
    gotCancerGrp: (state, action: PayloadAction<any>) => {
      state.filters = { ...state.filters, cancergrp: { ...state.filters.cancergrp, values: action.payload.sort((a: any, b: any) => a.name.localeCompare(b.name)), loading: false } };
    },
  },
});

export const { initFilterValues, updateFilter, setNoDataFlag, selectAll, applyStateFilters, fetchCancerGrp, gotCancerGrp } = filterSlice.actions;
export default filterSlice.reducer;
