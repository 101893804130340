import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import { css } from "@emotion/css";

const SLink = styled(Link)(() => ({
  textDecoration: "none",
}));

const SDialog = styled(Dialog)((props: any) => ({
  paper: {
    backgroundColor: props.theme.palette.background.dark,
  },
}));

type StoryItem = {
  title: string;
  value: number;
  link?: string;
};

interface DialogMenuProps {
  title: string;
  open: boolean;
  items?: Array<StoryItem>;
  handleSelect?: Function;
  handleClose: any;
}
const DialogMenu = ({ handleSelect, handleClose, title, open, items = [] }: DialogMenuProps) => {
  const BUTTON_STYLE = {
    root: css({
      justifyContent: "left",
    }),
    label: css({ textAlign: "left" }),
  };

  return (
    <SDialog open={open} onClose={handleClose} aria-labelledby={title}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {items.map((item: StoryItem, index: number) => {
          if (item.link)
            return (
              <SLink key={`item_${index}`} to={item.link}>
                <Button classes={BUTTON_STYLE} value={item.value} name={item.title} aria-label={item.title} variant="text" fullWidth onClick={handleClose}>
                  {item.title}
                </Button>
              </SLink>
            );
          else
            return (
              <Button key={`item_${index}`} classes={BUTTON_STYLE} value={item.value} name={item.title} aria-label={item.title} variant="text" fullWidth onClick={handleClose}>
                {item.title}
              </Button>
            );
        })}
      </DialogContent>
    </SDialog>
  );
};

export default DialogMenu;
