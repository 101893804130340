import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import * as FilterActionsThunk from "../../redux/features/filterSlice/thunk";
import * as UIActions from "../../redux/features/uiSlice";
import * as GlobeActions from "../../redux/features/globeSlice";
import { useTheme } from "@mui/material/styles";
import { css } from "@emotion/css";
import { CancerGroupPiece } from "../../types";
import { useAppDispatch, useAppSelector } from "../../redux/types";
const COMPONENT_NAME = "pc-select";

const SPCContainer = styled("div")((props: any) => ({
  background: "red",
  color: "#FFF",
  width: "18rem",
  padding: "1rem",
  border: "0.05rem solid " + props.theme.palette.text.primary,
  borderRadius: props.theme.components.border.borderRadius,
  backgroundColor: props.theme.palette.background.paper,
}));

const SPCContent = styled("div")({
  paddingBottom: "1rem",
});

const SButtonsDiv = styled("div")((props: any) => ({
  display: "flex",
  flexWrap: "nowrap",
  alignItems: "flex-start",
  width: "100%",
  justifyContent: "space-around",
}));

const SFilterButton = styled(Button)((props: any) => ({
  fontSize: "0.75rem",
  minWidth: "3rem",
  minHeight: "1rem",
  padding: "0.25rem",
  border: "0.05rem solid #6D6E70",
  borderRadius: "0.25rem",
  display: "inline-block",
  "& span": {
    pointerEvents: "none",
  },
}));

interface PCSelectProps {
  className?: string;
}

const PCSelect = (props: PCSelectProps) => {
  const { i18n, t } = useTranslation();
  const theme: any = useTheme();
  const dispatch = useAppDispatch();
  const { pc } = useAppSelector((state) => state.ui.indicators[0]);
  const curCancer = useAppSelector((state) => state.filter.filters.cancergrp.value);
  const cancervals = useAppSelector((state) => state.filter.filters.cancergrp.values);
  const [visible, setVisible] = useState<boolean>(true);

  const TOOLTIP_STYLE = {
    tooltip: css(theme.components.tooltip.tooltip),
    tooltipPlacementTop: css(theme.components.tooltip.tooltipPlacementTop),
    tooltipPlacementBottom: css(theme.components.tooltip.tooltipPlacementBottom),
  };

  useEffect(() => {
    if (cancervals && cancervals.length > 0 && curCancer) {
      const cancer = cancervals.find((x) => x.grp === curCancer);
      if (cancer !== undefined) setVisible(cancer.pc3 && cancer.pc4 /*&& cancer.grp === 11*/);
    }
  }, [cancervals, curCancer]);

  const handleClick = (event: any) => {
    let newValue = parseInt(event.target.value);

    if (typeof newValue === "number" && (newValue !== null || newValue !== undefined)) {
      // Get current value according to indicator window active state
      if (newValue !== pc) {
        dispatch(FilterActionsThunk.updateFilter({ fieldName: "pc", type: "radio", _name: "", _value: newValue }));
        dispatch(UIActions.updateIndicator({ index: 0, data: { pc: newValue } }));
        dispatch(GlobeActions.updateLoadingStatus({ loadingStatus: 50 }));
      }
    }
  };
  return visible ? (
    <SPCContainer className={props.className} theme={theme}>
      <SPCContent>{t("pc_select.content")}</SPCContent>
      <SButtonsDiv>
        <Tooltip id={`indicator_selectMales_tooltip`} classes={TOOLTIP_STYLE} title={t("pc_select.label.3")} placement="top" arrow disableFocusListener>
          <SFilterButton component="button" aria-label={t("pc_select.label.3")} variant="contained" value={3} name={"PC3"} onClick={handleClick} color={pc === 3 ? "primary" : "secondary"}>
            {t("pc_select.btn", { postcode: 3 })}
          </SFilterButton>
        </Tooltip>
        <Tooltip id={`indicator_selectFemales_tooltip`} classes={TOOLTIP_STYLE} title={t("pc_select.label.4")} placement="top" arrow disableFocusListener>
          <SFilterButton
            component="button"
            aria-label={t("pc_select.label.4")}
            variant="contained"
            value={4}
            name="PC4"
            // Send view mode index to view mode change function
            onClick={handleClick}
            // Use color and mode state value to determine active state
            color={pc === 4 ? "primary" : "secondary"}
          >
            {t("pc_select.btn", { postcode: 4 })}
          </SFilterButton>
        </Tooltip>
      </SButtonsDiv>
    </SPCContainer>
  ) : null;
};

export default PCSelect;
