import { useState, useRef, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import mapboxgl, { Map, LngLat } from "mapbox-gl";
import { MAPBOX_TOKEN, NCAColorGradient } from "../../constants";
import * as HTMLVisualisationActions from "../../redux/features/htmlVisualisationSlice";
import * as URIActionsThunk from "../../redux/features/uriSlice/thunk";
import * as CancerAtlasActionsThunk from "../../redux/features/cancerAtlasSlice/thunk";
import * as GlobeActions from "../../redux/features/globeSlice";
import * as FilterActions from "../../redux/features/filterSlice";
import { ChromeCheck, map_range, map_range2 } from "../../utils";
import { useAppDispatch, useAppSelector } from "../../redux/types";
import { useTranslation } from "react-i18next";

const Mapbox = () => {
  const dispatch = useAppDispatch();
  const storeMap = useAppSelector((store) => store.globe.map);
  const { zoomLevel, flyToDataId, triggerPopupRedraw } = useAppSelector((store) => store.globe);
  const { filters } = useAppSelector((store) => store.filter);
  const { screen } = useAppSelector((store) => store.ui);
  const { cancerData, loading, loadingDensityData, sa2Data, loadingSA2s } = useAppSelector((store) => store.cancerAtlas);
  const { visPanels, sa2s } = useAppSelector((store) => store.htmlVisualisation);
  const visPanelsRef = useRef(visPanels);
  const { urlVisible, uriObj, stateLoading } = useAppSelector((store) => store.uri);
  const [mapReady, setMapReady] = useState<boolean>(false);
  const [map, setMap] = useState<mapboxgl.Map>();
  const [lng] = useState<number>(5.767981807435612);
  const [lat] = useState<number>(52.24469566080194);
  const [zoom] = useState<number>(10);
  const [data, setData] = useState<any[]>([]);
  const location = useLocation();
  const [processedData, _setProcessedData] = useState<Array<any>>([]);
  const processedDataRef = useRef(processedData);
  const [moveCnt, setMoveCnt] = useState<number>(0);
  // mapbox layers for colouring etc
  const [sa2_layer, setSa2_layer] = useState<any>();
  const [sa2_pattern_layer, setSa2_pattern_layer] = useState<mapboxgl.AnyLayer>();
  const [sa2_highlight_layer, setSa2_highlight_layer] = useState<mapboxgl.AnyLayer>();
  const { visible: isStoriesVisible } = useAppSelector((state) => state.ui["stories"]);

  const mapRef2 = useRef<mapboxgl.Map | null>(null);

  const { i18n } = useTranslation();

  const pcLayers = {
    pc4: {
      pc_layer: "nl-pc4",
      pc_pattern_layer: "nl-pc4-pattern",
      pc_highlight_layer: "nl-pc4-highlight",
      pc_borders_layer: "nl-pc4-borders",
      mapKey: "pc4",
    },
    pc3: {
      pc_layer: "nl-pc3",
      pc_pattern_layer: "nl-pc3-pattern",
      pc_highlight_layer: "nl-pc3-highlight",
      pc_borders_layer: "nl-pc3-borders",
      mapKey: "pc3",
    },
  };

  const mapLabels = [
    "waterway-label",
    "natural-line-label",
    "natural-point-label",
    "water-line-label",
    "poi-label",
    "airport-label",
    "settlement-subdivision-label",
    "settlement-minor-label",
    "settlement-major-label",
    "state-label",
    "country-label",
  ];

  const setProcessedData = (data: Array<any>) => {
    processedDataRef.current = data;
    _setProcessedData(data);
  };

  mapboxgl.accessToken = MAPBOX_TOKEN || "";

  //Realign the popups with map when stories is closed
  useEffect(() => {
    if (!isStoriesVisible) redrawPopups();
  }, [isStoriesVisible]);

  //Trigger to realign popups when the reset button is clicked
  useEffect(() => {
    if (triggerPopupRedraw > 0) redrawPopups();
  }, [triggerPopupRedraw]);

  useEffect(() => {
    if (!map) return;
    mapRef2.current = map;
  }, [map]);

  //Highlight map state/area in green
  useEffect(() => {
    visPanelsRef.current = visPanels;
    var sa2sLocal = visPanels.map((vp: any) => vp.id.toString());
    sa2_highlight_layer && map?.setFilter(sa2_highlight_layer.id, buildFilter(["in", pcLayers[`pc${filters.pc.value}` as keyof typeof pcLayers].mapKey], sa2sLocal));
  }, [visPanels]);

  useEffect(() => {
    if (sa2s && sa2s.length && !visPanels.map((x: any) => x.id).some((x: any) => sa2s.includes(x))) {
      dispatch(HTMLVisualisationActions.removeAllHTMLVis({ parentId: "iknl_popups" }));
      for (var i = 0; i < sa2s.length; i++) {
        addPopup(sa2s[i].toString());
      }
    } else if (visPanels.length) {
      visPanels.forEach((visPanel: any) => {
        !sa2s.includes(visPanel.id) && dispatch(HTMLVisualisationActions.removeHTMLVis({ id: visPanel.id }));
      });
    }
  }, [sa2s, visPanels, visPanelsRef.current]);

  useEffect(() => {
    if (flyToDataId && flyToDataId !== null) {
      var sa2info = sa2Data?.find((d: any) => d.areacode === flyToDataId);
      if (sa2info) {
        var geo = sa2info.geo;
        try {
          map?.once("moveend", () => {
            if (sa2s.length === 0 || sa2s.findIndex((idx: any) => idx == flyToDataId)) {
              addPopup(flyToDataId.toString());
            }

            dispatch(GlobeActions.flyToSuccess());
          });
          map?.flyTo({
            center: [geo.pt[0], geo.pt[1]],
            zoom: 11,
            bearing: storeMap.bearing,
            pitch: storeMap.pitch,
          });
        } catch (e) {}
      }
    }
  }, [flyToDataId]);

  const getStoriesWidth = () => {
    let storiesWidth = 0;
    const wantedW = document.getElementById("stories-container")?.getBoundingClientRect().width;
    if (wantedW !== undefined) storiesWidth += wantedW;
    return storiesWidth;
  };

  const addPopup = (sa2: string, anchor?: any) => {
    const sa2info = sa2Data?.find((d) => d.areacode == +sa2);
    if (sa2info && map) {
      if (anchor === undefined) {
        var geo = sa2info.geo; //JSON.parse(sa2info.geo.replace(/(['"])?([a-zA-Z0-9_]+)(['"])?:/g, '"$2": '));
        anchor = new mapboxgl.LngLat(geo.pt[0], geo.pt[1]);
      }

      const areacode = sa2info.areacode.toString();
      const storiesWidth = getStoriesWidth();
      const latToPoint = map?.project(anchor) as mapboxgl.Point;
      const pointToLat = map?.unproject([latToPoint.x + storiesWidth, latToPoint.y]);
      const latToPointWithOffset = map?.project(pointToLat) as mapboxgl.Point;

      var Config = {
        type: "nca_cancer_popup",
        title: `${areacode} - ${sa2info.name ?? ""} ${sa2info.province ?? ""}`, //"SA2 Cancer Popup",
        chart: null,
      };

      dispatch(
        HTMLVisualisationActions.addHTMLVis({
          parentId: "iknl_popups",
          id: sa2 + "",
          panelConfig: Config,
          anchor: {
            lng: anchor.lng,
            lat: anchor.lat,
          },
          coords: { x: latToPointWithOffset.x, y: latToPointWithOffset.y },
        })
      );
    }
  };

  const clickPopup = (e: any) => {
    if (loadingDensityData) return;
    var features: any = map?.queryRenderedFeatures(e.point, { layers: [sa2_layer.id] });
    const key = pcLayers[`pc${filters.pc.value}` as keyof typeof pcLayers].mapKey;
    if (features.length > 0) {
      var sa2 = features[0].properties[key];
      addPopup(sa2);
    }
  };

  const chromeMoveStart = (e: any) => {
    setMoveCnt(0);
  };
  const chromeMoveEnd = (e: any) => {
    setMoveCnt(moveCnt + 1);
  };
  const removePopupListeners = () => {
    map?.off("click", clickPopup);
    if (ChromeCheck()) {
      map?.off("movestart", chromeMoveStart);
      map?.off("move", chromeMoveEnd);
      map?.off("moveend", fakeClick);
    }
  };

  const addPopupListeners = () => {
    if (ChromeCheck()) {
      // fake the click when mapbox thinks its a move (chrome bug) - could maybe add elapsed time too, if small than a click
      map?.on("movestart", chromeMoveStart);
      map?.on("move", chromeMoveEnd);
      map?.on("moveend", fakeClick);
    }
    map?.on("click", clickPopup);
  };

  const fakeClick = (e: any) => {
    if (moveCnt < 2 && e.originalEvent && e.originalEvent.movementX === 0 && e.originalEvent.movementY === 0) {
      e.originalEvent.preventDefault();
      e.originalEvent.stopPropagation();
      var point = new mapboxgl.Point(e.originalEvent.offsetX, e.originalEvent.offsetY);
      var lngLat = map?.unproject(point);

      map?.fire("click", {
        lngLat: lngLat,
        point: point,
      });
    }
  };

  //Account for an offset if stories is open
  const updateGeolocation = (panel: any) => {
    if (!map) return;
    const storiesWidth = getStoriesWidth();
    const latToPoint = map.project(panel.anchor) as mapboxgl.Point;
    const pointToLat = map.unproject([latToPoint.x + storiesWidth, latToPoint.y]);
    const latToPointWithOffset = map.project(pointToLat) as mapboxgl.Point;
    return latToPointWithOffset;
  };

  const updatePopup = (e: any) => {
    /*
     * Wrapped in originalEvent because:
     * https://github.com/mapbox/mapbox-gl-js/issues/6512#issuecomment-572670290
     */
    if (e.originalEvent && e.type === "moveend") {
      // update the map state
      if (map) {
        var centerObj: LngLat = map?.getCenter();
        var center: Array<number> = [centerObj.lng, centerObj.lat];
        var zoom: any = map?.getZoom();
        if (zoom && zoom > 24) zoom = 24;
        var bearing: any = map?.getBearing();
        var pitch: any = map?.getPitch();
        dispatch(GlobeActions.updateMap({ center, zoom, bearing, pitch }));
      }
      redrawPopups();
    }
  };

  const redrawPopups = () => {
    const visPanels = visPanelsRef.current;

    if (visPanels && visPanels.length > 0) {
      const obj = [];
      for (var i = 0; i < visPanels.length; i++) {
        const panel = visPanels[i];
        const coords = updateGeolocation(panel);
        if (!coords) return;
        const roundedCoords = { x: Math.round(coords.x), y: Math.round(coords.y) };
        if (Math.abs(roundedCoords.x - panel.coords.x) > 20 || Math.abs(roundedCoords.y - panel.coords.y) > 20) {
          obj.push({ id: panel.id, coords: roundedCoords });
        }
      }
      if (obj.length > 0) {
        dispatch(HTMLVisualisationActions.updateHTMLVis({ visUpdate: obj }));
      }
    }
  };

  // fetch the cancer data from api
  const getData = async () => {
    await dispatch(CancerAtlasActionsThunk.requestSA2Data({ pc: filters["pc"].value }));
    dispatch(CancerAtlasActionsThunk.requestPCCancerData({ ind: filters["indicator"].value, grp: filters["cancergrp"].value, sex: filters["sex"].value, pc: filters["pc"].value }));
  };

  // load data when map ready and cancer grps fetched
  useEffect(() => {
    // the groups are populated so fetch data OR the filters updated
    if (filters.cancergrp.values.length > 0 && mapReady) getData();
  }, [filters.cancergrp.values, mapReady, filters.pc.value, filters.cancergrp.value, filters.sex.value]);

  const applyFilters = () => {
    if (cancerData && sa2Data) {
      let d: Array<any> = data;

      var validSex = filters["cancergrp"].values.find((c: any) => c.grp == filters["cancergrp"].value)?.validsex;

      for (var key in filters) {
        const k = key as keyof FilterActions.FilterState["filters"];
        let type = filters[k];
        if (type.FieldName === "diagnoses") {
          const range = [-0.585, 0.585];

          if ("range" in type && Array.isArray(type.value)) {
            var low = map_range(type.value[0], type.range[0], type.range[1], range[0], range[1]);
            var high = map_range(type.value[1], type.range[0], type.range[1], range[0], range[1]);
            d = d.filter((f: any) => {
              var sir_p50 = f["logp50"];
              if ((high >= range[1] && sir_p50 >= range[1]) || (low <= range[0] && sir_p50 <= range[0])) return true;
              else return sir_p50 >= low && sir_p50 <= high;
            });
          }
        } else if (type.FieldName === "confidence") {
          if ("value" in type && Array.isArray(type.value)) {
            var low = map_range(type.value[0], 0, 100, 0, 1);
            var high = map_range(type.value[1], 0, 100, 0, 1);
            d = d.filter((f: any) => {
              var v = f["v"];
              if ((high >= 1 && v >= 1) || (low <= 0 && v <= 0)) return true;
              else return v >= low && v <= high;
            });
          }
        } else if (type.FieldName == "cancergrp") {
        } else if (type.FieldName == "sex" || type.FieldName == "indicator") {
        }
      }

      var validSA2s = sa2Data?.map((sa2info: any) => sa2info.areacode);

      d = d.filter((f) => {
        return validSA2s?.indexOf(f.postcode) !== -1;
      });

      if (d.length > 0) {
        dispatch(FilterActions.setNoDataFlag({ visible: false, validSex: validSex }));
      } else if (d.length == 0) {
        dispatch(FilterActions.setNoDataFlag({ visible: true, validSex: validSex }));
      }
      setProcessedData(d);
    }
  };

  useEffect(() => {
    if (processedData.length) {
      colorMapSA2s();
    }
  }, [processedData]);

  const createExpression = () => {
    var expression = [];
    const key = pcLayers[`pc${filters.pc.value}` as keyof typeof pcLayers].mapKey;
    if (processedData.length == 0) {
      expression = ["match", ["get", key]];
      expression.push("1", "rgba(127,127,127,0.0)");
      expression.push("rgba(127,127,127,0.0)");
      return expression;
    }
    expression = ["match", ["to-string", ["get", key]]];
    var row;
    var color;
    var i = 0;
    var midIdx = 0;
    var delta = 0;
    var colorMovement = 0;
    if (filters["transparency"].value) {
      for (i = 0; i < processedData.length; i++) {
        row = processedData[i];

        midIdx = Math.floor(NCAColorGradient.length / 2);
        delta = row["colorIdx"] - midIdx;
        colorMovement = Math.floor(map_range2(+row["v"], 0, 1.0, delta, 0));
        color = NCAColorGradient[row["colorIdx"] - colorMovement];

        expression.push(row["postcode"] + "", color);
      }
    } else {
      for (i = 0; i < processedData.length; i++) {
        row = processedData[i];

        color = NCAColorGradient[row["colorIdx"]];
        expression.push(row["postcode"] + "", color);
      }
    }
    expression.push("rgba(127,127,127,1.0)");
    return expression;
  };

  const colorMapSA2s = (): void => {
    var expression = createExpression();
    sa2_layer && map?.setPaintProperty(sa2_layer.id, "fill-color", expression);
  };

  const applyDataToMap = (): void => {
    applyFilters();
    const key = pcLayers[`pc${filters.pc.value}` as keyof typeof pcLayers].mapKey;
    if (!data || !sa2_layer || !sa2_pattern_layer || !sa2_highlight_layer || !sa2Data) return;
    var SA2s = data.map((f: any) => f.postcode);
    let legitSA2s = sa2Data.filter((f: any) => {
      return SA2s.indexOf(+f.areacode) !== -1;
    });
    legitSA2s = legitSA2s.map((m: any) => m.areacode.toString());
    map?.setFilter(sa2_layer.id, buildFilter(["in", key], legitSA2s));
    map?.setFilter(sa2_pattern_layer.id, buildFilter(["!in", key], legitSA2s));
    map?.setFilter(sa2_highlight_layer.id, buildFilter(["in", key], []));

    sa2_pattern_layer && map?.setPaintProperty(sa2_pattern_layer.id, "fill-opacity", 1);

    map?.on("wheel", () => {
      if (map.isMoving()) {
        map.stop();
      }
    });
  };

  const buildFilter = (filter: Array<string>, arr: Array<any>) => {
    if (arr.length === 0) {
      return filter;
    }

    for (var i = 0; i < arr.length; i += 1) {
      filter.push(arr[i]);
    }
    return filter;
  };

  const firstActiveSA2s = (e: any) => {
    if (e.isSourceLoaded) {
      map?.off("sourcedata", firstActiveSA2s);
    }
  };

  useEffect(() => {
    if (!stateLoading && urlVisible) {
      var center: any = map?.getCenter();
      var zoom = map?.getZoom();
      dispatch(URIActionsThunk.generateUri({ location: { center: [center.lng, center.lat], zoom: zoom }, screen }));
    }
  }, [urlVisible, stateLoading]);

  const mapRef = useCallback(
    (node: HTMLDivElement) => {
      if (node) {
        let zoomLocal = zoom;
        let centerLocal: any = [lng, lat];
        if (uriObj && uriObj.location) centerLocal = uriObj.location.center;
        if (uriObj && uriObj.location && uriObj.location.zoom) zoomLocal = uriObj.location.zoom;
        const gl = new mapboxgl.Map({
          container: node || "",
          style: process.env.REACT_APP_MAPBOX_STYLE,
          center: centerLocal,
          zoom: zoomLocal,
          attributionControl: false,
        });
        if (!map) setMap(gl);
      }
    },
    [lat, lng, zoom]
  );

  useEffect(() => {
    map?.resize();
  }, [screen]);

  // mapbox-gl initiate and load
  useEffect(() => {
    if (map) {
      map.dragRotate.disable();
      map.touchZoomRotate.disableRotation();
      //mapbox finished loading
      map.on("load", () => {
        map.on("move", updatePopup);
        map.on("moveend", updatePopup);
        map.addControl(new mapboxgl.AttributionControl({ compact: true }), "bottom-right");
        map.addControl(new mapboxgl.ScaleControl({ unit: "metric" }), "bottom-left");
        map.resize();
        applyLayers();
        setMapReady(true);
      });
    }
  }, [map]);

  useEffect(() => {
    dispatch(URIActionsThunk.stateFromURI({ hashURI: location.hash }));
  }, []);

  // layer management
  useEffect(() => {
    setProcessedData([]);
    applyLayers();
  }, [filters["pc"].value]);

  const applyLayers = () => {
    if (map) {
      const layers = map.getStyle().layers;
      for (const key in pcLayers) {
        const pc = key as keyof typeof pcLayers;
        if (key.endsWith(filters.pc.value.toString())) {
          const pc_layer = layers?.find((lyr) => lyr.id === pcLayers[pc].pc_layer);
          map.setLayoutProperty(pcLayers[pc].pc_layer, "visibility", "visible");
          map.setLayoutProperty(pcLayers[pc].pc_pattern_layer, "visibility", "visible");
          map.setLayoutProperty(pcLayers[pc].pc_highlight_layer, "visibility", "visible");
          map.setLayoutProperty(pcLayers[pc].pc_borders_layer, "visibility", "visible");
          setSa2_layer(layers?.find((lyr) => lyr.id === pcLayers[pc].pc_layer));
          setSa2_pattern_layer(layers?.find((lyr) => lyr.id === pcLayers[pc].pc_pattern_layer));
          setSa2_highlight_layer(layers?.find((lyr) => lyr.id === pcLayers[pc].pc_highlight_layer));
        } else {
          map.setLayoutProperty(pcLayers[pc].pc_layer, "visibility", "none");
          map.setLayoutProperty(pcLayers[pc].pc_pattern_layer, "visibility", "none");
          map.setLayoutProperty(pcLayers[pc].pc_highlight_layer, "visibility", "none");
          map.setLayoutProperty(pcLayers[pc].pc_borders_layer, "visibility", "none");
        }
      }
    }
  };

  useEffect(() => {
    if (map && mapReady) {
      const layers = map.getStyle().layers;
      const langArr = ["get", `${i18n.language === "nl" ? "name" : "name_en"}`];
      mapLabels.forEach((id) => {
        map.setLayoutProperty(id, "text-field", langArr);
      });
    }
  }, [i18n.language, map, mapReady]);

  useEffect(() => {
    if (cancerData && sa2Data && filters) {
      setData(cancerData[filters["indicator"].value][filters["sex"].value]);
      dispatch(GlobeActions.updateLoadingStatus({ loadingStatus: 100 }));
      removePopupListeners();
      addPopupListeners();
      if (!uriObj.data && !urlVisible && location.hash) {
      } else if (stateLoading && uriObj.data && !urlVisible && location.hash) {
        dispatch(URIActionsThunk.uriToState());
      } else {
      }
      //Adding this fixed the No Data overlay
      if (data) {
      }
    } else {
      dispatch(GlobeActions.updateLoadingStatus({ loadingStatus: +50 }));
    }
    // cleanup
    return () => {
      removePopupListeners();
    };
  }, [data, cancerData, filters, sa2Data, uriObj, urlVisible, location.hash, stateLoading]);

  useEffect(() => {
    if (data && filters["pc"].value && !loading && !loadingSA2s) applyDataToMap();
  }, [data, filters["pc"].value, loadingSA2s, loading]);

  // zoom
  useEffect(() => {
    if (!map || !zoomLevel || zoomLevel === 0) return;
    if (map?.isMoving()) {
      map?.stop();
    }
    var centerObj: LngLat = map.getCenter();
    var center: Array<number> = [centerObj.lng, centerObj.lat];
    var zoom: any = map.getZoom() + zoomLevel;
    if (zoom && zoom > 24) zoom = 24;
    var bearing: any = map.getBearing();
    var pitch: any = map.getPitch();
    dispatch(GlobeActions.updateMap({ center, zoom, bearing, pitch }));
  }, [dispatch, map, zoomLevel]);

  useEffect(() => {
    if (map) {
      var centerObj: LngLat = map?.getCenter();
      var center: Array<number> = [centerObj.lng, centerObj.lat];
      if (map?.getZoom() === storeMap.zoom && storeMap.center && storeMap.center[0] === center[0] && storeMap.center[1] === center[1]) return;
      if (storeMap.center?.length === 4) {
        map?.fitBounds([
          [storeMap.center[0], storeMap.center[1]],
          [storeMap.center[2], storeMap.center[3]],
        ]);
      } else {
        map?.flyTo({
          center: storeMap.center as mapboxgl.LngLatLike, //[center[0], center[1]],
          zoom: storeMap.zoom,
          bearing: storeMap.bearing,
          pitch: storeMap.pitch,
        });
      }
    }
  }, [map, storeMap.center]);

  //Fix zoom with windowbuttons
  useEffect(() => {
    if (storeMap.zoom === undefined || !map) return;

    map.flyTo({
      center: storeMap.center as mapboxgl.LngLatLike, //[center[0], center[1]],
      zoom: storeMap.zoom,
      bearing: storeMap.bearing,
      pitch: storeMap.pitch,
    });
  }, [storeMap.zoom]);

  return <div ref={mapRef} className="mapbox-container" style={{ width: "100%" }} />;
};

export default Mapbox;
