import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/types";
import SelectHeader from "../../components/SelectHeader";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import * as UIActions from "../../redux/features/uiSlice";
import * as CancerAtlasActionsThunk from "../../redux/features/cancerAtlasSlice/thunk";
import { useTranslation } from "react-i18next";
const COMPONENT_NAME = "stories";

const SHeaderDiv = styled("div")(({ theme }: { theme: any }) => ({
  ...theme.components.border,
  margin: "1rem auto",
  width: "calc(100% - 4rem)",
}));

interface StoriesSelectProps {
  handleClose?: Function;
}

const StoriesSelect = ({ handleClose }: StoriesSelectProps) => {
  const { screen, isMobile } = useAppSelector((state) => state.ui);
  const storyIndex = useAppSelector((state) => state.ui[COMPONENT_NAME].storyIndex);
  const storyData = useAppSelector((state) => state.cancerAtlas.stories);
  const { loadingStories, cancerData } = useAppSelector((state) => state.cancerAtlas);
  const { i18n, t } = useTranslation();
  const [storyValues, setStoryValues] = useState<Array<any>>([]);
  const dispatch = useAppDispatch();
  const theme = useTheme();

  useEffect(() => {
    storyData &&
      setStoryValues(
        storyData.map((story: any, index: number) => {
          return { title: story.attributes.title, value: index, link: `${process.env.REACT_APP_ASSET_PATH}/tour/${story.slug}` };
        })
      );
  }, [storyData]);

  useEffect(() => {
    cancerData !== null && !loadingStories && storyData.length === 0 && dispatch(CancerAtlasActionsThunk.requestStoryData({ locale: i18n.language }));
  }, [cancerData]);

  const handleStoryIndex = (index: number) => {
    // Send new story index and reset panelIndex to reducer
    dispatch(UIActions.updateComponent({ name: COMPONENT_NAME, data: { storyIndex: index, panelIndex: 0 } }));
  };

  return !isMobile ? (
    <SHeaderDiv theme={theme}>
      <SelectHeader title={t("tour.tourMenu_short")} label={t("tour.select")} tooltip={t("tour.select")} value={storyIndex} values={storyValues} closeButton onUpdate={handleStoryIndex} onClose={handleClose} />
    </SHeaderDiv>
  ) : null;
};

export default StoriesSelect;
