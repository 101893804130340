import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ScrollUpIcon from "@mui/icons-material/ArrowDropUp";
import ScrollDownIcon from "@mui/icons-material/ArrowDropDown";
import CollapseIcon from "@mui/icons-material/ExpandMore";
import { css } from "@emotion/css";
import { styled } from "@mui/system";

export const SRootAbstractPaper = styled(Paper)(({ theme }: { theme: any }) => ({
  top: 0,
  display: "inline-block",
  width: "100%",
  height: "100%",
  backgroundColor: theme.palette.background.dark,
  bottom: 0,
  position: "relative",
}));

export const SRootPaper = styled(Paper)(({ theme }: { theme: any }) => ({
  top: 0,
  display: "inline-block",
  width: "100%",
  height: "100%",
  backgroundColor: theme.palette.background.dark,
}));

export const SContainerDiv = styled("div")(({ theme }: { theme: any }) => ({
  position: "relative",
  color: theme.palette.text.primary,
  display: "inline-block",
  width: "98%",
  height: "calc(100% - 1rem)",
  verticalAlign: "top",
}));

export const SCloseButtonIconButton = styled(IconButton)(({ theme }: { theme: any }) => ({
  ...theme.layers.overlayMenu,
  display: "inherit",
  position: "absolute",
  top: 0,
  right: 0,
  margin: 0,
  "& svg": {
    fill: theme.palette.text.primary,
  },
  "&.collapsed": {
    position: "absolute",
    top: "-3.5rem",
    right: "1rem",
    ...theme.components.primaryButton,
    ...theme.components.border,
    boxSizing: "content-box",
  },
}));

export const SContentDiv = styled("div")({
  position: "relative",
  width: "100%",
  height: "100%",
  top: "initial",
  transform: "none",
});

export const SScrollButtonContainerDiv = styled("div")({
  zIndex: 1,
  position: "absolute",
  width: "100%",
  textAlign: "center",
  margin: "0 auto",
  "&.top": {
    top: 0,
    background: "linear-gradient(to bottom, rgba(43, 43, 43, 1) 0%, rgba(43, 43, 43, 0.8) 75%, rgba(43, 43, 43, 0) 100%)",
  },
  "&.bottom": {
    bottom: 0,
    background: "linear-gradient(to top, rgba(43, 43, 43, 1) 0%, rgba(43, 43, 43, 0.8) 75%, rgba(43, 43, 43, 0) 100%)",
  },
  "&.hidden": {
    visibility: "hidden",
  },
});

export const SScrollButtonIconButton = styled(IconButton)({
  width: "2rem",
  height: "2rem",
});

export const SScrollPromptSpan = styled("span")({
  display: "block",
  fontSize: "1rem",
});

export const SPanelsDiv = styled("div")({
  width: "100%",
  height: "100%",
  overflowY: "auto",
});

export const SCollapseButtonIconButton = styled(IconButton)(({ theme }: { theme: any }) => ({
  ...theme.layers.overlayMenu,
  display: "inherit",
  position: "absolute",
  top: 0,
  right: "2.5rem",
  margin: 0,
  "& svg": {
    fill: theme.palette.text.primary,
    fontSize: "2rem",
  },
}));

export const SCloseIcon = styled(CloseIcon)(({ theme }: { theme: any }) => ({
  ...theme.components.icon,
}));

export const SCollapseIcon = styled(CollapseIcon)(({ theme }: { theme: any }) => ({
  ...theme.components.icon,
}));

export const SScrollDownIcon = styled(ScrollDownIcon)(({ theme }: { theme: any }) => ({
  ...theme.components.icon,
}));

export const SScrollUpIcon = styled(ScrollUpIcon)(({ theme }: { theme: any }) => ({
  ...theme.components.icon,
}));

export const scrollContainer = css({
  width: "100%",
  height: "100%",
  overflow: "hidden",
});

export const swiperWrapper = css({
  height: "100%",
});

export const panelOut = css({
  opacity: 0.5,
});
