// @ts-nocheck
import update from "immutability-helper";
import deepmerge from "deepmerge";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { combineMerge } from "../utils";
import { ScreenType } from "../../../types";
import { uriToState } from "../uriSlice/thunk";
export const DRAGGABLE_CONTAINERS = 4;
export const LAYERS = {
  MAP_OVERLAY: 50,
  DRAGGABLE: 70,
  PRIMARY_BUTTON: 80,
  MENU: 90,
  OVERLAY_MENU: 100,
  OVERLAY: 1000,
};

export type Dimension = {
  name: string;
  pos: { x: number; y: number };
  width: number;
  height: number;
};

export interface UIState {

  screen: ScreenType;
  isMobile: boolean;
  scatterplotFullscreenVisible: boolean;
  order: Array<string>;
  search: {
    id: number;
    visible: boolean;
    expanded: boolean;
    open: boolean;
  };
  stories: {
    id: number;
    visible: boolean;
    expanded: boolean;
    showSelect: boolean;
    storyIndex: number;
    panelIndex: number;
  };
  options: {
    id: number;
    visible: boolean;
  };
  mapFilters: {
    id: number;
    visible: boolean;
    draggable: boolean;
    filters: Array<string>;
  };
  tutorial: {
    id: number;
    visible: boolean;
    index: number;
  };
  landingOverlay: {
    id: number;
    visible: boolean;
  };
  storiesMenu: {
    id: number;
    visible: boolean;
  };
  barcodes: {
    id: number;
    visible: boolean;
    draggable: boolean;
    order: number;
    pos: { x: number; y: number };
    value: number;
    mode: number;
  };

  data: {
    id: number;
    visible: boolean;
    draggable: boolean;
    order: number;
    pos: { x: number; y: number };
  };
  cancerType: {
    id: number;
    visible: boolean;
    draggable: boolean;
    order: number;
    pos: { x: number; y: number };
    expanded: boolean;
  };
  indicatorPanel: {
    id: number;
    visible: boolean;
    draggable: boolean;
    order: number;
    pos: { x: number; y: number };
    mode: number;
  };
  indicators: Array<{ active: boolean; value: number; view: number; sex: number; pc: number }>;
  mobileView: {
    id: number;
    visible: boolean;
    expanded: boolean;
    children: Array<any>;
  };
  dimensions: Dimension[];
}

const initialState: UIState = {
  screen: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  isMobile: false,
  scatterplotFullscreenVisible: false,

  order: [
    "data",
    "indicatorPanel",
    "barcodes",
    "cancerType",
  ],

  search: {
    id: 5,
    visible: true,
    expanded: false,
    open: false,
  },
  stories: {
    id: 6,
    visible: false,
    expanded: false,
    showSelect: false,
    storyIndex: -1,
    panelIndex: 0,
  },

  options: {
    id: 2,
    visible: false,
  },

  mapFilters: {
    id: 1,
    visible: false,
    draggable: false,
    filters: ["irsadRev", "ra5cat", "state"],
  },
  tutorial: {
    id: 10,
    visible: false,
    index: 0,
  },
  landingOverlay: {
    id: 12,
    visible: true,
  },
  storiesMenu: {
    id: 11,
    visible: false,
  },
  barcodes: {
    id: 4,
    visible: window.innerWidth > 600,
    draggable: true,
    order: 2,
    pos: { x: 0, y: 0 },
    value: 1,
    mode: 2,
  },
  data: {
    id: 7,
    visible: false,
    draggable: true,
    order: 3,
    pos: { x: 0, y: 0 },
  },
  cancerType: {
    id: 0,
    visible: true,
    draggable: true,
    order: 0,
    pos: { x: 0, y: 0 },
    expanded: false,
  },
  indicatorPanel: {
    id: 3,
    visible: true,
    draggable: true,
    order: 1,
    pos: { x: 0, y: 0 },
    mode: window.innerWidth > 600 ? 1 : 0,
  },
  indicators: [
    { active: true, value: 0, view: 1, sex: 3, pc: 3 },
  ],
  mobileView: {
    id: 9,
    visible: true,
    expanded: false,
    children: [],
  },
  dimensions: [
    { name: "cancerType", pos: { x: 0, y: 0 }, width: 0, height: 0 },
    { name: "indicatorPanel", pos: { x: 0, y: 0 }, width: 0, height: 0 },
  ],
};

type UIStatePartial<T> = {
  [P in keyof T]?: T[P] extends UIState ? UIStatePartial<T[P]> : T[P];
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    windowResize: (state, action: PayloadAction<{ screen: ScreenType }>) => {
      const { screen } = action.payload;
      if (state.screen.width !== screen.width || state.screen.height !== screen.height) {
        // Reset draggable components
        let draggables: any = {};
        state.order.forEach((draggable) => {
          const d = draggable as keyof UIState;
          if (typeof d === "object" && "pos" in state[d]) {
            if ((state[d]["pos"]["x"] !== 0 && state[d]["pos"]["y"] !== 0) || state[d]["expanded"]) {
              draggables[d] = state[d];
              draggables[d].pos = { x: 0, y: 0 };
              draggables[d].expanded = false;
            }
          }
        });
        state.screen = { ...state.screen, width: screen.width, height: screen.height };
        state = { ...state, ...draggables };
      }
    },
    updateComponent: (state, action: PayloadAction<{ name: keyof UIState; data: any }>) => {
      if (state[action.payload.name]) {
        return update(state, { [action.payload.name]: { $merge: action.payload.data } });
      }
    },
    updateIndicator: (state, action: PayloadAction<{ index: any; data: any }>) => {
      if (action.payload.index !== null && action.payload.data) {
        let updatedIndicators = state.indicators.map((indicator, index) => {
          if (index == action.payload.index) return { ...indicator, ...action.payload.data };
          else return indicator;
        });
        state.indicators = updatedIndicators;
      }
    },
    updateDimensions: (state, action: PayloadAction<Dimension>) => {
      const { name } = action.payload;
      state.dimensions = state.dimensions.map((d) => {
        return d.name === name ? action.payload : d;
      });
    },
    updateIndicators: (state, action: PayloadAction<{ data: any }>) => {
      if (action.payload.data) {
        let updatedIndicators = state.indicators.map((indicator) => {
          return { ...indicator, ...action.payload.data };
        });
        state.indicators = updatedIndicators;
      }
    },
    reorderDraggables: (state, action: PayloadAction<string>) => {

    },
    applyStateUI: (state, action: PayloadAction<UIStatePartial>) => {
      for (let key in action.payload) {
        const typedKey = key as keyof UIState;
        state[typedKey] = update(state[typedKey], { $set: deepmerge(state[typedKey], action.payload[typedKey], { arrayMerge: combineMerge }) });
      }
    },

    updateIsMobile: (state, action: PayloadAction<{ isMobile: UIState["isMobile"] }>) => {
      state.isMobile = action.payload.isMobile;
    },

    updateFullscreenScatterplot: (state, action: PayloadAction<{ isVisible: UIState["scatterplotFullscreenVisible"] }>) => {
      state.scatterplotFullscreenVisible = action.payload.isVisible;
    },
  },
});

export const { windowResize, updateComponent, updateIndicator, updateIndicators, reorderDraggables, applyStateUI, updateIsMobile, updateFullscreenScatterplot, updateDimensions } = uiSlice.actions;
export default uiSlice.reducer;
