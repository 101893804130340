import { useTranslation } from "react-i18next";
import * as FilterActionsThunk from "../../../redux/features/filterSlice/thunk";
import * as UIActionsThunk from "../../../redux/features/uiSlice/thunk";
import { useTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/types";
import SexSelectDisplay from "./SexSelectDisplay";

interface SexSelectContainerProps {
  index: any;
}

const SexSelectContainer = ({ index }: SexSelectContainerProps) => {
  const theme = useTheme();
  const { indicators } = useAppSelector((state) => state.ui);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleSexFilter = (newValue: any) => {
    if (typeof newValue === "number" && (newValue !== null || newValue !== undefined)) {
      // Get current value according to indicator window active state
      const currentSex = indicators[index].sex;
      const sexTitle = t(`filters:sex.values.${currentSex}`);
      if (newValue !== currentSex) {
        // Update sex filter value for active indicator
        if (indicators[index].active) {
          dispatch(FilterActionsThunk.updateFilter({ fieldName: "sex", type: "radio", _name: "", _value: newValue }));
        }
        // Update selected sex for UI
        dispatch(UIActionsThunk.updateIndicator({ index, data: { sex: newValue } }));
      }
    }
  };

  return useMemo(() => {
    return <SexSelectDisplay sex={indicators[index].sex} handleSelect={handleSexFilter} />;
  }, [indicators[index].sex]);
};

export default SexSelectContainer;
