export function changeLanguageCookie(language: string) {
    if (language === "en") {
        document.cookie = "i18next=nl";
    } else {
        document.cookie = "i18next=en";
    }

    window.location = window.location.origin as any;
};

export function getCountryCode(language: string) {
    if (language === "en") {
        return "GB";
    } else {
        return "NL";
    }
}