import { useState } from "react";
import ReactMarkdown from "react-markdown";
import * as UIActions from "../../redux/features/uiSlice";
import { useAppDispatch, useAppSelector } from "../../redux/types";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import Popup from "../Popup";
import Indicators from "../Indicator";
import styled from "@emotion/styled";
import { BREAKPOINTS } from "../../constants";
import { SA2DataPieceType, ScreenType } from "../../types";
const COMPONENT_NAME = "stories";

enum DIRECTIONS {
  UP = "UP",
  DOWN = "DOWN",
}

const STitleTypography = styled(Typography)(({ expanded, screen }: { expanded: any; screen: any }) => ({
  margin: "0 1rem",
  paddingTop: "5rem",
  ...(screen.width <= BREAKPOINTS.WIDTH && {
    fontSize: "1.4rem",
  }),
  ...(!expanded && {
    paddingTop: 0,
  }),
}));

const SContentDiv = styled(ReactMarkdown as any)({
  margin: "0 1rem",
});

const SComponentsDiv = styled("div")({
  width: "100%",
});

const SButton = styled(Button)({
  width: "7.5rem",
  marginLeft: "1rem",
  padding: "0.1rem",
  minHeight: "2rem",
  "& span": {
    fontSize: "0.95rem",
    fontWeight: "bold",
  },
});

const SPanelDiv = styled("div")(({ theme, expanded }: { theme: any; expanded: any }) => ({
  position: "relative",
  width: "calc(100% - 2rem)",
  minHeight: "calc(100vh - 5rem)",
  margin: "0 auto",
  "& div": {},
  "& p": {
    fontSize: "1rem",
    lineHeight: "1.5rem",
    margin: "2rem 0",
  },
  "& img": {
    maxWidth: "100%",
  },
  "& ul": {
    listStyleType: "disc",
    listStylePosition: "outside",
    paddingLeft: "1em",
  },
  "& li": {
    paddingBottom: "1em",
  },
  "& bold": {
    color: theme.palette.text.hint,
    fontWeight: 400,
  },
  ...(expanded
    ? {
        marginTop: "calc(10vh - 2rem)",
      }
    : {
        marginTop: "1rem",
        //minHeight: "auto",
        display: "inline-block",
        "& h1": {
          fontSize: "1.25rem",
        },
        "& div": {
          marginTop: 0,
        },
        "& p": {
          margin: "1rem 0",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 3,
          maxHeight: "5rem",
          width: "100%",
          fontSize: "0.95rem",
          textOverflow: "ellipsis",
          overflow: "hidden",
        },
      }),
}));

interface PanelContainerProps {
  index: number;
  panelData: any;
  children?: Array<any>;
}

const PanelContainer = (props: PanelContainerProps) => {
  const { panelData, index } = props;
  const { expanded } = useAppSelector((state) => state.ui[COMPONENT_NAME]);
  const { screen } = useAppSelector((state) => state.ui);
  const { sa2Data } = useAppSelector((state) => state.cancerAtlas);
  const dispatch = useAppDispatch();

  const getChildComponents = (index: number, ui: any, sa2s: Array<any>) => {
    let children = [];
    // Compile child components
    if (screen.width < BREAKPOINTS.WIDTH || screen.height < BREAKPOINTS.HEIGHT) {
      if (sa2s && sa2s.length > 0) {
        sa2s.map((sa2: SA2DataPieceType, i: number) => {
          let title = sa2Data?.find((d: any) => d.id == sa2)?.name;
          children.push(<Popup staticBool={true} key={`panel_${index}_popup_${i}`} title={title ?? ""} sa2={sa2} docked={false} posDefault={{ x: 0, y: 0 }} />);
        });
      }
      if (ui["indicatorPanel"].visible) {
        children.push(
          <Indicators
            isStatic={true}
            staticState={{
              visible: ui["indicatorPanel"].visible,
              mode: ui["indicatorPanel"].mode,
              pos: ui["indicatorPanel"].pos || { x: 0, y: 0 },
              indicators: ui["indicators"],
            }}
            key={`panel_${index}_indicators`}
          />
        );
      }
    }
    return children;
  };
  const [children, setChildren] = useState(panelData.appState && panelData.appState.ui ? getChildComponents(index, panelData.appState.ui, panelData.appState.sa2s) : props.children);

  const handleExpand = () => {
    dispatch(UIActions.updateComponent({ name: COMPONENT_NAME, data: { expanded: true } }));
  };

  const expanded2: boolean = expanded || (screen.width >= BREAKPOINTS.WIDTH && screen.height >= BREAKPOINTS.HEIGHT);

  if (screen.width < BREAKPOINTS.WIDTH || screen.height < BREAKPOINTS.HEIGHT) {
    // Mobile Panel
    return <Panel expanded={expanded2} screen={screen} title={panelData.title} abstract={panelData.abstract} content={panelData.content} children={children} handleExpand={handleExpand} />;
  } else {
    // Desktop Panel
    return <Panel expanded={true} screen={screen} title={panelData.title} abstract={panelData.abstract} content={panelData.content} />;
  }
};

interface PanelProps {
  expanded?: boolean;
  title?: string;
  abstract?: string;
  content?: string;
  children?: Array<any>;
  handleExpand?: Function;
  screen?: any;
}
export const Panel = ({ expanded, handleExpand, title, abstract, content, children = [], screen }: PanelProps) => {
  const theme = useTheme();

  const handleExpandClick = () => {
    const direction = DIRECTIONS.UP;
    handleExpand && handleExpand(direction);
  };

  return (
    <SPanelDiv className={`swiper-slide`} theme={theme} expanded={expanded}>
      <STitleTypography className={`${!expanded && "abstract"}`} expanded={expanded} screen={screen} variant="h5">
        {title}
      </STitleTypography>
      {
        // Output HTML contents or abstract according to expanded status
        expanded
          ? [
              <SContentDiv key={`${title}_content`} children={content ?? ""} linkTarget={"_blank"} />,
              <img key={"hack"} className="swiper-lazy" data-src={""} />,
              <div key={"preloader"} className="swiper-lazy-preloader swiper-lazy-preloader-white" />,
              children.length > 0 && <SComponentsDiv key={`${title}_components`}>{children}</SComponentsDiv>,
            ]
          : [
              <SContentDiv key={`${title}_abstract`} children={abstract ?? ""} linkTarget={"_blank"} />,
              <SButton key={`${title}_button`} onClick={handleExpandClick} variant="contained" color="primary">
                See More
              </SButton>,
            ]
      }
    </SPanelDiv>
  );
};

export default PanelContainer;
