import { remToPx } from "../styles/theme";
import { ScreenType } from "../types";

export const Logo = `<?xml version="1.0" encoding="utf-8"?>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 445 217" style="enable-background:new 0 0 445 217;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#FFFFFF;}
	.st1{clip-path:url(#SVGID_2_);}
	.st2{fill:#EA2427;}
	.st3{fill:#FCC58E;}
	.st4{fill:#3B54A2;}
	.st5{fill:#F8A876;}
	.st6{fill:#EC1F7E;}
	.st7{fill:#70C06B;}
	.st8{fill:#8CD4E0;}
	.st9{fill:#4A9AD4;}
	.st10{fill:#6D53A2;}
	.st11{fill:#C576AF;}
	.st12{fill:#F9F29E;}
	.st13{fill:#0E7F40;}
	.st14{fill:#801619;}
	.st15{fill:#93B5C8;}
	.st16{fill:#B7529E;}
	.st17{fill:#6FCBDC;}
	.st18{fill:#FBD00D;}
	.st19{fill:#4B89C8;}
	.st20{fill:#6ABC45;}
	.st21{fill:#F4EA0B;}
	.st22{fill:#5792CD;}
	.st23{fill:#9FBCC7;}
	.st24{fill:#F5EB35;}
	.st25{fill:#F7EE6C;}
	.st26{fill:#D6DCC0;}
	.st27{fill:#F6996A;}
	.st28{fill:#BA59A1;}
	.st29{fill:#447FBE;}
</style>
<g>
	<g>
		<path class="st0" d="M187.8,89.6l6.6-20.3h4.2l6.6,20.3h-3.8l-1.6-5.5H193l-1.6,5.5C191.4,89.6,187.8,89.6,187.8,89.6z
			 M194.6,78.8l-0.7,2.5h5.2l-0.7-2.5c-0.3-1.1-0.6-2.2-0.9-3.3c-0.3-1.1-0.6-2.3-0.9-3.4h-0.1c-0.3,1.1-0.6,2.3-0.9,3.4
			C195.2,76.6,194.9,77.7,194.6,78.8z"/>
		<path class="st0" d="M214.9,90c-1.2,0-2.2-0.2-3.2-0.5c-0.9-0.3-1.7-0.9-2.4-1.6s-1.2-1.7-1.6-2.9c-0.4-1.2-0.5-2.6-0.5-4.3V69.3
			h3.6V81c0,1.1,0.1,2,0.3,2.8s0.5,1.4,0.8,1.8c0.4,0.5,0.8,0.8,1.3,1s1.1,0.3,1.7,0.3c0.6,0,1.2-0.1,1.7-0.3c0.5-0.2,1-0.5,1.3-1
			c0.4-0.5,0.7-1.1,0.9-1.8c0.2-0.8,0.3-1.7,0.3-2.8V69.3h3.4v11.4c0,1.7-0.2,3.1-0.5,4.3c-0.4,1.2-0.9,2.1-1.5,2.9
			c-0.7,0.7-1.5,1.3-2.4,1.6C217.1,89.8,216.1,90,214.9,90z"/>
		<path class="st0" d="M233.2,90c-1.4,0-2.7-0.3-4-0.8s-2.4-1.3-3.4-2.2l2.1-2.5c0.7,0.7,1.6,1.2,2.5,1.7c0.9,0.4,1.9,0.6,2.8,0.6
			c1.2,0,2-0.2,2.6-0.7s0.9-1.1,0.9-1.9c0-0.4-0.1-0.8-0.2-1.1c-0.2-0.3-0.4-0.5-0.7-0.8c-0.3-0.2-0.6-0.4-1-0.6s-0.8-0.4-1.3-0.6
			l-2.8-1.2c-0.5-0.2-1-0.5-1.5-0.8s-0.9-0.7-1.3-1.1c-0.4-0.4-0.7-0.9-0.9-1.5s-0.4-1.2-0.4-2s0.2-1.6,0.5-2.2
			c0.3-0.7,0.8-1.3,1.4-1.8c0.6-0.5,1.3-0.9,2.2-1.2c0.8-0.3,1.8-0.4,2.7-0.4c1.2,0,2.3,0.2,3.4,0.7c1.1,0.5,2,1.1,2.8,1.9l-1.8,2.3
			c-0.7-0.5-1.3-1-2-1.3s-1.5-0.5-2.4-0.5c-1,0-1.7,0.2-2.3,0.6s-0.9,1-0.9,1.8c0,0.4,0.1,0.7,0.3,1s0.4,0.5,0.7,0.7s0.6,0.4,1,0.6
			s0.8,0.3,1.2,0.5l2.8,1.1c0.6,0.2,1.1,0.5,1.6,0.9c0.5,0.3,0.9,0.7,1.3,1.1c0.4,0.4,0.6,0.9,0.9,1.5c0.2,0.6,0.3,1.2,0.3,2
			s-0.2,1.6-0.5,2.3c-0.3,0.7-0.8,1.4-1.4,1.9c-0.6,0.5-1.4,1-2.3,1.3S234.4,90,233.2,90z"/>
		<path class="st0" d="M247.7,89.6V72.4h-5.9v-3h15.3v3h-5.9v17.2C251.2,89.6,247.7,89.6,247.7,89.6z"/>
		<path class="st0" d="M260.5,89.6V69.3h6.9c1.1,0,2,0.1,2.9,0.3c0.9,0.2,1.7,0.5,2.4,1c0.7,0.5,1.2,1.1,1.6,1.8
			c0.4,0.8,0.6,1.7,0.6,2.9c0,1.6-0.4,2.8-1.1,3.8s-1.7,1.6-2.9,2l4.8,8.5h-4l-4.4-7.9h-3.1v7.9L260.5,89.6L260.5,89.6z M264.1,78.8
			h2.9c1.4,0,2.4-0.3,3.2-0.9c0.7-0.6,1.1-1.4,1.1-2.6s-0.4-2-1.1-2.5s-1.8-0.7-3.2-0.7h-2.9V78.8z"/>
		<path class="st0" d="M276.1,89.6l6.6-20.3h4.2l6.6,20.3h-3.8l-1.6-5.5h-6.8l-1.6,5.5C279.7,89.6,276.1,89.6,276.1,89.6z
			 M282.9,78.8l-0.7,2.5h5.2l-0.7-2.5c-0.3-1.1-0.6-2.2-0.9-3.3c-0.3-1.1-0.6-2.3-0.9-3.4h-0.1c-0.3,1.1-0.6,2.3-0.9,3.4
			C283.5,76.6,283.2,77.7,282.9,78.8z"/>
		<path class="st0" d="M296,89.6V69.3h3.6v17.2h8.4v3L296,89.6L296,89.6z"/>
		<path class="st0" d="M311.6,89.6V69.3h3.6v20.3C315.2,89.6,311.6,89.6,311.6,89.6z"/>
		<path class="st0" d="M317.7,89.6l6.6-20.3h4.2l6.6,20.3h-3.8l-1.6-5.5h-6.8l-1.6,5.5C321.3,89.6,317.7,89.6,317.7,89.6z
			 M324.5,78.8l-0.7,2.5h5.2l-0.7-2.5c-0.3-1.1-0.6-2.2-0.9-3.3c-0.3-1.1-0.6-2.3-0.9-3.4h-0.1c-0.3,1.1-0.6,2.3-0.9,3.4
			C325.1,76.6,324.8,77.7,324.5,78.8z"/>
		<path class="st0" d="M337.6,89.6V69.3h3.7l6.3,11.4l2.1,4.3h0.1c-0.1-1.1-0.2-2.2-0.3-3.4c-0.1-1.2-0.2-2.4-0.2-3.5v-8.8h3.4v20.3
			H349l-6.3-11.4l-2.1-4.3h-0.1c0.1,1.1,0.2,2.2,0.3,3.4c0.1,1.2,0.2,2.3,0.2,3.5v8.9h-3.4V89.6z"/>
		<path class="st0" d="M198.5,121c-1.3,0-2.5-0.2-3.6-0.7s-2.1-1.1-2.9-2c-0.8-0.9-1.5-2-1.9-3.3c-0.5-1.3-0.7-2.8-0.7-4.5
			s0.2-3.1,0.7-4.5c0.5-1.3,1.1-2.4,2-3.3c0.8-0.9,1.8-1.6,2.9-2.1c1.1-0.5,2.3-0.7,3.6-0.7s2.4,0.3,3.4,0.8s1.8,1.1,2.5,1.8
			l-1.9,2.3c-0.5-0.5-1.1-1-1.7-1.3c-0.6-0.3-1.3-0.5-2.1-0.5s-1.6,0.2-2.3,0.5s-1.3,0.8-1.8,1.5c-0.5,0.6-0.9,1.4-1.2,2.3
			s-0.4,1.9-0.4,3.1c0,2.3,0.5,4.1,1.5,5.4s2.4,2,4.1,2c0.9,0,1.7-0.2,2.4-0.6s1.3-0.9,1.9-1.5l1.9,2.3c-0.8,1-1.8,1.7-2.8,2.2
			C201,120.7,199.8,121,198.5,121z"/>
		<path class="st0" d="M205.6,120.6l6.6-20.3h4.2l6.6,20.3h-3.8l-1.6-5.5h-6.8l-1.6,5.5C209.2,120.6,205.6,120.6,205.6,120.6z
			 M212.4,109.8l-0.7,2.5h5.2l-0.7-2.5c-0.3-1.1-0.6-2.2-0.9-3.3c-0.3-1.1-0.6-2.3-0.9-3.4h-0.1c-0.3,1.1-0.6,2.3-0.9,3.4
			C213.1,107.7,212.8,108.8,212.4,109.8z"/>
		<path class="st0" d="M225.6,120.6v-20.3h3.7l6.3,11.4l2.1,4.3h0.1c-0.1-1.1-0.2-2.2-0.3-3.4c-0.1-1.2-0.2-2.4-0.2-3.5v-8.8h3.4
			v20.3H237l-6.3-11.4l-2.1-4.3h-0.1c0.1,1.1,0.2,2.2,0.3,3.4c0.1,1.2,0.2,2.3,0.2,3.5v8.9h-3.4V120.6z"/>
		<path class="st0" d="M254,121c-1.3,0-2.5-0.2-3.6-0.7s-2.1-1.1-2.9-2c-0.8-0.9-1.5-2-1.9-3.3c-0.5-1.3-0.7-2.8-0.7-4.5
			s0.2-3.1,0.7-4.5c0.5-1.3,1.1-2.4,2-3.3c0.8-0.9,1.8-1.6,2.9-2.1c1.1-0.5,2.3-0.7,3.6-0.7s2.4,0.3,3.4,0.8s1.8,1.1,2.5,1.8
			l-1.9,2.3c-0.5-0.5-1.1-1-1.7-1.3s-1.3-0.5-2.1-0.5s-1.6,0.2-2.3,0.5s-1.3,0.8-1.8,1.5c-0.5,0.6-0.9,1.4-1.2,2.3s-0.4,1.9-0.4,3.1
			c0,2.3,0.5,4.1,1.5,5.4s2.4,2,4.1,2c0.9,0,1.7-0.2,2.4-0.6s1.3-0.9,1.9-1.5l1.9,2.3c-0.8,1-1.8,1.7-2.8,2.2
			C256.5,120.7,255.3,121,254,121z"/>
		<path class="st0" d="M263.8,120.6v-20.3H276v3h-8.6v5.2h7.3v3h-7.3v6h8.9v3L263.8,120.6L263.8,120.6z"/>
		<path class="st0" d="M280.5,120.6v-20.3h6.9c1.1,0,2,0.1,2.9,0.3c0.9,0.2,1.7,0.5,2.4,1c0.7,0.5,1.2,1.1,1.6,1.8
			c0.4,0.8,0.6,1.7,0.6,2.9c0,1.6-0.4,2.8-1.1,3.8s-1.7,1.6-2.9,2l4.8,8.5h-4l-4.4-7.9h-3.1v7.9L280.5,120.6L280.5,120.6z
			 M284.1,109.8h2.9c1.4,0,2.4-0.3,3.2-0.9c0.7-0.6,1.1-1.4,1.1-2.6s-0.4-2-1.1-2.4c-0.7-0.5-1.8-0.7-3.2-0.7h-2.9V109.8z"/>
		<path class="st0" d="M187.8,151.6l6.6-20.3h4.2l6.6,20.3h-3.8l-1.6-5.5H193l-1.6,5.5H187.8z M194.6,140.9l-0.7,2.5h5.2l-0.7-2.5
			c-0.3-1.1-0.6-2.2-0.9-3.3c-0.3-1.1-0.6-2.3-0.9-3.4h-0.1c-0.3,1.1-0.6,2.3-0.9,3.4C195.2,138.7,194.9,139.8,194.6,140.9z"/>
		<path class="st0" d="M209.8,151.6v-17.2H204v-3h15.3v3h-5.9v17.2H209.8z"/>
		<path class="st0" d="M222.7,151.6v-20.3h3.6v17.2h8.4v3h-12V151.6z"/>
		<path class="st0" d="M235.6,151.6l6.6-20.3h4.2l6.6,20.3h-3.8l-1.6-5.5h-6.8l-1.6,5.5H235.6z M242.4,140.9l-0.7,2.5h5.2l-0.7-2.5
			c-0.3-1.1-0.6-2.2-0.9-3.3c-0.3-1.1-0.6-2.3-0.9-3.4h-0.1c-0.3,1.1-0.6,2.3-0.9,3.4C243,138.7,242.7,139.8,242.4,140.9z"/>
		<path class="st0" d="M261.5,152c-1.4,0-2.7-0.3-4-0.8s-2.4-1.3-3.4-2.2l2.1-2.5c0.7,0.7,1.6,1.2,2.5,1.7s1.9,0.6,2.8,0.6
			c1.2,0,2-0.2,2.6-0.7s0.9-1.1,0.9-1.9c0-0.4-0.1-0.8-0.2-1.1c-0.2-0.3-0.4-0.5-0.7-0.8c-0.3-0.2-0.6-0.4-1-0.6
			c-0.4-0.2-0.8-0.4-1.3-0.6l-2.8-1.2c-0.5-0.2-1-0.5-1.5-0.8s-0.9-0.7-1.3-1.1c-0.4-0.4-0.7-0.9-0.9-1.5s-0.4-1.2-0.4-2
			s0.2-1.6,0.5-2.2c0.3-0.7,0.8-1.3,1.4-1.8s1.3-0.9,2.2-1.2c0.8-0.3,1.8-0.4,2.7-0.4c1.2,0,2.3,0.2,3.4,0.7s2,1.1,2.8,1.9l-1.8,2.3
			c-0.7-0.5-1.3-1-2-1.3s-1.5-0.5-2.4-0.5c-1,0-1.7,0.2-2.3,0.6c-0.6,0.4-0.9,1-0.9,1.8c0,0.4,0.1,0.7,0.3,1s0.4,0.5,0.7,0.7
			c0.3,0.2,0.6,0.4,1,0.6c0.4,0.2,0.8,0.3,1.2,0.5l2.8,1.1c0.6,0.2,1.1,0.5,1.6,0.9c0.5,0.3,0.9,0.7,1.3,1.1
			c0.4,0.4,0.6,0.9,0.9,1.5c0.2,0.6,0.3,1.2,0.3,2s-0.2,1.6-0.5,2.3s-0.8,1.4-1.4,1.9s-1.4,1-2.3,1.3S262.6,152,261.5,152z"/>
	</g>
</g>
<g>
	<g>
		<defs>
			<circle id="SVGID_1_" cx="101.9" cy="109.3" r="68.5"/>
		</defs>
		<clipPath id="SVGID_2_">
			<use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
		</clipPath>
		<g class="st1">
			<path class="st2" d="M73.7,103.2c0,9.8,0,19.6,0,29.4c-14.7,0-29.4,0-44.1,0c0-24.5,0-48.9,0-73.4C67.8,50.4,82.5,65,73.7,103.2z
				"/>
			<path class="st3" d="M154.7,122.8c8.1,4,13.1,11,15.8,20.5c-3.5-1.4-6.2-3.7-9.3-5.6c-1.4-1.7-2.9-3.3-4.7-4.7
				c-0.5-0.9-0.8-2-1.9-2.3c0-0.6-0.2-1-0.5-1.4C154,126.8,154.5,125,154.7,122.8z"/>
			<path class="st4" d="M225,111.2c-0.9,9-4.4,15.5-8.4,21.4c-0.2,0-0.3,0-0.5,0c-1-0.8-1.9-1.8-3.3-2.3c-1.4-1.7-2.9-3.3-4.7-4.7
				c-4-5.6-7.6-11.7-8.4-20.5c0-2.5,0-5,0-7.4c0.9-9,4.5-15.3,8.8-20.9c0.2,0,0.3,0,0.5,0c8.5,6.1,14.2,14.9,15.8,27.9
				C225,106.8,225,109,225,111.2z"/>
			<path class="st5" d="M50.5,130.7c0-2.2,0-4.3,0-6.5C51.3,125.3,51.3,129.6,50.5,130.7z"/>
			<path class="st6" d="M353.4,130.3c0-1.9,0-3.7,0-5.6C354.1,125.5,354.1,129.5,353.4,130.3z"/>
			<path class="st7" d="M316.2,130.3c-0.8-0.8-0.8-4.8,0-5.6C316.2,126.5,316.2,128.4,316.2,130.3z"/>
			<path class="st8" d="M284.5,127.9c-0.8-0.5-0.8-4.1,0-4.7C284.5,124.8,284.5,126.4,284.5,127.9z"/>
			<path d="M268.7,111.2c-7,2.9-16.3,0.4-21.9-2.3c0-0.2,0-0.3,0-0.5C254,105.3,264,106.8,268.7,111.2z"/>
			<path class="st9" d="M225,111.2c0-2.2,0-4.3,0-6.5C225.8,105.8,225.8,110.1,225,111.2z"/>
			<path class="st2" d="M164.5,107.9c-1,0.8-5.1,0.8-6.1,0C160.5,107.9,162.5,107.9,164.5,107.9z"/>
			<path d="M501.3,102.4c0.4,1.6-0.7,1.8-0.5,3.3c-0.2,0-0.3,0-0.5,0c-1.9-1.5-4.3-2.5-5.6-4.7c2.8-0.3,3.9,1,6.5,0.9
				C501.3,102,501.3,102.2,501.3,102.4z"/>
			<path class="st10" d="M274.3,105.1c0-2.5,0-5,0-7.4C275.1,99.1,275.1,103.7,274.3,105.1z"/>
			<path class="st10" d="M199.9,105.1c-0.8-1.4-0.8-6,0-7.4C199.9,100.2,199.9,102.7,199.9,105.1z"/>
			<path class="st8" d="M309.6,98.2c0.5-0.8,4.2-0.8,4.7,0C312.7,98.2,311.2,98.2,309.6,98.2z"/>
			<path class="st6" d="M22.1,95.8c-2.2,0-4.3,0-6.5,0C16.7,95.1,21,95.1,22.1,95.8z"/>
			<path class="st11" d="M533.4,83.3c0.8,0.5,0.8,4.2,0,4.6C533.4,86.4,533.4,84.8,533.4,83.3z"/>
			<path class="st12" d="M-56.9,84.2c-0.8,0.8-4.8,0.8-5.6,0C-60.7,84.2-58.8,84.2-56.9,84.2z"/>
			<path class="st10" d="M192.9,80c0-2.2,0-4.3,0-6.5C193.7,74.6,193.7,78.9,192.9,80z"/>
			<path d="M262.7,74.4c-10.3,2.7-20.9-1.1-25.6-7c0.1-1.6,1.6-1.8,1.9-3.3c0.2,0,0.3,0,0.5,0c10.5,0.5,17.3,4.7,23.3,9.8
				C262.7,74.1,262.7,74.3,262.7,74.4z"/>
			<path d="M192.4,70.7c-6,3.8-15.6,5.8-24.2,3.3c0-0.2,0-0.3,0-0.5c4.5-1.9,9-3.7,14.9-4.2c2.2,0,4.3,0,6.5,0
				c1.4-0.3,1.7,0.5,2.8,0.5c0,0.2,0,0.3,0,0.5C192.4,70.4,192.4,70.6,192.4,70.7z"/>
			<path d="M354.8,59.6c-4.7,14.2-31.6,14.2-36.3,0c0-0.3,0-0.6,0-0.9c-1-22.8,37.3-22.8,36.3,0C354.8,58.9,354.8,59.2,354.8,59.6z"
				/>
			<path class="st13" d="M189.6,69.3c-2.2,0-4.3,0-6.5,0C184.2,68.6,188.5,68.6,189.6,69.3z"/>
			<path d="M-155.6,67.9c-0.2,0-0.3,0-0.5,0c-6.4-5.4-10.9-16.8-7.4-27.9C-147.3,38.2-143.4,62.3-155.6,67.9z"/>
			<path class="st13" d="M415.2,64.2c-1.4,0.8-6,0.8-7.4,0C410.3,64.2,412.8,64.2,415.2,64.2z"/>
			<path d="M417.6,63.7c-1.1-0.2-1,0.8-2.3,0.5c-2.5,0-5,0-7.4,0c-1.3,0.4-1.2-0.7-2.3-0.5c2.5-2.6,9-2.1,12.1-0.5
				C417.6,63.4,417.6,63.6,417.6,63.7z"/>
			<path d="M179.4,51.2c-5.2,5.9-18.2,9.2-27.9,5.1c0-0.2,0-0.3,0-0.5c-1-2.1-0.9-5.3-1.4-7.9c0-0.2,0-0.3,0-0.5
				c3-0.4,5-1.8,8.4-1.9c2.2,0,4.3,0,6.5,0c5.9,0.6,10.2,2.8,14.4,5.1C179.4,50.9,179.4,51,179.4,51.2z"/>
			<path class="st12" d="M-23.9,45.6c0.8,0.8,0.8,4.8,0,5.6C-23.9,49.3-23.9,47.5-23.9,45.6z"/>
			<path class="st12" d="M-95.5,51.2c-0.8-0.8-0.8-4.8,0-5.6C-95.5,47.5-95.5,49.3-95.5,51.2z"/>
			<path class="st14" d="M165,45.6c-2.2,0-4.3,0-6.5,0C159.6,44.8,163.9,44.8,165,45.6z"/>
			<path class="st15" d="M147.8,104.7c-20.5-5.5-23.3-44.8-3.7-53.5c2.3,1.7,4.3,3.8,7.4,4.7c0,0.2,0,0.3,0,0.5
				c2.9,8.5,8.2,14.4,16.8,17.2c0,0.2,0,0.3,0,0.5C157,79.8,149.7,89.6,147.8,104.7z"/>
			<path class="st16" d="M5.4,98.6C-4,96.2-10.4,80.5-3,71.2c6.6,6.9,20.4,11.1,32.6,7c0.2,0,0.3,0,0.5,0c0.9,9-2.7,13.5-6.5,17.7
				c-0.3,0-0.6,0-0.9,0c-0.2,0-0.3,0-0.5,0c-1.1-0.8-5.4-0.8-6.5,0c-3.8,0.4-7.2,1.2-9.8,2.8C5.7,98.6,5.6,98.6,5.4,98.6z"/>
			<path class="st17" d="M-17.9,108.4c-4.9-4.9-0.7-13.2,6-12.6C1,97-7.9,118.4-17.9,108.4z"/>
			<path class="st15" d="M171.9,149.8c0.7,19.8-13.4,35.4-31.6,36.8c-18.8,1.4-34-11-36.8-30.2c24,6.2,39.6-17.8,28.8-37.7
				c0-0.2,0-0.3,0-0.5c5.7-1.3,12.1,0.1,16.7,1.4c0.4-0.1,0.4,0.2,0.5,0.5c1.3,3.4,2.5,6.8,4.7,9.3c0.2,0.4,0.5,0.8,0.5,1.4
				C156.3,141.3,162.8,146.8,171.9,149.8z"/>
			<path class="st16" d="M-73.2,97.2c2.3-4.5,12.5-6.5,16.7-2.3C-62.4,93.7-68.7,95.6-73.2,97.2z"/>
			<path class="st18" d="M-55.5,94.9c15.1,3.2,26.1,12.7,27.9,28.8c2.1,18.6-13.8,38-35.4,35.8c-16.7-1.7-28-14.2-29.8-29.3
				c-1.7-15.3,7.1-26.2,18.6-32.6c-1.7,3.9-2.8,5.3-2.3,9.8c1.9,16.8,28,13.3,26.1-3.7C-50.9,99.9-52.7,98.3-55.5,94.9z"/>
			<path class="st5" d="M48.2,115.4c-5.8,4.1-5.8,17.6-1.4,23.3c-2.5-1.2-4.8-2.6-8.4-2.8c-0.5-0.8-4.2-0.8-4.7,0
				c-10.4,1.7-16.4,7.7-18.1,18.1c-0.8,0.5-0.8,4.2,0,4.7c0,0.2,0,0.3,0,0.5c-16.3-2.6-25.8-12-28.4-28.4c0-2.2,0-4.3,0-6.5
				c1.6-12.9,8.5-20.7,18.1-25.6c0.2,0,0.3,0,0.5,0c5.4,2,14.1,0.9,16.7-2.8c0.3,0,0.6,0,0.9,0c12.2,2.3,19.7,9.2,24.7,18.6
				C48.2,114.8,48.2,115.1,48.2,115.4z"/>
			<path class="st19" d="M103.6,147c9.3-2.8,16-10.6,14.4-22.8c0-0.2,0-0.3,0-0.5c4-2.5,8.7-4.4,14-5.6c-0.1,0.4,0.2,0.4,0.5,0.5
				c10.8,19.9-4.8,43.9-28.8,37.7c0-0.2,0-0.3,0-0.5C102.6,153.1,103.4,150.1,103.6,147z"/>
			<path class="st20" d="M500.4,105.6c-1.7,10.8-22.2,12.4-26.5,2.8c4.4-4.9,11-7.6,20-7.9c0.5,0,0.6,0.4,0.9,0.5
				C496.1,103.1,498.5,104.1,500.4,105.6z"/>
			<path class="st2" d="M118,124.2c-7.7,4.7-12.3,12.5-14.4,22.8c-3.8,1.5-9.5,0.9-13-0.5c-0.4-0.1-0.5-0.5-0.9-0.5
				c-8.9-14.8-0.4-35.2,14-38.1c0.3,0,0.6,0,0.9,0c6.8,3,12,7.6,13.5,15.8C118,123.9,118,124.1,118,124.2z"/>
			<path class="st10" d="M212.9,130.3c-1.7-1.4-3.3-2.9-4.7-4.7C210,127,211.5,128.5,212.9,130.3z"/>
			<path class="st20" d="M334.3,108.9c8.3,9.2,5.8,31-3.3,37.2c-0.2,0-0.3,0-0.5,0c-7.5-2.6-12.9-7.2-14.4-15.8c0-1.9,0-3.7,0-5.6
				c2-9.1,7.3-15,17.7-15.8C334,108.9,334.1,108.9,334.3,108.9z"/>
			<path class="st21" d="M331,146.1c9-6.2,11.5-28,3.3-37.2c8-0.1,12.6,3.2,15.8,7.9c-7.4,3.5-12.9,18.2-6.5,27c0,0.2,0,0.3,0,0.5
				c-2,0.6-3.8,1.5-6,1.9c-1.9,0-3.7,0-5.6,0C331.7,146.1,331.4,146.1,331,146.1z"/>
			<path class="st2" d="M343.6,143.8c-6.4-8.8-0.9-23.5,6.5-27c0.2,0,0.3,0,0.5,0c0.8,2.8,2.5,4.6,2.8,7.9c0,1.9,0,3.7,0,5.6
				C352,136.6,349.1,141.5,343.6,143.8z"/>
			<path class="st5" d="M103.6,107.9c-14.3,2.9-22.8,23.4-14,38.1c-4.4-2.1-8-5-9.8-9.8c2.4-4.8,2.4-12.9,0-17.7
				C83,110.8,92.5,104.8,103.6,107.9z"/>
			<path class="st4" d="M154.3,129.3c-2.2-2.5-3.4-5.9-4.7-9.3c1.8,0.7,3.5,1.5,5.1,2.3c0,0.2,0,0.3,0,0.5
				C154.5,125,154,126.8,154.3,129.3z"/>
			<path class="st4" d="M237.1,67.5c4.7,5.9,15.3,9.6,25.6,7c0.2,0,0.3,0,0.5,0c5.6,5.9,10.1,12.9,11.2,23.3c0,2.5,0,5,0,7.4
				c0.3,1.8-0.8,2.3-0.5,4.2c0.1,0.4-0.2,0.4-0.5,0.5c-1.3,0.6-2.7,1-4.2,1.4c-0.2,0-0.3,0-0.5,0c-4.7-4.4-14.7-5.9-21.9-2.8
				c-13-5-19.2-27.3-10.2-40.9C236.8,67.5,236.9,67.5,237.1,67.5z"/>
			<path class="st6" d="M277.1,65.1c-1.4,1.7-2.9,3.3-4.7,4.7C273.8,68,275.3,66.5,277.1,65.1z"/>
			<path class="st15" d="M175.7,104.7c-2.7,1.6-6.2,2.5-9.8,3.3C168.2,105.9,172,105.3,175.7,104.7z"/>
			<path class="st15" d="M100.8,74.4C80.3,92.5,51.5,61,70.5,42.3c-7,13.1,1.2,28.8,15.4,29.3c0.3,0,0.6,0,0.9,0
				C90,74,95.1,74.5,100.8,74.4z"/>
			<path class="st16" d="M354.8,61.4c0.1,17.5-29.4,21.2-34.4,5.6C329.9,76,349.7,71.3,354.8,61.4z"/>
			<path class="st18" d="M50.1,52.6c-2.3,12.9-9.2,21.2-20.5,25.1C29.3,62.4,3.8,58.5-3,70.7c-4.9-4.2-8-10.3-9.3-18.1
				c0-2.8,0-5.6,0-8.4C-10.4,32.2-4,24.6,5.4,20C-8.3,33.1,18.1,46,21.7,31.2c1.8-7.3-2.5-11.9-8.8-13.5c0.8,0.1,1-0.6,1.9-0.5
				c2.8,0,5.6,0,8.4,0c15.2,2.8,24.2,11.8,27,27C50.1,47,50.1,49.8,50.1,52.6z"/>
			<path class="st20" d="M238.9,64.2c-0.2,1.5-1.8,1.6-1.9,3.3c-0.2,0-0.3,0-0.5,0c-0.6-1.4-2.1-1.9-2.8-3.3
				C235.5,64.2,237.2,64.2,238.9,64.2z"/>
			<path class="st2" d="M262.7,74.4c0-0.2,0-0.3,0-0.5c-6-5-12.7-9.3-23.3-9.8c6.2-11.1,30.6-13.7,40-3.7c-0.3,2-1.7,3-2.3,4.7
				c-1.7,1.4-3.3,2.9-4.7,4.7c-2.9,1.7-5.9,3.4-9.3,4.7C263,74.4,262.8,74.4,262.7,74.4z"/>
			<path class="st2" d="M86.8,71.7c15,0.1,23.3-16,16.3-29.3c9,6.7,6.8,26.8-1.9,32.1c-0.2,0-0.3,0-0.5,0C95.1,74.5,90,74,86.8,71.7
				z"/>
			<path class="st18" d="M-152.3,70.3c14.1-7.6,8.7-35.3-8.8-34.9c13.6-24.2,51-11.4,48.4,15.8C-114.4,67.7-135.8,82.1-152.3,70.3z"
				/>
			<path class="st20" d="M179.9,51.2c6.1,4.3,10.4,10.4,12.6,18.6c-1.1,0-1.4-0.8-2.8-0.5c-1.1-0.8-5.4-0.8-6.5,0
				c-5.9,0.5-10.4,2.3-14.9,4.2c-8.5-2.8-13.9-8.7-16.8-17.2c9.7,4.1,22.7,0.8,27.9-5.1C179.5,51.2,179.7,51.2,179.9,51.2z"/>
			<path class="st2" d="M354.8,61.4c-5.1,9.9-24.9,14.6-34.4,5.6c-0.4,0.1-0.4-0.2-0.5-0.5c-0.8-2-1.6-4-1.4-7
				c4.7,14.2,31.6,14.2,36.3,0c0,0.3,0,0.6,0,0.9C354.8,60.8,354.8,61.1,354.8,61.4z"/>
			<path class="st3" d="M101.2,40c0.3,1.1,1.5,1.2,1.9,2.3c7,13.4-1.3,29.4-16.3,29.3c-0.3,0-0.6,0-0.9,0
				c-9.5-4.8-17-17.4-12.6-31.6c0-0.2,0-0.3,0-0.5C79.8,33.3,95.1,34,101.2,40z"/>
			<path class="st22" d="M70.5,42.3c0.8-0.9,1.2-2.2,2.8-2.3c-4.5,14.3,3,26.9,12.6,31.6C71.7,71.2,63.5,55.4,70.5,42.3z"/>
			<path class="st4" d="M-163.5,40c-3.5,11.1,1,22.5,7.4,27.9c-5.8,3.5-13.9,1.5-17.7-2.3C-182.7,56.6-176.1,39.6-163.5,40z"/>
			<path class="st21" d="M318.5,59.6c-0.2,3,0.6,5,1.4,7c-4.3-3.3-7.2-8-8.4-14.4c0-2.5,0-5,0-7.4c2.2-10.8,8.7-17.3,19.5-19.5
				c2.5,0,5,0,7.4,0c10.8,2.2,17.3,8.7,19.5,19.5c0,2.5,0,5,0,7.4c-0.6,3.3-1.5,6.2-3.3,8.4c0-0.3,0-0.6,0-0.9c0-0.3,0-0.6,0-0.9
				c1.2-1.9,1.7-3.3,1.9-4.7c4.2-29.9-43.6-31-40-0.5c0.2,1.9,0.7,3.4,1.9,5.1C318.5,58.9,318.5,59.2,318.5,59.6z"/>
			<path class="st20" d="M318.5,58.6c-1.2-1.7-1.6-3.3-1.9-5.1c-3.6-30.5,44.2-29.4,40,0.5c-0.2,1.3-0.7,2.8-1.9,4.7
				C355.8,35.8,317.4,35.8,318.5,58.6z"/>
			<path class="st20" d="M279.4,35.8c-7.2,16.9-40.9,12.5-38.1-10.2c2.1-0.9,3.7-2.2,6-2.8c8.9-3.4,19.6,0,25.1,4.2
				c1.4,1.7,2.9,3.3,4.7,4.7c0.7,1.3,1.4,2.6,2.3,3.7C279.4,35.5,279.4,35.7,279.4,35.8z"/>
			<path class="st2" d="M158.5,45.6c-3.4,0.1-5.4,1.4-8.4,1.9c1.8-14.5,9.5-23,23.3-25.6c0.2,0,0.3,0,0.5,0
				c3.2,2.3,7.2,3.6,12.6,3.7c3.1,10.5-1.5,20.2-6.5,25.6c-0.2,0-0.3,0-0.5,0c0-0.2,0-0.3,0-0.5c-4.2-2.3-8.5-4.5-14.4-5.1
				C163.9,44.8,159.6,44.8,158.5,45.6z"/>
			<path class="st20" d="M101.2,40c-6.2-6-21.5-6.8-27.9-0.5c0.4-0.4,0.3-1.3,0.9-1.4C80.2,31.3,96.9,32.7,101.2,40z"/>
			<path class="st16" d="M350.6,116.8c0.2,0,0.3,0,0.5,0c12.5-8,28.1,2.5,29.8,14c3.1,22.2-27.1,31.7-37.2,13.5c0-0.2,0-0.3,0-0.5
				c5.5-2.2,8.4-7.1,9.8-13.5c0.8-0.8,0.8-4.8,0-5.6C353.1,121.4,351.4,119.6,350.6,116.8z"/>
			<path class="st2" d="M171,143.8c0.1-0.4-0.2-0.4-0.5-0.5c-2.7-9.4-7.7-16.5-15.8-20.5c0-0.2,0-0.3,0-0.5
				c1.8-6.4,5.5-10.9,10.2-14.4c0.3,0,0.6,0,0.9,0c3.6-0.7,7-1.7,9.8-3.3c0.3,0,0.6,0,0.9,0c23.5-2.1,30.4,32,13,41.9
				c-0.2,0-0.3,0-0.5,0c-2,0-4,0-6,0C178.2,146.5,174.9,144.8,171,143.8z"/>
			<path class="st21" d="M238.5,139.1c17.3-1.9,28-10.5,33.5-24.2c2.4,2.1,3.5,5.5,4.2,9.3c0,2.2,0,4.3,0,6.5
				c-1.9,9.6-7.6,15.4-17.2,17.2c-2.2,0-4.3,0-6.5,0C245.9,146.9,241.5,143.6,238.5,139.1z"/>
			<path class="st17" d="M238.5,139.1c-9.3-0.1-16.5-2.4-21.9-6.5c4-5.9,7.5-12.4,8.4-21.4c0.8-1.1,0.8-5.4,0-6.5
				c-1.6-13-7.3-21.8-15.8-27.9c0-0.2,0-0.3,0-0.5c6-6.2,13.2-11.3,24.7-12.1c0.6,1.4,2.1,1.9,2.8,3.3c-9,13.6-2.8,36,10.2,40.9
				c0,0.2,0,0.3,0,0.5C236.3,111.8,231.1,129.2,238.5,139.1z"/>
			<path class="st23" d="M48.2,139.6c-0.7-0.1-0.8-0.8-1.4-0.9c-4.4-5.7-4.4-19.1,1.4-23.3c1.3,2.4,2,5.5,2.3,8.8c0,2.2,0,4.3,0,6.5
				C50.2,134.1,49.5,137.2,48.2,139.6z"/>
			<path class="st24" d="M276.1,130.7c0-2.2,0-4.3,0-6.5C276.9,125.3,276.9,129.6,276.1,130.7z"/>
			<path class="st24" d="M-115.6,130.7c0-2.5,0-5,0-7.4C-114.8,124.7-114.8,129.3-115.6,130.7z"/>
			<path class="st25" d="M-12.7,130.7c-0.8-1.1-0.8-5.4,0-6.5C-12.7,126.4-12.7,128.6-12.7,130.7z"/>
			<path class="st15" d="M131.9,118.2c-5.3,1.2-10,3.1-14,5.6c-1.5-8.2-6.7-12.8-13.5-15.8C116.9,104.6,127.2,110.8,131.9,118.2z"/>
			<path class="st16" d="M273.8,109.3c-0.4-1.9,0.7-2.4,0.5-4.2c0.8-1.4,0.8-6,0-7.4c-1.1-10.4-5.5-17.4-11.2-23.3
				c3.4-1.3,6.4-2.9,9.3-4.7c1.7-1.4,3.3-2.9,4.7-4.7c0.6-1.7,2-2.6,2.3-4.7c0.2,0,0.3,0,0.5,0c4.8,5.1,9.6,10.2,10.2,19.5
				c0,2.2,0,4.3,0,6.5C288.7,98.2,282.7,105.1,273.8,109.3z"/>
			<path class="st2" d="M500.8,105.6c-0.2-1.5,0.8-1.6,0.5-3.3c0.2,0,0.3,0,0.5,0c3.7,1.2,6.5,3.4,9.3,5.6c0,0.2,0,0.3,0,0.5
				C507.2,108,503.4,107.4,500.8,105.6z"/>
			<path class="st17" d="M473.4,108.4c-6.1-11.3,0.6-26,14.9-24.2c0.3,1-0.6,3.1,0.5,3.3c0.3,5.8,2.9,9.2,5.1,13
				c-9,0.3-15.6,3-20,7.9C473.7,108.4,473.5,108.4,473.4,108.4z"/>
			<path class="st16" d="M501.8,102.4c0.6-11-4.8-16-13-18.1c-0.3-3.3,0.7-5.2,1.4-7.4c0.4,0.1,0.4-0.2,0.5-0.5
				c12,1.1,20.5-5.2,25.6-12.6c9.7,2.6,15.8,8.7,17.2,19.5c0,1.5,0,3.1,0,4.6c-2,12.1-8.7,19.5-22.3,20
				C508.3,105.8,505.5,103.6,501.8,102.4z"/>
			<path class="st18" d="M-62.5,84.2c-5.3-0.4-9.7-1.8-13.5-3.7c0.3-9.6-4.6-14.1-14.4-13.5c-2.8-4.2-4.6-9.4-5.1-15.8
				c0-1.9,0-3.7,0-5.6c-0.1-2.5,0.8-3.8,0.9-6C-81.7,49.9-68.8,26.9-82.5,21c3.7-4.1,9.4-6.2,15.4-7.9c0.2,0,0.3,0,0.5,0
				c2,1.2,5.8,1,7.4-0.5c0.3,0,0.6,0,0.9,0c20.5,1.9,31.6,13.4,34.4,33c0,1.9,0,3.7,0,5.6c-2.6,19.4-13.7,30.4-33,33
				C-58.8,84.2-60.7,84.2-62.5,84.2z"/>
			<path class="st2" d="M490.6,76.3c4.1-8.8,11.4-14.3,25.6-13c0,0.2,0,0.3,0,0.5C511.1,71.1,502.6,77.4,490.6,76.3z"/>
			<path class="st18" d="M424.6,73c-0.8-4.3-3.1-7.1-6.1-9.3c10.9-3.2,18.4-9.8,21.4-20.9C443.1,57.7,434.9,68.6,424.6,73z"/>
			<path class="st20" d="M424.6,73c-7.4,4.2-19.6,2-25.6-1.9c0-0.2,0-0.3,0-0.5c1.3-2.9,3.2-5.2,5.6-7c0.3,0,0.6,0,0.9,0
				c1.1-0.2,1,0.8,2.3,0.5c1.4,0.8,6,0.8,7.4,0c1.3,0.4,1.2-0.7,2.3-0.5c0.3,0,0.6,0,0.9,0C421.4,65.9,423.7,68.7,424.6,73z"/>
			<path class="st2" d="M-3,70.7c6.8-12.2,32.3-8.3,32.6,7c0,0.2,0,0.3,0,0.5c-12.1,4.1-26-0.1-32.6-7C-3,71-3,70.9-3,70.7z"/>
			<path class="st18" d="M192.4,69.8c-2.1-8.2-6.4-14.3-12.6-18.6c5-5.4,9.6-15.1,6.5-25.6c1.1-0.3,3.4,0.6,3.7-0.5
				c0.2,0,0.3,0,0.5,0c6.7,4.2,11.6,10.1,13,19.5c0,2.5,0,5,0,7.4c-1.2,8.1-5.1,13.5-10.2,17.7c-0.5,0-0.5,0.4-0.9,0.5
				C192.4,70.1,192.4,69.9,192.4,69.8z"/>
			<path class="st20" d="M-76,80.5c-6.1-3.2-11-7.6-14.4-13.5C-80.6,66.4-75.7,70.9-76,80.5z"/>
			<path class="st2" d="M418.5,63.7c-0.3,0-0.6,0-0.9,0c0-0.2,0-0.3,0-0.5c-3.1-1.7-9.6-2.1-12.1,0.5c-0.3,0-0.6,0-0.9,0
				c-7.6-1.9-12.7-6.2-17.2-11.2c0.1-0.8-0.6-1-0.5-1.9c-0.5-8.7,2.5-13.9,5.6-19.1c1.7-1.4,3.3-2.9,4.7-4.7
				c7.7-7.1,25.8-7.1,33.5,0c1.4,1.7,2.9,3.3,4.7,4.7c1.7,3.2,3.8,6.1,4.7,10.2c0,0.3,0,0.6,0,0.9C436.9,53.9,429.4,60.6,418.5,63.7
				z"/>
			<path class="st24" d="M520.4,52.1c0-2.5,0-5,0-7.4C521.2,46.1,521.2,50.7,520.4,52.1z"/>
			<path class="st24" d="M464.6,52.1c-0.8-1.4-0.8-6,0-7.4C464.6,47.2,464.6,49.6,464.6,52.1z"/>
			<path class="st24" d="M50.1,52.6c0-2.8,0-5.6,0-8.4C50.8,45.9,50.8,50.9,50.1,52.6z"/>
			<path class="st24" d="M-12.3,52.6c-0.8-1.7-0.8-6.7,0-8.4C-12.3,47-12.3,49.8-12.3,52.6z"/>
			<path class="st24" d="M358,52.1c0-2.5,0-5,0-7.4C358.8,46.1,358.8,50.7,358,52.1z"/>
			<path class="st24" d="M311.5,52.1c-0.8-1.4-0.8-6,0-7.4C311.5,47.2,311.5,49.6,311.5,52.1z"/>
			<path class="st24" d="M203.6,52.1c0-2.5,0-5,0-7.4C204.4,46.1,204.4,50.7,203.6,52.1z"/>
			<path class="st18" d="M-222.1,27c29.7-5.5,34.8,40,7,42.8c-12.9,1.3-24.7-6.3-24.2-24.2c11.7-0.1,17.5-6.1,17.2-18.1
				C-222.1,27.3-222.1,27.1-222.1,27z"/>
			<path class="st17" d="M-239.3,45.6c-22.8-0.7-16.6-38.4,7-31.2c5.2,1.6,8.2,6,10.2,12.6c0,0.2,0,0.3,0,0.5
				C-231.9,29.4-237.3,35.8-239.3,45.6z"/>
			<path class="st16" d="M-94.6,39.1c-10-6.6,0.1-25,11.6-18.1c-5.1,4.7-9.6,9.9-11.2,18.1C-94.3,39.1-94.5,39.1-94.6,39.1z"/>
			<path class="st16" d="M163.1,7c1.6,6.7,5.4,11.3,10.2,14.9C159.6,24.4,151.9,33,150,47.5c0,0.2,0,0.3,0,0.5c-2.6,0.3-3.9,2-6,2.8
				C127.1,36.2,139.1,5.1,163.1,7z"/>
			<path class="st16" d="M416.2,5.6c2.9,9.4,9.2,15.3,21.4,15.4c1.4,3.4,3.4,6.3,3.3,11.2c0,1.9,0,3.7,0,5.6c0.1,1.7-0.1,3-0.5,4.2
				c-0.1,0-0.3,0-0.5,0c-0.8-4.2-2.9-7-4.7-10.2c-1.4-1.7-2.9-3.3-4.7-4.7c-7.7-7.1-25.8-7.1-33.5,0c-1.7,1.4-3.3,2.9-4.7,4.7
				c-3.1,5.2-6.1,10.4-5.6,19.1c-2.5-3.4-4.4-7.3-4.7-13c0-1.9,0-3.7,0-5.6c2.2-15.5,11.1-24.3,26.5-26.5c1.9,0,3.7,0,5.6,0
				c0.5,0,0.9,0,1.4,0C415.9,5.6,416,5.6,416.2,5.6z"/>
			<path class="st17" d="M330.6,146.1c-4.1,3.6-8.8,6.7-16.3,7c-1.6,0-3.1,0-4.7,0c-3.8,0.3-5.9-1.2-8.8-1.9c0-0.3-0.1-0.6-0.5-0.5
				c-1.9-3.9-5.2-6.6-9.8-7.9c-0.5,0-0.6-0.4-0.9-0.5c-2.3-4.2-5.1-7.9-5.1-14.4c0-1.5,0-3.1,0-4.7c1.9-14.9,10.2-23.3,25.1-25.1
				c1.6,0,3.1,0,4.7,0c9.4,0.7,15.3,4.8,19.5,10.7c-10.4,0.8-15.6,6.7-17.7,15.8c-0.8,0.8-0.8,4.8,0,5.6
				C317.6,138.9,323.1,143.5,330.6,146.1z"/>
			<path class="st26" d="M79.4,136.3c-2.8,8.7-16.8,13.2-26.5,7.9c-1.4-1.5-2.5-3.4-4.7-4.2c0-0.2,0-0.3,0-0.5
				c1.3-2.4,2-5.5,2.3-8.8c0.8-1.1,0.8-5.4,0-6.5c-0.4-3.4-1-6.4-2.3-8.8c0-0.3,0-0.6,0-0.9c7-9.2,28.1-7.1,31.2,4.2
				C77.1,123.6,77.1,131.4,79.4,136.3z"/>
			<path class="st17" d="M273.4,109.8c0.1,2.1-0.4,3.7-1.4,4.6c-0.8-1.2-2-2-2.8-3.3C270.7,110.8,272.1,110.4,273.4,109.8z"/>
			<path class="st17" d="M-76,80.5c-2.8,17.1-28.2,9.6-22.8-6.5c0.9-2.6,4.1-5.5,8.4-7C-87,72.9-82.1,77.2-76,80.5z"/>
			<path class="st17" d="M424.6,73c2.9,20.7-31.5,18.5-25.6-1.9C405,75,417.1,77.2,424.6,73z"/>
			<path class="st17" d="M-152.8,70.3c-6.7,5.9-18.6,3.1-23.7-1.9c-11.9-11.4-4.4-34.9,15.4-33c-1,1.3-1.4,3.2-2.3,4.7
				c-12.7-0.4-19.2,16.6-10.2,25.6c3.8,3.8,11.9,5.9,17.7,2.3c0.2,0,0.3,0,0.5,0C-155.1,69.2-153.7,69.5-152.8,70.3z"/>
			<path class="st21" d="M399,70.7c-5.9-4.1-10.4-9.5-11.6-18.1c4.5,5,9.6,9.3,17.2,11.2C402.1,65.5,400.2,67.8,399,70.7z"/>
			<path class="st18" d="M233.8,64.2c-9.5-11.3-4-33.1,7-38.6c0.2,0,0.3,0,0.5,0c-2.7,22.7,31,27.1,38.1,10.2c0.2,0,0.3,0,0.5,0
				c3.3,6.6,3.7,18.1,0,24.7c-0.2,0-0.3,0-0.5,0c-9.4-10-33.9-7.4-40,3.7c-0.2,0-0.3,0-0.5,0C237.2,64.2,235.5,64.2,233.8,64.2z"/>
			<path class="st27" d="M154.7,122.4c-1.6-0.8-3.3-1.7-5.1-2.3c0-0.3-0.1-0.5-0.5-0.5c-1-4.2-1.8-8.8-1.4-14.4
				c3.7,0.8,6.4,2.6,10.7,2.8c1,0.8,5.1,0.8,6.1,0c0.2,0,0.3,0,0.5,0C160.3,111.5,156.6,116,154.7,122.4z"/>
			<path class="st20" d="M272,114.9c-5.5,13.7-16.2,22.3-33.5,24.2c-7.3-10-2.2-27.3,8.4-30.2c5.5,2.7,14.8,5.2,21.9,2.3
				c0.2,0,0.3,0,0.5,0c0.8,1.2,2,2.1,2.8,3.3C272,114.6,272,114.8,272,114.9z"/>
			<path class="st23" d="M79.8,118.6c2.4,4.8,2.4,12.9,0,17.7c-0.2,0-0.3,0-0.5,0c-2.3-4.9-2.3-12.7,0-17.7
				C79.5,118.6,79.7,118.6,79.8,118.6z"/>
			<path class="st20" d="M-152.3,70.3c-0.2,0-0.3,0-0.5,0c-0.9-0.8-2.3-1.1-2.8-2.3c12.2-5.6,8.3-29.7-7.9-27.9
				c0.9-1.4,1.3-3.4,2.3-4.7C-143.6,34.9-138.2,62.7-152.3,70.3z"/>
			<path class="st20" d="M-222.1,27.5c0.3,12-5.5,18.1-17.2,18.1C-237.3,35.8-231.9,29.4-222.1,27.5z"/>
			<path class="st28" d="M290.1,86.5c0-2.2,0-4.3,0-6.5C290.9,81.1,290.9,85.4,290.1,86.5z"/>
			<path class="st29" d="M165.9,107.9c-0.3,0-0.6,0-0.9,0c-0.2,0-0.3,0-0.5,0c-2,0-4,0-6.1,0c-4.3-0.2-7-2-10.7-2.8
				c0-0.2,0-0.3,0-0.5c2-15.1,9.2-24.9,20.5-30.7c8.6,2.6,18.2,0.5,24.2-3.3c0,1.1,0.8,1.4,0.5,2.8c0,2.2,0,4.3,0,6.5
				c-1.5,12.1-8,19.3-16.3,24.7c-0.3,0-0.6,0-0.9,0C172,105.3,168.2,105.9,165.9,107.9z"/>
			<path class="st4" d="M501.3,102.4c0-0.2,0-0.3,0-0.5c-2.6,0.1-3.7-1.3-6.5-0.9c-0.4-0.1-0.5-0.5-0.9-0.5c-2.3-3.8-4.9-7.2-5.1-13
				c0-1.1,0-2.2,0-3.3c8.2,2.1,13.6,7.2,13,18.1C501.6,102.4,501.5,102.4,501.3,102.4z"/>
			<path class="st4" d="M151.5,55.8c-3.1-0.9-5.1-2.9-7.4-4.7c0-0.2,0-0.3,0-0.5c2.1-0.8,3.4-2.5,6-2.8
				C150.5,50.6,150.5,53.8,151.5,55.8z"/>
			<path class="st2" d="M-55.5,94.9c2.9,3.4,4.7,5,5.1,8.8c1.9,17-24.2,20.5-26.1,3.7c-0.5-4.5,0.6-5.9,2.3-9.8
				c0.4-0.1,0.5-0.5,0.9-0.5c4.5-1.7,10.8-3.6,16.7-2.3C-56.2,94.9-55.9,94.9-55.5,94.9z"/>
			<path class="st2" d="M22.6,95.8c-2.6,3.7-11.3,4.8-16.7,2.8c2.6-1.6,6-2.4,9.8-2.8c2.2,0,4.3,0,6.5,0
				C22.3,95.8,22.5,95.8,22.6,95.8z"/>
			<path class="st2" d="M-82.5,20.9c13.7,5.9,0.9,28.9-12.1,18.6c0-0.2,0-0.3,0-0.5c0.2,0,0.3,0,0.5,0c1.6-8.2,6.1-13.4,11.2-18.1
				C-82.8,20.9-82.7,20.9-82.5,20.9z"/>
		</g>
	</g>
</g>
</svg>
`;

export var tutorialOverlay = (screen: ScreenType, pos: any, width: any, height: any) => {
	
  let maskPos = JSON.parse(JSON.stringify(pos));
  // Convert from rem to px
  if (pos.left) maskPos.left = remToPx(maskPos.left.replace("rem", ""));
  if (pos.right) maskPos.right = remToPx(maskPos.right.replace("rem", ""));
  if (pos.top) maskPos.top = remToPx(maskPos.top.replace("rem", ""));
  if (pos.bottom) maskPos.bottom = remToPx(maskPos.bottom.replace("rem", ""));
  let maskWidth = remToPx(width.replace("rem", ""));
  let maskHeight = remToPx(height.replace("rem", ""));
  let x = pos.left ? maskPos.left : screen.width - (maskWidth + maskPos.right);
  let y = pos.top ? maskPos.top : `calc(${screen.height} - ${maskHeight + maskPos.bottom})`;
  let svg = `
        <svg xmlns="http://www.w3.org/2000/svg" width="${screen.width}" height="${screen.height}">
        <defs>
            <style>
                .main{font-size: "16px"; fill:rgba(0,0,0,0.8);}
            </style>
            <mask id="mask">
                <rect width="100%" height="100%" fill="#fff"/>
                <rect x="${x}" y="${y}" width="${maskWidth}" height="${maskHeight}" />
            </mask>
        </defs>
        <rect class="main" width="100%" height="100%" mask="url(#mask)" />
        </svg>
    `;
  return svg;
};
