import { useMemo, useState } from "react";
import { scaleLinear } from "@visx/scale";
import Tooltip from "../Tooltip/Tooltip";
import { clamp } from "curve-interpolator";
import { pxToRem } from "../../../styles/theme";
import { DataPieceType, SA2DataPieceType } from "../../../types";
import { useAppSelector } from "../../../redux/types";
import { CancerAtlasState } from "../../../redux/features/cancerAtlasSlice";
import { HTMLVisualisationState } from "../../../redux/features/htmlVisualisationSlice";
import { GlobeState } from "../../../redux/features/globeSlice";
import { color, x, y, extractName } from "../utils";
import { SRootDiv } from "./emotion";
import { SCATTERPLOT_CIRCLE_SIZE } from "../../../constants";

interface ScatterplotProps {
  data?: Array<DataPieceType> | null;
  sa2Data: CancerAtlasState["sa2Data"];
  selectedSA2s: HTMLVisualisationState["sa2s"];
  activeSA2s: GlobeState["activeSA2s"];
  loadingDensityData?: boolean;
  flyToActive: GlobeState["flyToActive"];
  callFlyToSA2?: Function;
}

const Scatterplot = ({ data = null, sa2Data, selectedSA2s = [], activeSA2s, loadingDensityData, flyToActive, callFlyToSA2 }: ScatterplotProps) => {
  const [tooltipLeft, setTooltipLeft] = useState<number>(0);
  const [tooltipTop, setTooltipTop] = useState<number>(0);
  const [tooltipData, setTooltipData] = useState<string>("");
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const { screen } = useAppSelector((state) => state.ui);
  const [width] = useState<number>(screen.width > 320 ? 18.75 : pxToRem(320 - 52));
  const [height] = useState<number>(3.75);

  const xScale = scaleLinear<any>({
    domain: [-2, 2],
    range: [0, width],
    clamp: true,
  });
  const yScale = scaleLinear<any>({
    domain: [0, 1],
    range: [height - 0.25, 0],
    clamp: true,
  });

  const renderCircles = () => {
    return data?.map((d: DataPieceType, i: number) => {
      return (
        <circle
          style={{ cursor: "pointer !important" }}
          key={`vpoint-${d.postcode}`}
          fill={color(activeSA2s, d)}
          cx={xScale(x(d)) + "em"}
          cy={yScale(y(d)) + 0.125 + "em"}
          r={SCATTERPLOT_CIRCLE_SIZE}
          onClick={() => {
            if (!loadingDensityData && !flyToActive) {
              if (callFlyToSA2) callFlyToSA2(d.postcode);
            }
          }}
          onMouseOver={() => {
            setTooltipLeft(xScale(x(d)));
            setTooltipTop(yScale(y(d)) + 0.125);
            setTooltipData(
              extractName(
                d,
                sa2Data?.find((x: any) => x.areacode === d.postcode)
              )
            );
            setTooltipVisible(true);
          }}
          onMouseLeave={() => {
            setTooltipVisible(false);
          }}
        />
      );
    });
  };

  let circles = useMemo(() => renderCircles(), [data, sa2Data, activeSA2s]);

  return (
    <SRootDiv>
      <svg width={width + "em"} height={height + "em"}>
        <g>{circles}</g>
      </svg>
      {/* Only renders 2 max so no need for memo */}
      {selectedSA2s.map((obj: any) => {
        return (
          <Tooltip
            key={`tooltip_${obj.cancer_grp}_${obj.postcode}`}
            visible={true}
            color={obj.colorHex}
            textColor={xScale(x(obj)) > 7.3 && xScale(x(obj)) < 11.25 ? "#303030" : "#dedede"}
            type={"active"}
            left={xScale(x(obj))}
            top={yScale(y(obj))}
            reverse={yScale(y(obj)) > height - 1.2}
            data={extractName(
              obj,
              sa2Data?.find((x: any) => x.areacode == obj.postcode)
            )}
          />
        );
      })}
      <Tooltip key={"tooltip"} visible={tooltipVisible} left={tooltipLeft} top={tooltipTop} data={tooltipData} />
    </SRootDiv>
  );
};

export default Scatterplot;
