import { styled } from "@mui/system";

export const SRootDiv = styled("div")(({ theme }: { theme: any }) => ({
  ...theme.layers.overlayMenu,
  position: "absolute",
  backgroundColor: theme.palette.background.overlay,
  width: "100%",
  height: "100%",
}));

export const SContentDiv = styled("div")(({ theme }: { theme: any }) => ({
  position: "absolute",
  width: "25%",
  height: "auto",
  [theme.breakpoints.down("lg")]: {
    width: "50%",
  },
  top: "50%",
  left: "50%",
  transform: "translateY(-50%) translateX(-50%)",
  textAlign: "center",
}));

export const SImg = styled("img")(({ theme }: { theme: any }) => ({
  width: "15rem",
  height: "15rem",
  margin: "0 auto",
  [theme.breakpoints.down("xs")]: {
    width: "80%",
    height: "80%",
  },
}));
