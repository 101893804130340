import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/system";

export const SGrow = styled(Grow)(({ theme }: { theme: any }) => ({
  position: "absolute",
  ...theme.layers.draggable,
  ...theme.components.border,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  width: "20rem",
  height: "auto",
}));

export const SPaper = styled(Paper)(({ theme }: { theme: any }) => ({
  position: "absolute",
  ...theme.layers.draggable,
  ...theme.components.border,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  width: "20rem",
  height: "auto",

  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));
