import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { css } from "@emotion/css";
import { SButtonsDiv, S_H2, SFilterButton } from "./emotion";

interface PCSelectDisplayProps {
  pc: number;
  handleSelect: Function;
  RenderHeading: any;
}

const PCSelectDisplay = (props: PCSelectDisplayProps) => {
  const { pc, handleSelect, RenderHeading } = props;
  const theme: any = useTheme();
  const { t } = useTranslation();
  const TOOLTIP_STYLE = {
    tooltip: css(theme.components.tooltip.tooltip),
    tooltipPlacementTop: css(theme.components.tooltip.tooltipPlacementTop),
    tooltipPlacementBottom: css(theme.components.tooltip.tooltipPlacementBottom),
  };

  const handleClick = (event: any) => {
    let newValue = parseInt(event.target.value);
    handleSelect(newValue);
  };

  return (
    <>
      <RenderHeading children={<S_H2>{t("filters:pc.title")}</S_H2>}></RenderHeading>
      <SButtonsDiv>
        <Tooltip classes={TOOLTIP_STYLE} id={`indicator_selectMales_tooltip`} title={t("filter_by", { filter: t("filters:pc.values.3") })} placement="top" arrow disableFocusListener>
          <SFilterButton aria-label={t("filters:pc.values.3")} variant="contained" value={3} name={"PC3"} onClick={handleClick} color={pc === 3 ? "primary" : "secondary"}>
            {t("filters:pc.values.3")}
          </SFilterButton>
        </Tooltip>
        <Tooltip classes={TOOLTIP_STYLE} id={`indicator_selectFemales_tooltip`} title={t("filter_by", { filter: t("filters:pc.values.4") })} placement="top" arrow disableFocusListener>
          <SFilterButton
            aria-label={t("filters:pc.values.4")}
            variant="contained"
            value={4}
            name="PC4"
            // Send view mode index to view mode change function
            onClick={handleClick}
            // Use color and mode state value to determine active state
            color={props.pc === 4 ? "primary" : "secondary"}
          >
            {t("filters:pc.values.4")}
          </SFilterButton>
        </Tooltip>
      </SButtonsDiv>
    </>
  );
};

export default PCSelectDisplay;
