import { Typography } from "@mui/material";
import { styled } from "@mui/system";

export const SPC4Title = styled(Typography)(() => ({
  display: "inline-block",
  fontWeight: 500,
  fontSize: "1rem",
  color: "#FFF",
  paddingRight: "0.5rem",
}));

export const SPC4List = styled("span")(() => ({
  fontSize: "1rem",
  color: "#FFF",
}));

export const SPC4Div = styled("div")(() => ({
  textAlign: "left",
  margin: "0 .5rem",
}));
