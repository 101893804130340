import { useEffect, Suspense, useState, lazy, useRef, useCallback } from "react";
import { BrowserRouter, Route, Link, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@mui/material/styles";
import Skeleton from "@mui/material/Skeleton";
import theme from "../../styles/theme";
import * as CancerAtlasActionsThunk from "../../redux/features/cancerAtlasSlice/thunk";
import LandingOverlay from "../Overlays/Landing";
import NoDataOverlay from "../Overlays/NoData";
import LoadingOverlay from "../Overlays/Loading";
import ErrorBoundary from "../ErrorBoundary";
import Mapbox from "../Mapbox";
import HTMLVisualisations from "../HTMLVisualisations";
import ScatterplotDialog from "../charting/ScatterplotDialog";
import PassiveLoader from "../PassiveLoader";
import MediaQuery from "react-responsive";
import Stories from "../Stories";
import Tutorial from "../Tutorial";
import OptionsMenu from "../OptionsMenu";
import StoriesMenu from "../Stories/StoriesMenu";
import ShareIcon from "@mui/icons-material/Share";
import { useAppDispatch, useAppSelector } from "../../redux/types";
import { useWindowResizeHook } from "./hooks/useWindowResizeHook";
import { usePopulateFromStateHook } from "./hooks/usePopulateFromStateHook";
import Navigation from "../Navigation";
import {
  SAtlas,
  SCancerSelect,
  SENWindowButton,
  SIndicators,
  SLogoLink,
  SMobileContainerMobileViewer,
  SMobileSearchSelectDiv,
  SNavigation,
  SNLWindowButton,
  SPCSelect,
  SRoot,
  SSearchBar,
  SStoriesIcon,
  STutorialIcon,
  SWindowButtonDiv,
  SWindowButton,
  SButtonsDiv,
} from "./emotion";
import WindowButton from "../WindowButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import ReactCountryFlag from "react-country-flag";
import { changeLanguageCookie, getCountryCode } from "../../helpers/LanguageHelper";

const Layout = () => {
  const { i18n, t } = useTranslation();
  const { isMobile, indicators, screen } = useAppSelector((state) => state.ui);
  const loadingMap = useAppSelector((state) => state.globe.loadingStatus);
  const storyOpen = useAppSelector((state) => state.ui.stories.visible);
  let storyData = useAppSelector((state) => state.cancerAtlas.stories);
  const storyLoading = useAppSelector((state) => state.cancerAtlas.loadingStories);
  const loadingCancerData = useAppSelector((state) => state.cancerAtlas.loadingCancerData);
  const loadingSA2s = useAppSelector((state) => state.cancerAtlas.loadingSA2s);
  const isScatterOpen = useAppSelector((state) => state.ui.scatterplotFullscreenVisible);
  const [numIndicators, setNumIndicators] = useState<number>(indicators[0].view);
  const [defStoryLink, setDefStoryLink] = useState<string>("/");
  const dispatch = useAppDispatch();
  const windowResizeHook = useWindowResizeHook();
  const populateFromStateHook = usePopulateFromStateHook();

  useEffect(() => {
    setNumIndicators(indicators[0].view);
  }, [indicators[0].view]);

  // initial story data load
  useEffect(() => {
    storyData.length === 0 && !storyLoading && dispatch(CancerAtlasActionsThunk.requestStoryData({ locale: i18n.language }));
    if (storyData.length && !isMobile) setDefStoryLink(`/tour/${storyData[0].slug}`);
  }, [storyData]);

  const MapLoader = () => {
    return <PassiveLoader visible={loadingMap < 100 || loadingSA2s || loadingCancerData ? true : false} message={t("loading_map")} />;
  };

  const countryCode = (): string => {
    return getCountryCode(i18n.language);
  };

  const changeLanguage = () => {
    changeLanguageCookie(i18n.language);
  };

  const needToHide = useMediaQuery("(max-width:975px)");
  const logoBreakpoint = useMediaQuery("(max-width:550px)");
  const logoSizeBreakpoint = useMediaQuery("(max-width:391px)");

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<Skeleton width="100%" height="100%" />}>
          <SRoot key="root" className={isMobile ? "mobile" : ""}>
            <LandingOverlay loading={loadingMap < 100 ? true : false} />
            <Routes>
              <Route path="/tour/:story" element={<Stories />} />
            </Routes>
            <SAtlas className={"atlasclasses"}>
              <NoDataOverlay />
              <LoadingOverlay />
              <OptionsMenu />
              {/* Mobile */}
              {isMobile && (
                <Suspense key="movileFallback" fallback={<Skeleton width="100%" height="100%" />}>
                  <SLogoLink key="desktop_btnIKNL" href={"https://iknl.nl/kankeratlas"} target="_blank" style={{ zIndex: 70 }}>
                    <img src={`/assets/${logoBreakpoint ? "iknl_logo_no_text.svg" : "iknl_logo.svg"}`} style={{ height: `${logoSizeBreakpoint ? 30 : 42}px` }} />
                  </SLogoLink>
                  <SMobileContainerMobileViewer key="mobileViewer" defStoryLink={defStoryLink} />
                  <StoriesMenu key="storiesMenu" />
                  <SMobileSearchSelectDiv key="mobileSearchSelect">
                    <SSearchBar key="mobile_searchBar" className={"mobile"} />
                    <SCancerSelect isMobile={true} />
                  </SMobileSearchSelectDiv>
                  <MapLoader key="mobile_mapLoader" />
                </Suspense>
              )}
              {/* Desktop */}
              {!isMobile && (
                <Suspense key="movileFallback" fallback={<Skeleton width="100%" height="100%" />}>
                  {[
                    (!needToHide || (needToHide && !storyOpen)) && <SSearchBar key="desktop_searchBar" className={"cubeClass"} />,
                    <SCancerSelect key="desktop_cancerSelect" isMobile={false} />,
                    <HTMLVisualisations key="desktop_popups" />,
                    isScatterOpen && <ScatterplotDialog></ScatterplotDialog>,
                    <MapLoader key="desktop_mapLoader" />,
                    <SIndicators key={"desktop_indicators"} />,
                    (!needToHide || (needToHide && !storyOpen)) && (
                      <SLogoLink key="desktop_btnIKNL" href={"https://iknl.nl/kankeratlas"} target="_blank">
                        <img src="/assets/iknl_logo.svg" height={42} />
                      </SLogoLink>
                    ),
                    (!needToHide || (needToHide && !storyOpen)) && (
                      <SWindowButtonDiv>
                        {storyData.length > 0 && (
                          <Link key="desktop_btnStories" to={storyOpen ? `/` : defStoryLink}>
                            <SWindowButton buttonText={t(`tour.tourBtn`)} visible={storyOpen} windowName="stories" icon={<SStoriesIcon />} label={t(`tour.tooltip`)} />
                          </Link>
                        )}
                        <SWindowButton buttonText={t(`tutorial.tutorialBtn`)} windowName="tutorial" icon={<STutorialIcon />} label={t(`tutorial.tooltip`)} />
                        <SWindowButton windowName="options" icon={<ShareIcon />} label={t(`share.shareBtn`)} />
                        <SWindowButton windowName="language" label={t("language.languageBtn")} clickOverride={changeLanguage} icon={<ReactCountryFlag countryCode={countryCode()} svg />} />
                      </SWindowButtonDiv>
                    ),
                    needToHide && storyOpen && (
                      <SButtonsDiv>
                        {storyData.length > 0 && (
                          <Link key="desktop_btnStories" to={storyOpen ? `/` : defStoryLink}>
                            <SWindowButton needMarginTop={true} buttonText={t(`tour.tourBtn`)} visible={storyOpen} windowName="stories" icon={<SStoriesIcon />} label={t(`tour.tooltip`)} />
                          </Link>
                        )}
                        <SWindowButton needMarginTop={true} buttonText={t(`tutorial.tutorialBtn`)} windowName="tutorial" icon={<STutorialIcon />} label={t(`tutorial.tooltip`)} />
                        <SWindowButton needMarginTop={true} windowName="language" label={t("language.languageBtn")} clickOverride={changeLanguage} icon={<ReactCountryFlag countryCode={countryCode()} svg />} />
                      </SButtonsDiv>
                    ),
                    <SPCSelect numIndicators={numIndicators} />,
                    <MediaQuery key="desktop_noPrint" query={"not print"}>
                      <Tutorial />
                      {(!needToHide || (needToHide && !storyOpen)) && <SNavigation />}
                    </MediaQuery>,
                  ]}
                </Suspense>
              )}
              <ErrorBoundary name={"maps"} message={"Error connecting to data. Please click to refresh"}>
                <Mapbox />
              </ErrorBoundary>
            </SAtlas>
          </SRoot>
        </Suspense>
      </ThemeProvider>
    </BrowserRouter>
  );
};
export default Layout;
