import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import Popup from "../Popup";
import Scrollbar from "../Scrollbar";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { remToPx } from "../../styles/theme";
import { tutorial } from "../../assets/TutorialData";
import { tutorialOverlay } from "../../assets/svgs";
import * as UIActions from "../../redux/features/uiSlice";
import * as GlobeActions from "../../redux/features/globeSlice";
import * as HTMLVisualisationActions from "../../redux/features/htmlVisualisationSlice";
import * as FilterActionsThunk from "../../redux/features/filterSlice/thunk";
import * as URIActions from "../../redux/features/uriSlice";
import * as URIActionsThunk from "../../redux/features/uriSlice/thunk";
import { useAppDispatch, useAppSelector } from "../../redux/types";
import { SCloseButtonIconButton, SContentDiv, SDescriptionTypography, SHeaderDiv, SNextIcon, SOverlayImg, SPrevIcon, SRootPaper, SSeparatorHr, SStepperDiv, STitleTypography } from "./emotion";
import { VARIANTS } from "../Scrollbar/Scrollbar";
import { useMediaQuery } from "@mui/material";
import { SEARCHBAR_BREAKPOINT } from "../SearchBar/SearchBar";
const COMPONENT_NAME = "tutorial";

const Tutorial = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { visible, index } = useAppSelector((state) => state.ui[COMPONENT_NAME]);
  const { screen } = useAppSelector((state) => state.ui);
  const { urlVisible } = useAppSelector((state) => state.uri);
  const pos = tutorial[index].pos || { top: 0, left: 0 };
  const targetPos = tutorial[index].target.lang[i18n.language].pos;
  const targetWidth = tutorial[index].target.lang[i18n.language].width;
  const targetHeight = tutorial[index].target.lang[i18n.language].height;

  useEffect(() => {
    if (visible) {
      dispatch(UIActions.updateComponent({ name: "stories", data: { visible: false } }));
      // Capture current state
      dispatch(URIActions.setURLVisibility(true));
      return;
    } else {
      dispatch(URIActionsThunk.uriToState());
      dispatch(URIActions.setURLVisibility(false));
    }
  }, [visible]);

  const handleClose = () => {
    // Hide the component and reset index to 0
    dispatch(UIActions.updateComponent({ name: COMPONENT_NAME, data: { index: 0, visible: false } }));
  };

  const handleNext = () => {
    if (index < tutorial.length - 1) handleSelect(index + 1);
  };

  /* ========================================== */
  const handlePrev = () => {
    if (index > 0) handleSelect(index - 1);
  };

  const handleSelect = async (indexLocal: number) => {
    if (tutorial[indexLocal].ui) {
      dispatch(UIActions.applyStateUI(tutorial[indexLocal].ui));
    }
    if (tutorial[indexLocal].filters) {
      dispatch(FilterActionsThunk.applyState({ state: tutorial[indexLocal].filters }));
    }
    if (tutorial[index].popup) {
      dispatch(HTMLVisualisationActions.applyStateHTML(tutorial[indexLocal].popup));
    }
    if (tutorial[indexLocal].map) {
      dispatch(GlobeActions.updateMap({ center: tutorial[indexLocal].map.center, zoom: tutorial[indexLocal].map.zoom }));
    }
    dispatch(UIActions.updateComponent({ name: COMPONENT_NAME, data: { index: indexLocal } }));
  };

  const moveOverlay = useMediaQuery("(max-height:760px)");
  const searchOverlayBreak = useMediaQuery(`(max-width:${SEARCHBAR_BREAKPOINT}px)`);

  const getOverlay = () => {
    switch (index) {
      case 4:
        if (moveOverlay) {
          return targetPos.alt;
        } else {
          return targetPos.main;
        }
        break;
      case 5:
        return targetPos.main;
        break;
      case 6:
        return targetPos.main;
        break;
      default:
        return targetPos;
        break;
    }
  };

  const getWidth = () => {
    if (index === 3) {
      if (searchOverlayBreak) {
        return targetWidth.alt;
      }
      return targetWidth.main;
    }
    return targetWidth;
  };

  const getPos = () => {
    switch (index) {
      case 3:
        return searchOverlayBreak ? pos.alt : pos.main;
        break;
      case 4:
        return moveOverlay ? pos.alt : pos.main;
        break;
      case 5:
        return moveOverlay ? pos.alt : pos.main;
        break;
      case 6:
        return moveOverlay ? pos.alt : pos.main;
        break;
      default:
        return pos;
        break;
    }
  };

  return (
    <>
      {visible && (
        <div>
          {index === 4 && (
            // Static Popup
            <Popup
              key={"popup tutorial"}
              title={"101 - Noord-Holland"}
              posDefault={{
                x: remToPx(moveOverlay ? targetPos.alt.left : targetPos.main.left ?? "0"),
                y: remToPx(moveOverlay ? targetPos.alt.top : targetPos.main.top),
              }}
              docked={false}
              sa2={101}
              order={9999}
            />
          )}
          {tutorial[index].target && <SOverlayImg theme={theme} alt={"Tutorial Overlay"} src={"data:image/svg+xml;base64," + btoa(tutorialOverlay(screen, getOverlay(), getWidth(), targetHeight))} />}
          <SRootPaper theme={theme} pos={getPos()} widthOvRd={index === 5 || index === 6}>
            <SHeaderDiv>
              <STitleTypography theme={theme} variant="h2">
                {t(`tutorial.panels.${index}.title`)}
              </STitleTypography>
              <SCloseButtonIconButton
                aria-label="Close Tutorial"
                // Close popup
                onClick={handleClose}
                color="secondary"
                size="large"
                theme={theme}
              >
                <CloseIcon style={{ fontSize: "1.5rem" }} />
              </SCloseButtonIconButton>
            </SHeaderDiv>
            <SSeparatorHr theme={theme} />
            <SContentDiv>
              <SDescriptionTypography theme={theme} variant="body1">
                {t(`tutorial.panels.${index}.content`)}
              </SDescriptionTypography>
            </SContentDiv>
            <SStepperDiv theme={theme}>
              <IconButton aria-label="Previous" onClick={handlePrev} color="primary" size="large">
                <SPrevIcon />
              </IconButton>
              <Scrollbar variant={VARIANTS.HORIZONTAL} index={index} length={tutorial.length} handleSelect={handleSelect} />
              <IconButton
                aria-label="Next"
                // Close popup
                onClick={handleNext}
                color="primary"
                size="large"
              >
                <SNextIcon />
              </IconButton>
            </SStepperDiv>
          </SRootPaper>
        </div>
      )}
    </>
  );
};

export default Tutorial;
