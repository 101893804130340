import { useTheme } from "@mui/material/styles";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import ReactIdSwiperCustom from "react-id-swiper/lib/ReactIdSwiper.custom";
import Swiper, { Navigation, Pagination, Mousewheel, FreeMode } from "swiper";
import * as UIActions from "../../../../redux/features/uiSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/types";
import Scrollbar from "../../../Scrollbar";
import StoriesSelect from "../../StoriesSelect";
import { VARIANTS } from "../../../Scrollbar/Scrollbar";
import {
  panelOut,
  SContainerDiv,
  SContentDiv,
  scrollContainer,
  SRootPaper,
  SScrollButtonContainerDiv,
  SScrollButtonIconButton,
  SScrollDownIcon,
  SScrollPromptSpan,
  SScrollUpIcon,
  swiperWrapper,
} from "./emotion";
const COMPONENT_NAME = "stories";

interface DesktopViewProps {
  panels: any;
  handleOpen: Function;
  handleClose: Function;
}

const DesktopView = ({ panels, handleOpen, handleClose }: DesktopViewProps) => {
  const dispatch = useAppDispatch();
  const { expanded, panelIndex } = useAppSelector((state) => state.ui[COMPONENT_NAME]);
  const swiperRef = useRef<any>(null);
  const theme = useTheme();
  const { t } = useTranslation();

  useEffect(() => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.on({ slideChange: handlePanelScroll });
    }
  }, []);

  useEffect(() => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.update();
      swiperRef.current.swiper.updateAutoHeight();
      swiperRef.current.swiper.slideTo(0);
    }
  }, [panels]);

  useEffect(() => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      if (swiperRef.current.swiper.activeIndex !== panelIndex) {
        handlePanelIndex(panelIndex);
      }
    }
  }, [panelIndex]);

  const handlePrevPanel = () => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextPanel = () => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePanelIndex = (index: number) => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.slideTo(index);
    }
  };

  const handlePanelScroll = () => {
    dispatch(UIActions.updateComponent({ name: COMPONENT_NAME, data: { panelIndex: swiperRef.current.swiper.activeIndex } }));
  };

  const handleCollapse = () => {
    dispatch(UIActions.updateComponent({ name: COMPONENT_NAME, data: { expanded: false } }));
  };

  function renderView() {
    const swiperParams: any = {
      Swiper,
      modules: [Navigation, Pagination, Mousewheel, FreeMode],
      lazy: true,
      shouldSwiperUpdate: true,
      effect: "slide",
      slidesPerView: "auto",
      direction: "vertical",
      mousewheel: true,
      autoHeight: true,
      preventInteractionOnTransition: true,
      freeMode: true,
      freeModeMomentumRatio: 0.5,
      freeModeMomentumBounce: false,
      longSwipes: false,
      preloadImages: true,
      updateOnImagesReady: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      containerClass: scrollContainer,
      wrapperClass: swiperWrapper,
      slideNextClass: panelOut,
      slidePrevClass: panelOut,
      setWrapperSize: true,
      grabCursor: true,
      on: {
        slideChange: handlePanelScroll,
      },
    };

    return (
      <SContainerDiv theme={theme}>
        <StoriesSelect handleClose={handleClose} />
        <SContentDiv theme={theme}>
          <SScrollButtonContainerDiv className={`top ${panelIndex === 0 ? "hidden" : ""}`}>
            <SScrollButtonIconButton color="primary" className={"swiper-button-prev"} onClick={handlePrevPanel} size="large">
              <SScrollUpIcon />
            </SScrollButtonIconButton>
            <SScrollPromptSpan>{t("scroll")}</SScrollPromptSpan>
          </SScrollButtonContainerDiv>
          <ReactIdSwiperCustom {...swiperParams} ref={swiperRef}>
            {panels}
          </ReactIdSwiperCustom>
          <SScrollButtonContainerDiv className={`bottom ${panelIndex === panels.length - 1 ? "hidden" : ""}`}>
            <SScrollPromptSpan>{t("scroll")}</SScrollPromptSpan>
            <SScrollButtonIconButton color="primary" className={"swiper-button-next"} onClick={handleNextPanel} size="large">
              <SScrollDownIcon />
            </SScrollButtonIconButton>
          </SScrollButtonContainerDiv>
        </SContentDiv>
      </SContainerDiv>
    );
  }
  return (
    <SRootPaper theme={theme}>
      <Scrollbar index={panelIndex} length={panels.length} variant={VARIANTS.VERTICAL} handleSelect={handlePanelIndex} />
      {renderView()}
    </SRootPaper>
  );
};

export default DesktopView;
