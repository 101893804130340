import { useAppSelector } from "../../../redux/types";
import { SPC4Div, SPC4Title, SPC4List } from "./emotion";
import { useTranslation } from "react-i18next";

interface PC4ContainerProps {
  sa2?: string;
}
const PC4Container = ({ sa2 }: PC4ContainerProps) => {
  const { t } = useTranslation();
  const popupData = useAppSelector((state) => state.cancerAtlas.popupData);
  const sexIndex = useAppSelector((state) => state.filter.filters.sex.value);

  if (!popupData || !popupData[`${sa2}-${sexIndex}`]) return <div></div>;
  let relevantPC4 = popupData[`${sa2}-${sexIndex}`][0].childpostcodes;
  return relevantPC4 && relevantPC4.length > 0 ? (
    <SPC4Div style={{ textAlign: "left", margin: "0 .5rem" }}>
      <SPC4Title>{t(`messages:popup.pc4s`)}:&nbsp;</SPC4Title>

      <SPC4List>{relevantPC4.join(", ")}</SPC4List>
    </SPC4Div>
  ) : (
    // Insert blank div because scroll ref is indexing child elems via a constant index
    <div></div>
  );
};

export default PC4Container;
