import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

export const SCloseIcon = styled(CloseIcon)({
  fontSize: "1.5em",
});

export const SIconButton = styled(IconButton)({
  position: "absolute",
  right: "1rem",
  top: "1rem",
});
