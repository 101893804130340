import { createAsyncThunk } from "@reduxjs/toolkit";
import * as GlobeActions from "./index";

const resetThunk = createAsyncThunk("globe/resetThunk", async (_, thunkAPI) => {
  thunkAPI.dispatch(GlobeActions.resetMap());
});

// use async thunk to avoid the race condition between the map resetting and the the triggerPopupRedraw basing new positions off the old map state
export const applyResetThunk = createAsyncThunk("globe/applyResetThunk", async (_, thunkAPI) => {
  await thunkAPI.dispatch(resetThunk());
  thunkAPI.dispatch(GlobeActions.triggerPopupRedraw());
});