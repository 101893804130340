import { SRootDiv, SGenericDiv, SActiveDiv, SCircleDiv, SActiveContentDiv } from "./emotion";

interface Props {
  data?: string | null;
  width?: number;
  top?: number;
  left?: number;
  visible?: boolean;
  type?: string;
  color?: string;
  textColor?: string;
  reverse?: boolean;
}

const Tooltip = (props: Props) => {
  const { data = null, width = 150, top = 0, left = 0, visible, type = "rollover", color = "#59595c", textColor = "#414141", reverse = false } = props;
  const revClass: string = reverse ? " reverse" : "";

  return (
    <>
      {visible && (
        <SRootDiv top={top} left={left}>
          {type == "rollover" ? (
            <SGenericDiv>{data}</SGenericDiv>
          ) : (
            <SActiveDiv className={revClass}>
              <SCircleDiv className={revClass} color={color} />
              <SActiveContentDiv className={revClass} color={color} textColor={textColor}>
                {data}
              </SActiveContentDiv>
            </SActiveDiv>
          )}
        </SRootDiv>
      )}
    </>
  );
};

export default Tooltip;
