import { useTranslation } from "react-i18next";
import * as FilterActionsThunk from "../../../redux/features/filterSlice/thunk";
import * as UIActionsThunk from "../../../redux/features/uiSlice/thunk";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import { useTheme } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "../../../redux/types";
import { SContentDiv, SFilterButton, SRootDiv } from "./emotion";
const COMPONENT_NAME = "noData";

const NoDataOverlay = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const noData = useAppSelector((state) => state.filter.noDataFlag);

  const handleClick = (event: any) => {
    let newValue = parseInt(event.target.value);
    let sexTitle = newValue ? t(`filters:sex.values.${newValue}`) : "";
    dispatch(FilterActionsThunk.updateFilter({ fieldName: "sex", type: "radio", _name: "", _value: newValue }));
    dispatch(UIActionsThunk.updateIndicators({ data: { sex: newValue } }));
  };

  const sexTitle = noData.validSex ? t(`filters:sex.values.${noData.validSex}`) : "";
  return (
    <Fade in={noData.visible} mountOnEnter unmountOnExit>
      <SRootDiv theme={theme}>
        <SContentDiv>
          <Typography variant="h2" component="h2">
            {t(`messages:no_data`)}
          </Typography>
          {noData.validSex && (
            <SFilterButton
              theme={theme}
              aria-label={sexTitle}
              variant="contained"
              value={noData.validSex}
              // Send view mode index to view mode change function
              onClick={handleClick}
              // Use color and mode state value to determine active state
              color="primary"
            >
              {t(`messages:select`)} {sexTitle}
            </SFilterButton>
          )}
        </SContentDiv>
      </SRootDiv>
    </Fade>
  );
};

export default NoDataOverlay;
