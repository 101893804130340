import gradient from "gradient-color";

export const gccsaLookup = [
  { gccsa_code: 1, gccsa_name: "Greater Sydney" },
  { gccsa_code: 2, gccsa_name: "Greater Melbourne" },
  { gccsa_code: 3, gccsa_name: "Greater Brisbane" },
  { gccsa_code: 4, gccsa_name: "Greater Adelaide" },
  { gccsa_code: 5, gccsa_name: "Greater Perth" },
  { gccsa_code: 6, gccsa_name: "Greater Hobart" },
  { gccsa_code: 7, gccsa_name: "Greater Darwin" },
  { gccsa_code: 8, gccsa_name: "ACT" },
];

export const NCAColorGradient = gradient(
  [
    "#2C7BB6",
    "#ABD9E9",
    "#FFFFBF",
    "#FDAE61",
    "#D7191C",
  ],
  64
);

export const POPUP_WIDTH = "20rem";
export const POPUP_HEIGHT = "24rem";

export const indicatorLookup = [
  { value: 0, indicator: "Diagnoses", dataset: "SIR" },
  { value: 1, indicator: "Excess Deaths", dataset: "EHR" },
];
export const sexLookup = [
  { value: 1, sex: "males" },
  { value: 2, sex: "females" },
  { value: 3, sex: "persons" },
];
export const BREAKPOINTS = {
  WIDTH: 600,
  HEIGHT: 550,
};
export const VPLOT_FULLSCREEN_MAX_WIDTH = 200;
export const VPLOT_FULLSCREEN_BG_HEIGHT = 40;
export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
export const SCATTERPLOT_CIRCLE_SIZE = "0.063rem";
export const FS_SCATTERPLOT_CIRCLE_SIZE = "0.1rem";

