import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import ScrollUpIcon from "@mui/icons-material/ArrowDropUp";
import ScrollDownIcon from "@mui/icons-material/ArrowDropDown";
import styled from "@emotion/styled";
import { css } from "@emotion/css";

export const SRootPaper = styled(Paper)(({ theme }: { theme: any }) => ({
  display: "flex",
  width: "22.5rem",
  height: "100%",
  backgroundColor: theme.palette.background.dark,
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "100vh",
  },
}));

export const SScrollButtonContainerDiv = styled("div")({
  zIndex: 1,
  position: "absolute",
  width: "100%",
  textAlign: "center",
  margin: "0 auto",
  "&.top": {
    top: 0,
    background: "linear-gradient(to bottom, rgba(43, 43, 43, 1) 0%, rgba(43, 43, 43, 0.8) 75%, rgba(43, 43, 43, 0) 100%)",
  },
  "&.bottom": {
    bottom: 0,
    background: "linear-gradient(to top, rgba(43, 43, 43, 1) 0%, rgba(43, 43, 43, 0.8) 75%, rgba(43, 43, 43, 0) 100%)",
  },
  "&.hidden": {
    visibility: "hidden",
  },
});

export const SContentDiv = styled("div")(({ theme }: { theme: any }) => ({
  position: "relative",
  width: "100%",
  height: "calc(100% - 5rem)",
  [theme.breakpoints.down("md")]: {
    height: "calc(100% - 5rem)",
    top: "initial",
    transform: "none",
  },
}));

export const SContainerDiv = styled("div")(({ theme }: { theme: any }) => ({
  position: "relative",
  color: theme.palette.text.primary,
  display: "inline-block",
  width: "98%",
  height: "100%",
  verticalAlign: "top",
}));

export const SScrollButtonIconButton = styled(IconButton)({
  width: "2rem",
  height: "2rem",
});

export const SScrollUpIcon = styled(ScrollUpIcon)({
  fontSize: "1.5rem",
});

export const SScrollDownIcon = styled(ScrollDownIcon)({
  fontSize: "1.5rem",
});

export const SScrollPromptSpan = styled("span")({
  display: "block",
  fontSize: "1rem",
});

export const scrollContainer = css({
  width: "100%",
  height: "100%",
  overflow: "hidden",
});

export const swiperWrapper = css({
  height: "100%",
});

export const panelOut = css({
  opacity: 0.5,
});
