import { createBrowserHistory } from "history";
import { useEffect } from "react";
import { stateFromURI } from "../../../redux/features/uriSlice/thunk";
import { useAppDispatch } from "../../../redux/types";

export const usePopulateFromStateHook = () => {
  const dispatch = useAppDispatch();
  const history = createBrowserHistory();
  const location = history.location;

  useEffect(() => {
    if (location.hash) {
      dispatch(stateFromURI({ hashURI: location.hash }));
    }
  }, []);
};
