import * as FilterActions from "../filterSlice";
import * as CancerAtlasActions from "../cancerAtlasSlice";
import * as CancerAtlasActionsThunk from "../cancerAtlasSlice/thunk";
import * as UIActionsThunk from "../uiSlice/thunk";
import * as HTMLVisualisationActions from "../htmlVisualisationSlice";
import axios from "axios";
import { FilterState } from "../filterSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppState } from "../../store";

const ENDPOINTS = {
  DATA_URL: process.env.REACT_APP_DATA_URL,
};

export const updateFilter = createAsyncThunk(
  "filter/updateFilterThunk",
  async ({ fieldName, type, _name, _value }: { fieldName: keyof FilterState["filters"]; type: any; _name: any; _value: any }, thunkAPI) => {
    const { cancerData } = (thunkAPI.getState() as AppState).cancerAtlas;
    const { indicator, sex, pc } = (thunkAPI.getState() as AppState).filter.filters;
    // Don't update Filters if no Cancer data has been downloaded
    if (cancerData == null) return;
    if (fieldName === "indicator" && _value !== indicator.value) {
      thunkAPI.dispatch(UIActionsThunk.updateIndicator({ index: 0, data: { value: _value } }));
    }
    if (fieldName === "sex" && _value !== sex.value) {
      thunkAPI.dispatch(UIActionsThunk.updateIndicator({ index: 0, data: { sex: _value } }));
    }
    if (fieldName === "pc" && _value !== pc.value) {
      //close all popups
      thunkAPI.dispatch(HTMLVisualisationActions.removeAllHTMLVis({ parentId: "iknl_popups" }));
      thunkAPI.dispatch(UIActionsThunk.updateIndicator({ index: 0, data: { pc: _value } }));
    }
    thunkAPI.dispatch(FilterActions.updateFilter({ fieldName, type, _name, _value }));
  }
);

export const changeNoDataFlag = createAsyncThunk("filter/changeNoDataFlagThunk", async ({ value, validSex }: { value: boolean; validSex: any }, thunkAPI) => {
  thunkAPI.dispatch(FilterActions.setNoDataFlag({ visible: value, validSex: validSex }));
});

export const applyState = createAsyncThunk("filter/applyStateThunk", async ({ state }: { state: any }, thunkAPI) => {
  thunkAPI.dispatch(FilterActions.applyStateFilters(state));
  if (state.sex && state.pc) {
    thunkAPI.dispatch(CancerAtlasActionsThunk.requestPCCancerData({ ind: state.indicator.value, grp: state.cancergrp.value, sex: state.sex.value, pc: state.pc.value }));
  }
});

export const getCancerGroups = createAsyncThunk("filter/getCancerGroupsThunk", async ({ lang }: { lang: string }, thunkAPI) => {
  thunkAPI.dispatch(FilterActions.fetchCancerGrp());
  axios
    .get(`${ENDPOINTS.DATA_URL}cancer-groups/cancergrppc?locale=${lang}`)
    .then((response) => {
      return thunkAPI.dispatch(FilterActions.gotCancerGrp(response.data));
    })
    .catch((error) => {});
});
