import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface GlobeState {
  loading: boolean;
  loadingStatus: number;
  activeSA2s: Array<any>;
  resizing: boolean;
  map: {
    center?: Array<number>;
    zoom?: number;
    bearing?: number;
    pitch?: number;
  };
  zoomLevel: number;
  getGeolocation: boolean;
  processingGeolocation: null | any;
  flyToActive: boolean;
  flyToDataId: null | number;
  requestScreenshot: boolean;
  triggerPopupRedraw: number;
}
const initialState: GlobeState = {
  loading: false,
  loadingStatus: 0,
  activeSA2s: [],
  resizing: false,
  map: {
    center: [5.558686193664669, 52.18662038640304],
    zoom: 7,
    bearing: 0,
    pitch: 0,
  },
  zoomLevel: 0,
  getGeolocation: false,
  processingGeolocation: null,
  flyToActive: false,
  flyToDataId: null,
  requestScreenshot: false,
  triggerPopupRedraw: 0,
};

export const globeSlice = createSlice({
  name: "globe",
  initialState,
  reducers: {
    requestScreenshot: (state) => {
      state.requestScreenshot = true;
    },
    screenshotTaken: (state) => {
      state.requestScreenshot = false;
    },
    flyToRequest: (state, action: PayloadAction<{ id: GlobeState["flyToDataId"] }>) => {
      state.flyToActive = true;
      state.flyToDataId = action.payload.id;
    },
    flyToSuccess: (state) => {
      state.flyToActive = false;
      state.flyToDataId = null;
    },
    flyToFailed: (state) => {
      state.flyToActive = false;
      state.flyToDataId = null;
    },
    getGeoLocation: (state) => {
      state.processingGeolocation = true;
    },
    updateLoadingStatus: (state, action: PayloadAction<{ loadingStatus: GlobeState["loadingStatus"] }>) => {
      state.loadingStatus = action.payload.loadingStatus;
    },
    updateLoadingState: (state, action: PayloadAction<GlobeState["loading"]>) => {
      state.loading = action.payload;
    },
    setZoomLevel: (state, action: PayloadAction<GlobeState["zoomLevel"]>) => {
      state.zoomLevel = action.payload;
      if (state.map.zoom === undefined) return;
      const updatedMap = {
        ...state.map,
        zoom: action.payload + state.map.zoom,
      };
      state.map = updatedMap;
    },
    updateMap: (state, action: PayloadAction<GlobeState["map"]>) => {
      const updatedMap = {
        center: action.payload.center || state.map.center,
        zoom: action.payload.zoom || state.map.zoom,
        bearing: action.payload.bearing || state.map.bearing,
        pitch: action.payload.pitch || state.map.pitch,
      };
      state.map = updatedMap;
    },
    resetMap: (state) => {
      state.map = initialState.map;
    },
    resizeRequest: (state) => {
      state.resizing = true;
    },
    resizeSuccess: (state) => {
      state.resizing = false;
    },
    resizeFailed: (state) => {
      state.resizing = false;
    },
    updateActiveSA2s: (state, action: PayloadAction<{ activeSA2s: GlobeState["activeSA2s"] }>) => {
      state.activeSA2s = action.payload.activeSA2s;
      state.loading = false;
    },
    triggerPopupRedraw: (state) => {
      state.triggerPopupRedraw += 1;
    },
  },
});

export const {
  requestScreenshot,
  screenshotTaken,
  flyToRequest,
  flyToSuccess,
  flyToFailed,
  getGeoLocation,
  updateLoadingStatus,
  updateLoadingState,
  setZoomLevel,
  updateMap,
  resetMap,
  resizeRequest,
  resizeFailed,
  resizeSuccess,
  updateActiveSA2s,
  triggerPopupRedraw
} = globeSlice.actions;
export default globeSlice.reducer;
