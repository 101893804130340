import { styled } from "@mui/system";
import { VARIANTS } from "./Scrollbar";

export const SButton = styled("button")(({ theme, variant, index, i }: { theme: any; variant: VARIANTS; index: number; i: number }) => ({
  cursor: "pointer",
  outline: "none",
  backgroundColor: theme.palette.background.paper,
  padding: 0,
  margin: 0,
  border: "none",
  borderTop: "0.05rem solid black",
  transition: "all 0.5s ease",
  [theme.breakpoints.up("sm")]: {
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  ...(variant === VARIANTS.HORIZONTAL && {
    width: "100%",
    height: "100%",
    borderTop: "none",
    borderBottom: "none",
    borderLeft: "0.05rem solid black",
    borderRight: "0.05rem solid black",
  }),

  ...(variant === VARIANTS.VERTICAL && {
    WebkitTapHighlightColor: "rgba(0,0,0,0)",
    WebkitAppearance: "none",
    width: "100%",
    height: "100%",
    border: "none",
    borderTop: "0.05rem solid black",
  }),

  ...(index === i && {
    backgroundColor: theme.palette.primary.main,
  }),
}));

export const SScrollBarDiv = styled("div")(({ theme, variant }: { theme: any; variant: VARIANTS }) => ({
  cursor: "pointer",
  backgroundColor: theme.palette.background.paper,
  display: "inline-block",
  verticalAlign: "top",
  ...(variant === VARIANTS.VERTICAL && {
    width: "2%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  }),

  ...(variant === VARIANTS.HORIZONTAL && {
    width: "100%",
    height: "1rem",
    display: "flex",
    flexDirection: "row",
  }),
}));
