import { useTheme } from "@mui/material/styles";
import { SyntheticEvent, useCallback, useState } from "react";
import { useAppSelector } from "../../../redux/types";
import { pxToRem, remToPx } from "../../../styles/theme";
import { CancerGroupPiece } from "../../../types";
import { SList, SButton } from "./emotion";

interface ButtonListProps {
  listValues: Array<CancerGroupPiece>;
  selectedValue?: number;
  handleClick: Function;
}

const ButtonList = (props: ButtonListProps) => {
  const { listValues, selectedValue = 0 } = props;
  const theme = useTheme();
  const { screen } = useAppSelector((state) => state.ui);
  const [height, setHeight] = useState<number>(-1);

  const ref = useCallback(
    (node: HTMLDivElement) => {
      if (!node) return;
      const posY = node.getBoundingClientRect().y;
      setHeight(pxToRem(screen.height - posY - remToPx("5.5rem")));
    },
    [screen.height]
  );

  const handleClick = (e: SyntheticEvent) => {
    const et = e.target as any;
    props.handleClick && props.handleClick(et.name, et.value);
  };

  return (
    <SList ref={ref} height={height}>
      {listValues.map((item) => {
        return (
          <SButton
            theme={theme}
            value={item.grp}
            name={item.name}
            key={"cancerButton_" + item.grp}
            aria-label={item.name}
            variant="text"
            fullWidth
            selectedValue={selectedValue}
            itemValue={item.grp}
            onClick={handleClick}
          >
            {item.name}
          </SButton>
        );
      })}
    </SList>
  );
};

export default ButtonList;
