import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Swiper from "react-id-swiper";
import Scrollbar from "../../../Scrollbar";
import * as UIActions from "../../../../redux/features/uiSlice";
import { useTheme } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "../../../../redux/types";
import { VARIANTS } from "../../../Scrollbar/Scrollbar";
import {
  panelOut,
  scrollContainer,
  SRootAbstractPaper,
  swiperWrapper,
  SContainerDiv,
  SCloseButtonIconButton,
  SScrollButtonContainerDiv,
  SCloseIcon,
  SRootPaper,
  SContentDiv,
  SScrollButtonIconButton,
  SScrollUpIcon,
  SScrollPromptSpan,
  SPanelsDiv,
  SScrollDownIcon,
  SCollapseButtonIconButton,
  SCollapseIcon,
} from "./emotion";
const COMPONENT_NAME = "stories";

interface MobileViewProps {
  panels: any;
  handleOpen: any;
  handleClose: any;
}

const MobileView = ({ panels, handleOpen, handleClose }: MobileViewProps) => {
  const dispatch = useAppDispatch();
  const { t }: any = useTranslation();
  const theme: any = useTheme();
  const { expanded, panelIndex } = useAppSelector((state) => state.ui.stories);
  const { screen } = useAppSelector((state) => state.ui);
  const [touchY, setTouchY] = useState(0);
  const swiperRef: any = useRef(null);

  useEffect(() => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.on({ slideChange: handlePanelScroll });
    }
  }, []);

  useEffect(() => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.update();
      swiperRef.current.swiper.updateAutoHeight();
      swiperRef.current.swiper.slideTo(0);
    }
  }, [panels]);

  useEffect(() => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      if (swiperRef.current.swiper.activeIndex !== panelIndex) {
        handlePanelIndex(panelIndex);
      }
    }
  }, [panelIndex]);

  const handlePrevPanel = () => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextPanel = () => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePanelIndex = (index: number) => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.slideTo(index);
    }
  };

  const handlePanelScroll = () => {
    dispatch(UIActions.updateComponent({ name: COMPONENT_NAME, data: { panelIndex: swiperRef.current.swiper.activeIndex } }));
  };

  const handleCollapse = () => {
    dispatch(UIActions.updateComponent({ name: COMPONENT_NAME, data: { expanded: false } }));
  };

  const handleExpand = () => {
    dispatch(UIActions.updateComponent({ name: COMPONENT_NAME, data: { expanded: true } }));
  };

  const handleTouchStart = (event: any) => {
    setTouchY(event.touches[0].clientY);
  };

  const handleTouchMove = (event: any) => {
    if (event.touches[0].clientY - touchY < -(screen.height / 4)) {
      // Swipe Up
      setTouchY(event.touches[0].clientY);
      handleExpand();
    }
  };

  const handleTouchEnd = (event: any) => {
    setTouchY(0);
  };

  const swiperParams = {
    shouldSwiperUpdate: true,
    effect: "slide" as any,
    slidesPerView: "1" as any,
    direction: "horizontal" as any,
    mousewheel: true,
    navigation: {
      nextEl: ".swiper-button-next", // Add your class name for next button
      prevEl: ".swiper-button-prev", // Add your class name for prev button
    },
    containerClass: scrollContainer,
    wrapperClass: swiperWrapper,
    slideNextClass: panelOut,
    slidePrevClass: panelOut,
    setWrapperSize: true,
    on: {
      slideChange: handlePanelScroll,
    },
  };

  return (
    <>
      {!expanded ? (
        <SRootAbstractPaper theme={theme} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
          <Scrollbar index={panelIndex} length={panels.length} variant={VARIANTS.HORIZONTAL} handleSelect={handlePanelIndex} />
          <SContainerDiv theme={theme}>
            <Swiper {...swiperParams} ref={swiperRef}>
              {panels}
            </Swiper>
          </SContainerDiv>
          <SCloseButtonIconButton key={`stories_btnClose`} theme={theme} onClick={handleClose} className={"collapsed"} aria-label={t("close")} size="large">
            <SCloseIcon theme={theme} />
          </SCloseButtonIconButton>
        </SRootAbstractPaper>
      ) : (
        <SRootPaper theme={theme}>
          <SContainerDiv theme={theme}>
            <SContentDiv>
              <SScrollButtonContainerDiv className={"top"}>
                <SScrollButtonIconButton color="primary" className={"swiper-button-prev"} onClick={handlePrevPanel} size="large">
                  <SScrollUpIcon theme={theme} />
                </SScrollButtonIconButton>
                <SScrollPromptSpan>{t("scroll")}</SScrollPromptSpan>
              </SScrollButtonContainerDiv>
              <SPanelsDiv>{panels}</SPanelsDiv>
              <SScrollButtonContainerDiv className={"bottom"}>
                <SScrollPromptSpan>{t("scroll")}</SScrollPromptSpan>
                <SScrollButtonIconButton color="primary" className={"swiper-button-next"} onClick={handleNextPanel} size="large">
                  <SScrollDownIcon theme={theme} />
                </SScrollButtonIconButton>
              </SScrollButtonContainerDiv>
            </SContentDiv>
          </SContainerDiv>
          <SCollapseButtonIconButton key={`stories_btnCollapse`} theme={theme} onClick={handleCollapse} aria-label="Collapse" size="large">
            <SCollapseIcon theme={theme} />
          </SCollapseButtonIconButton>
          <SCloseButtonIconButton key={`stories_btnClose`} theme={theme} onClick={handleClose} aria-label={t("close")} size="large">
            <SCloseIcon theme={theme} />
          </SCloseButtonIconButton>
        </SRootPaper>
      )}
    </>
  );
};

export default MobileView;
