import { styled } from "@mui/system";

export const SLoadingDiv = styled("div")(({ theme }: { theme: any }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  flexDirection: "column",
  height: "100%",
}));

export const SLoadingImg = styled("img")(({ theme }: { theme: any }) => ({
  ...theme.components.componentLoading.loadingImage,
}));

export const SBoxDiv = styled("div")(({ theme, isDesktop }: { theme: any; isDesktop: boolean }) => ({
  display: "inline-block",
  width: "2rem",
  height: "2rem",
  margin: "0.1rem",
  border: "none",
  boxSizing: "border-box",
  "&.selected": {
    height: "2rem",
  },
  "&.active": {
    border: "0.25rem double " + theme.palette.common.black,
  },

  //Blue border on hover
  ...(isDesktop && {
    "&:hover": {
      border: "0.25rem solid " + theme.palette.primary.main,
    },
  }),
  cursor: "pointer",
}));
