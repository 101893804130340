import Typography from "@mui/material/Typography";
import GridIcon from "@mui/icons-material/ViewComfy";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/system";
import DraggableWindow from "../DraggableWindow/DraggableWindow";
import { ScreenType } from "../../types";
import { BREAKPOINTS } from "../../constants";

export const SContainerDiv = styled("div")(({ theme }: any) => ({
  ...theme.components.border,
  width: "100%",
  height: "21rem",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    height: "auto",
    minHeight: "21rem",
  },
}));

export const SHeaderDiv = styled("div")(() => ({
  width: "calc(100% - 1rem)",
  height: "auto",
  display: "flex",
  padding: ".5rem",
  paddingBottom: "0",
  justifyContent: "space-between",
}));

export const STitleTypography = styled(Typography)(() => ({
  fontSize: "1.25rem",
  fontWeight: 500,
  display: "inline-block",
  width: "18rem",
  paddingTop: "0.5rem",
}));

export const SButtonsDiv = styled("div")(() => ({
  textAlign: "right",
  display: "inline-block",
  width: "8rem",
}));

export const SButton = styled(Button)(({ theme }: any) => ({
  color: theme.palette.text.primary,
  minWidth: "1rem",
  minHeight: "1rem",
  padding: "0.5rem",
  "&.selected": {
    color: theme.palette.primary.main,
  },
}));

export const SGridIcon = styled(GridIcon)(() => ({
  fontSize: "1.5rem",
}));

export const SCloseIcon = styled(CloseIcon)(() => ({
  fontSize: "1.5rem",
}));

export const SFooterDiv = styled("div")(() => ({
  width: "100%",
  height: "1rem",
}));

export const SDraggableWindow = styled(DraggableWindow)(({ theme, docked, adjusted, screen }: { theme: any; docked: boolean; adjusted: boolean; screen: ScreenType }) => ({
  position: "fixed",
  top: 0,
  left: 0,

  ...(screen.height <= BREAKPOINTS.HEIGHT && {
    position: "relative",
    top: "auto",
    left: "auto",
    width: "100%",
    height: "auto",
  }),
  [theme.breakpoints.down("sm")]: {
    position: "relative",
    top: "auto",
    left: "auto",
    width: "100%",
    height: "auto",
  },
  ...(docked &&
    !adjusted && {
      "&:before": {
        zIndex: 1,
        pointerEvents: "none",
        content: "' '",
        top: "calc(100% - 0.1rem)",
        left: "50%",
        width: "0",
        height: "0",
        display: "block",
        position: "absolute",
        transform: "translate(-50%,0%)",
        borderLeft: "1rem solid transparent",
        borderRight: "1rem solid transparent",
        borderTop: "1rem solid #404041",
      },
    }),
}));

export const SPaper = styled(Paper)(({ theme }: { theme: any }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  [theme.breakpoints.down("sm")]: {
    position: "relative",
    top: "auto",
    left: "auto",
    width: "100%",
    height: "auto",
  },
  ...theme.components.border,
  width: "100%",
  height: "21rem",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    height: "auto",
    minHeight: "21rem",
  },
}));
