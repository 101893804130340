import styled from "@emotion/styled";

export const SContentDiv = styled("div")(() => ({
	width: "calc(100% - 0.5rem)",
	height: "80%",
	paddingLeft: "0.5rem",
	textAlign: "center",
	overflow: "hidden",
}));

export const SViewDiv = styled("div")(() => ({
	display: "inline-flex",
	flexDirection: "column",
	width: "12rem",
	padding: "0.5em",
	height: "auto",
}));

export const SGridDiv = styled("div")(() => ({
	width: "12rem",
	display: "flex",
	flexDirection: "row",
	flexWrap: "wrap",
	justifyContent: "left",
	margin: "0 auto",
}));
