import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppState } from "../../store";
import * as CancerAtlasThunk from "../cancerAtlasSlice/thunk";
import * as UIActions from "../uiSlice";

export const updateIndicator = createAsyncThunk("ui/updateIndicatorThunk", async ({ index, data }: { index: any; data: any }, thunkAPI) => {
  const { cancerData } = (thunkAPI.getState() as AppState).cancerAtlas;
  // Don't update Indicators if no Cancer data has been downloaded
  if (cancerData == null) return;
  thunkAPI.dispatch(UIActions.updateIndicator({ index: index, data: data }));
});

export const updateIndicators = createAsyncThunk("ui/updateIndicatorsThunk", async ({ data }: { data: any }, thunkAPI) => {
  const { cancerData } = (thunkAPI.getState() as AppState).cancerAtlas;
  // Don't update Indicators if no Cancer data has been downloaded
  if (cancerData == null) return;
  thunkAPI.dispatch(UIActions.updateIndicators({ data: data }));
});