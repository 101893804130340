import { createRef, Suspense, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../../redux/types";
import * as FilterActionsThunk from "../../../redux/features/filterSlice/thunk";
import * as CancerAtlasActionsThunk from "../../../redux/features/cancerAtlasSlice/thunk";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import ListView from "../ListView";
import GridView from "../GridView";
import { useTheme } from "@mui/material/styles";
import { css } from "@emotion/css";
import { OrderTypes } from "../../../types";
import { DataPieceType } from "../../../types";
import { SBoxDiv, SLoadingDiv } from "./emotion";

const COMPONENT_NAME = "popup";

export type DataType = {
  indicator: number;
  females: Object[];
  male: Object[];
  all: Object[];
};

interface DataContainerProps {
  staticBool?: boolean;
  isMobile: boolean;
  mode?: number;
  selected?: number;
  order?: OrderTypes;
  orderIndex?: string;
  filters?: any;
  sa2?: string;
}

const DataContainer = (props: DataContainerProps) => {
  const { mode = 0, staticBool, filters, sa2, isMobile, selected = 1 } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme<any>();
  //Selected is the index of the box as it appears in the DOM children to select
  const [_selected, _setSelected] = useState<number>(selected);
  const [data, setData] = useState<Array<DataPieceType>>([]);
  const [order, setOrder] = useState<OrderTypes>(props.order ?? OrderTypes.ASC);
  const [orderIndex, setOrderIndex] = useState<string>(props.orderIndex ?? "cancer_grp");
  const cancerValue = useAppSelector((state) => state.filter.filters["cancergrp"].value);
  const sexIndex = useAppSelector((state) => state.filter.filters.sex.value);
  const indicatorIndex = useAppSelector((state) => state.filter.filters.indicator.value);
  const { loading, loadingDensityData, popupData } = useAppSelector((state) => state.cancerAtlas);
  const cancerGroups = useAppSelector((state) => state.filter.filters.cancergrp.values); // now filter vals rather than translations
  const scrollbarRef = createRef<any>();
  const [boxes, setBoxes] = useState<Array<Array<JSX.Element>>>([]);
  const dataRef = useRef<Array<DataPieceType>>(data);
  dataRef.current = data;
  const TOOLTIP_STYLE = {
    tooltip: css(theme.components.tooltip.tooltip),
    tooltipPlacementTop: css(theme.components.tooltip.tooltipPlacementTop),
    tooltipPlacementBottom: css(theme.components.tooltip.tooltipPlacementBottom),
  };

  function organiseData(dataLocal: Array<DataPieceType>) {
    let output: Array<any> = [];
    let cancers: any = {};
    // Get all cancer values for the provided sex filter
    dataLocal.map((item) => {
      // Get current selection text values (content)
      // NOTE: Duncan Difference = item.v
      let content = "";
      // Calculate p50 from plog50
      let p50 = Math.pow(2, item.logp50);
      if (p50 <= 0.995) {
        let x = Math.round((1 - p50) * 100);
        content =
          0 <= item.v && item.v <= 0.6
            ? `${x}% ${t("comparison.average", {
                comparator: t("comparison.below"),
                subject: t("common:demonym-short"),
              })}, ${t("comparison.unlikely_difference")}`
            : `${x}% ${t("comparison.average", {
                comparator: t("comparison.below"),
                subject: t("common:demonym-short"),
              })}, ${t("comparison.likely_difference")}`;
      } else if (p50 < 1.005) {
        content = t("comparison.average", {
          comparator: t("comparison.equal"),
          subject: t("common:demonym-short"),
        });
      } else {
        let x = Math.round((p50 - 1) * 100);
        content =
          0 <= item.v && item.v <= 0.6
            ? `${x}% ${t("comparison.average", {
                comparator: t("comparison.above"),
                subject: t("common:demonym"),
              })}, ${t("comparison.unlikely_difference")}`
            : `${x}% ${t("comparison.average", {
                comparator: t("comparison.above"),
                subject: t("common:demonym"),
              })}, ${t("comparison.likely_difference")}`;
      }

      output.push({ ...item, content });
    });

    return output;
  }

  useEffect(() => {
    if (!loading) {
      dispatch(CancerAtlasActionsThunk.requestPopupData({ sex: sexIndex, postcode: sa2 }));
    }
  }, [loading]);

  useEffect(() => {
    if (!loading && popupData && popupData[`${sa2}-${sexIndex}`] && cancerGroups.length > 0) {
      const grps = cancerGroups.map((x: any) => x.grp);
      let pData: Array<DataPieceType> = popupData[`${sa2}-${sexIndex}`].filter((x: any) => grps.includes(x.cancer_grp));
      pData = sortData(organiseData(pData), orderIndex, order);
      setData(pData);
      setBoxes(getBoxes(pData, _selected));
    }
  }, [popupData?.[`${sa2}-${sexIndex}`], cancerGroups, _selected, sexIndex, sa2, loading]);

  //If user updates value via indicator
  useEffect(() => {
    _setSelected(cancerValue);
  }, [cancerValue]);

  useEffect(() => {
    boxes && scrollSelected();
  }, [boxes, _selected, cancerValue, order, orderIndex]);

  const scrollSelected = () => {
    if (scrollbarRef.current) {
      var collection: Array<HTMLElement> = Array.from(scrollbarRef.current.children[0].children[1].childNodes);
      var rowNode = collection.find((x: HTMLElement) => x.dataset.grp === _selected.toString());
      if (!rowNode) return;
      rowNode?.scrollIntoView();
      return;
    }
  };

  /* ========================================== */
  // Handle Select
  // -------------
  // Set selected cancer and indicator filter and
  // update selected row index state
  // PROPS:
  //  index: index of selected cancer data
  //  indicator: value to set for indicator filter
  /* ========================================== */
  const handleSelect = async (index: any, indicator: any) => {
    const data = dataRef.current ?? undefined;

    if (loadingDensityData)
      //prevent db spam
      return;
    if (!staticBool) {
      // Convert indicator from string to int
      let indicatorVal = parseInt(indicator);
      // Update cancer and indicator filters
      await dispatch(FilterActionsThunk.updateFilter({ fieldName: "cancergrp", type: "radio", _name: "", _value: +data[index].cancer_grp })).unwrap();
      await dispatch(FilterActionsThunk.updateFilter({ fieldName: "indicator", type: "radio", _name: "", _value: indicatorVal })).unwrap();
    }
    _setSelected(data[index].cancer_grp);
  };

  /* ========================================== */
  // Handle Sort
  // -----------
  // Toggle order and sort table contents by
  // the new order and the specified index
  // PROPS:
  //  index: string index to order by
  /* ========================================== */
  const handleSort = (index: string) => {
    const data = dataRef.current ?? undefined;

    // Toggle order based on index change
    let orderLocal: OrderTypes = order;
    if (index === orderIndex) {
      // Toggle order
      orderLocal = order === OrderTypes.ASC ? OrderTypes.DESC : OrderTypes.ASC;
    }
    // Sort data by order
    let dataLocal: Array<DataPieceType> = sortData(data, index, orderLocal);
    let selectIndex: number = dataLocal.length;
    dataLocal.map((item: DataPieceType, index: number) => {
      if (item.cancer_grp === cancerValue) {
        selectIndex = cancerValue;
      }
    });

    // Set order and sorted data states
    _setSelected(selectIndex);
    setOrder(orderLocal);
    dataLocal = organiseData(dataLocal);
    setData(dataLocal.slice());
    setOrderIndex(index);
  };

  /* ========================================== */
  // Sort Data
  // ---------
  // Sort data by specified index and order
  // PROPS:
  //  data: array of data to sort
  //  index: string - index of data to sort by
  //  order: string - (asc || desc) order
  /* ========================================== */
  const sortData = (data: any, index: string, order: OrderTypes) => {
    let sortedData: any = [];
    const cg = cancerGroups || [];
    if (index === "cancer_grp" && cancerGroups && cancerGroups.length && data.length && index) {
      sortedData =
        order === OrderTypes.ASC
          ? data.sort((a: DataPieceType, b: DataPieceType) => {
              if (cancerGroups) return cancerGroups?.find((x: any) => x.grp === a[index])!.name < cancerGroups?.find((x: any) => x.grp === b[index])!.name ? -1 : 1;
              else return null;
            })
          : data.sort((a: DataPieceType, b: DataPieceType) => (cancerGroups.find((x: any) => x.grp === b[index])!.name < cancerGroups.find((x: any) => x.grp === a[index])!.name ? -1 : 1));
    } else {
      // CODE SNIPPET FROM https://@mui/material-next.com/demos/tables/#sorting-amp-selecting
      sortedData = order === OrderTypes.ASC ? data.sort((a: DataPieceType, b: DataPieceType) => (a.p50 < b.p50 ? -1 : 1)) : data.sort((a: any, b: any) => (b.p50 < a.p50 ? -1 : 1));
    }

    return sortedData;
  };

  /* ========================================== */
  // Get Filter Value Title
  // ----------------------
  // Returns the string title corresponding to the
  // provided filter and value
  // PROPS:
  //  fieldName: string - filter name
  //  value: number - value corresponding to the filter value
  /* ========================================== */
  const getFilterValueTitle = (fieldName: string, value: number) => {
    let output = "";

    filters[fieldName].values.map((filterValue: any) => {
      if (filterValue.value == value) {
        output = filterValue.Title;
      }
    });
    return output;
  };

  /* ========================================== */
  // Get Boxes
  // ---------
  // Creates diagnoses and excess deaths boxes according
  // to colour values and returns these in an array
  /* ========================================== */
  const getBoxes = (dataLocal: Array<DataPieceType>, selectedRowIndex: number) => {
    let boxes: any = [];
    const ind = indicatorIndex;
    boxes[ind] = [];
    const isDesktop = window.matchMedia("(pointer:fine)").matches;
    // Populate boxes array
    dataLocal.map((row, index: number) => {
      boxes[ind][row.cancer_grp] = (
        <Tooltip
          key={`row_${row.cancer_grp}_${sexIndex}`}
          id={`popup_cancer_tooltip`}
          classes={TOOLTIP_STYLE}
          title={cancerGroups.find((x) => x.grp === row.cancer_grp)?.name ?? ""}
          placement="top"
          arrow
          disableFocusListener
        >
          <SBoxDiv
            key={index}
            theme={theme}
            isDesktop={isDesktop}
            style={{ backgroundColor: row.colorHex }}
            onMouseDown={() => handleSelect(index, ind)}
            className={row.cancer_grp === cancerValue ? "selected active" : ""}
          />
        </Tooltip>
      );
    });
    return boxes;
  };

  return (
    <>
      {!data || data.length === 0 || boxes == null || loading ? (
        <SLoadingDiv key={`dataContainer_${sa2}`}>
          <div>
            <Typography variant="caption" className={"loadingText"}>
              <p>
                <img width="80" alt={t("messages:loading.loading")} src="/assets/iknl_loader.svg" />
              </p>
              {t("loading.data")}
            </Typography>
          </div>
        </SLoadingDiv>
      ) : (
        <>
          {mode === 0 ? (
            <Suspense key={`dataContainer0_${sa2}`} fallback="Loading...">
              <ListView
                key={`listView_${sa2}`}
                sa2={sa2}
                isMobile={isMobile}
                boxes={boxes}
                data={data}
                selected={_selected}
                indicatorIndex={indicatorIndex}
                order={order}
                orderIndex={orderIndex}
                cancerGroups={cancerGroups}
                onSort={handleSort}
                scrollbarRef={scrollbarRef}
              />
            </Suspense>
          ) : (
            <Suspense key={`dataContainer_${sa2}`} fallback="Loading...">
              <GridView key={`gridView_${sa2}`} boxes={boxes} data={data} selected={_selected} indicatorIndex={indicatorIndex} />
            </Suspense>
          )}
        </>
      )}
    </>
  );
};

export default DataContainer;
