import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { css } from "@emotion/css";
import { SButtonsDiv, SFilterButton } from "./emotion";

interface SexSelectDisplayProps {
  sex: number;
  handleSelect: any;
}

const SexSelectDisplay = ({ sex, handleSelect }: SexSelectDisplayProps) => {
  const theme: any = useTheme();
  const { t } = useTranslation();
  const TOOLTIP_STYLE = {
    tooltip: css(theme.components.tooltip.tooltip),
    tooltipPlacementTop: css(theme.components.tooltip.tooltipPlacementTop),
    tooltipPlacementBottom: css(theme.components.tooltip.tooltipPlacementBottom),
  };

  const handleClick = (event: any) => {
    let newValue = parseInt(event.target.value);
    handleSelect(newValue);
  };

  return (
    <SButtonsDiv>
      <Tooltip id={`indicator_selectMales_tooltip`} classes={TOOLTIP_STYLE} title={t("filter_by", { filter: t("filters:sex.values.1") })} placement="top" arrow disableFocusListener>
        <SFilterButton aria-label={t("filters:sex.values.1")} variant="contained" value={1} name={"males"} onClick={handleClick} color={sex === 1 ? "primary" : "secondary"}>
          {t("filters:sex.values.1")}
        </SFilterButton>
      </Tooltip>
      <Tooltip id={`indicator_selectFemales_tooltip`} classes={TOOLTIP_STYLE} title={t("filter_by", { filter: t("filters:sex.values.2") })} placement="top" arrow disableFocusListener>
        <SFilterButton
          aria-label={t("filters:sex.values.2")}
          variant="contained"
          value={2}
          name="females"
          // Send view mode index to view mode change function
          onClick={handleClick}
          // Use color and mode state value to determine active state
          color={sex === 2 ? "primary" : "secondary"}
        >
          {t("filters:sex.values.2")}
        </SFilterButton>
      </Tooltip>
      <Tooltip id={`indicator_selectPersons_tooltip`} classes={TOOLTIP_STYLE} title={t("filters:sex.values.3")} placement="top" arrow disableFocusListener>
        <SFilterButton
          aria-label={t("filters:sex.values.3")}
          variant="contained"
          value={3}
          name="persons"
          // Send view mode index to view mode change function
          onClick={handleClick}
          // Use color and mode state value to determine active state
          color={sex === 3 ? "primary" : "secondary"}
        >
          {t("filters:sex.values.3")}
        </SFilterButton>
      </Tooltip>
    </SButtonsDiv>
  );
};

export default SexSelectDisplay;
