import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import styled from "@emotion/styled";

export const SSelectedMenuItem = styled(MenuItem)(({ valueGlobal, index }: { valueGlobal: number; index: number }) => ({
  ...(valueGlobal === index && { color: "#FFF" }),
}));

export const SIconButton = styled(IconButton)(({ theme }: { theme: any }) => ({
  ...theme.components.select.selectButton,
}));

export const SHeaderIconButton = styled(IconButton)(({ theme }: { theme: any }) => ({
  ...theme.components.select.headerButton,
}));

export const SHeaderDiv = styled("div")(({ theme }: { theme: any }) => ({
  ...theme.components.select.header,
}));

export const SFormControl = styled(FormControl)(({ theme, closeButton }: { theme: any; closeButton: boolean }) => ({
  ...theme.components.select.formControl,
  ...(closeButton && theme.components.select.formControlCloseable),
}));
