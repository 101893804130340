import { DataPieceType, SA2DataPieceType } from "../../types";
import { clamp } from "curve-interpolator";
import { GlobeState } from "../../redux/features/globeSlice";

export const x = (d: DataPieceType) => clamp(d.logp50, -2, 2);
export const y = (d: DataPieceType) => d.v;

export const extractName = (dataObj: DataPieceType, pcObj: SA2DataPieceType | undefined): string => {
  const regex = /\.\d/;
  let areacode = pcObj?.areacode.toString();
  const iknl_pc_name = `${areacode} - ${pcObj?.name ?? ""} ${pcObj?.wnpalt} ${pcObj?.province ?? ""}`;
  return iknl_pc_name;
};

export const color = (activeSA2s: GlobeState["activeSA2s"], d: DataPieceType) => {
  return d.colorHex;
};
