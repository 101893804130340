import LinearProgress from "@mui/material/LinearProgress";
import Fade from "@mui/material/Fade";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/types";
import { useTranslation } from "react-i18next";
import { SRootDiv, SContentDiv } from "./emotion";

const LoadingOverlay = () => {
  const { loadingStatus } = useAppSelector((state) => state.globe);
  const theme = useTheme();
  const { t } = useTranslation();
  const [buffer, setBuffer] = useState<number>(0);

  useEffect(() => {
    setBuffer(loadingStatus + Math.random() * 5);
  }, [loadingStatus]);

  return (
    <Fade in={loadingStatus < 100 ? true : false} mountOnEnter unmountOnExit>
      <SRootDiv theme={theme}>
        <SContentDiv theme={theme}>
          <img alt={t("messages:loading.loading")} src="/assets/iknl_loader.svg" />
          <LinearProgress variant="buffer" value={loadingStatus} valueBuffer={buffer} />
        </SContentDiv>
      </SRootDiv>
    </Fade>
  );
};

export default LoadingOverlay;
