import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import cancerAtlasReducer from "./features/cancerAtlasSlice";
import filterReducer from "./features/filterSlice";
import globeReducer from "./features/globeSlice";
import htmlVisualisationReducer from "./features/htmlVisualisationSlice";
import uiReducer from "./features/uiSlice";
import uriReducer from "./features/uriSlice";
import { createLogger } from "redux-logger";

export function makeStore() {
  return configureStore({
    middleware: (getDefaultMiddleware) => {
      if (process.env.NODE_ENV === 'development') {
        return getDefaultMiddleware().concat(
          createLogger({
            collapsed: true,
            predicate: (getState, action) => !action.type.includes("UPDATE_MAP_VIEW_STATE"),
          })
        );
      } else
        return getDefaultMiddleware();
    },
    reducer: { cancerAtlas: cancerAtlasReducer, filter: filterReducer, globe: globeReducer, htmlVisualisation: htmlVisualisationReducer, ui: uiReducer, uri: uriReducer },
  });
}

const store = makeStore();

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action<string>>;

export default store;
