import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { useAppSelector } from "../../../redux/types";
import { SStatusDiv, SIndicatorTypography, SDividerSpan, SSexTypography } from "./emotion";
interface StatusProps {}
export const Status = (props: StatusProps) => {
  const { i18n, t } = useTranslation();
  const theme = useTheme();
  const sexValue = useAppSelector((state) => state.filter.filters.sex.value);
  const indicatorValue = useAppSelector((state) => state.filter.filters.indicator.value);
  const indicator = useMemo(() => t(`filters:indicator.values.${indicatorValue}`), [indicatorValue, i18n.language]); // eslint-disable-line react-hooks/exhaustive-deps
  const sex = useMemo(() => t(`filters:sex.values.${sexValue}`), [sexValue, i18n.language]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SStatusDiv>
      <SIndicatorTypography>{indicator}</SIndicatorTypography>
      <SDividerSpan theme={theme} />
      <SSexTypography>{sex}</SSexTypography>
    </SStatusDiv>
  );
};

Status.displayName = "Status";
export default Status;
