import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";

export const SStatusDiv = styled("div")({
  width: "100%",
  height: "1.5rem",
  textTransform: "uppercase",
  textAlign: "center",
  fontSize: "1.2rem",
});

export const SIndicatorTypography = styled(Typography)({
  display: "inline-flex",
  width: "calc(50% - 0.05rem)",
  height: "1.2rem",
  justifyContent: "center",
  verticalAlign: "bottom",
});

export const SDividerSpan = styled("span")(({ theme }: { theme: any }) => ({
  width: "0.1rem",
  height: "1rem",
  backgroundColor: theme.palette.text.primary,
  display: "inline-flex",
  justifyContent: "center",
  verticalAlign: "middle",
}));

export const SSexTypography = styled(Typography)({
  display: "inline-flex",
  width: "calc(50% - 0.05rem)",
  height: "1.2rem",
  justifyContent: "center",
  verticalAlign: "bottom",
});
