import { useEffect, useRef, useState } from "react";
import { createBrowserHistory } from "history";
import { useTranslation } from "react-i18next";
import Slide from "@mui/material/Slide";
import Fade from "@mui/material/Fade";
import Panel from "./Panel";
import DesktopView from "./Views/DesktopView";
import MobileView from "./Views/MobileView";
import * as CancerAtlasActionsThunk from "../../redux/features/cancerAtlasSlice/thunk";
import * as UIActions from "../../redux/features/uiSlice";
import * as GlobeActions from "../../redux/features/globeSlice";
import * as URIActionsThunk from "../../redux/features/uriSlice/thunk";
import * as URIActions from "../../redux/features/uriSlice";
import { useAppDispatch, useAppSelector } from "../../redux/types";
import { useParams } from "react-router";
import { useTheme } from "@mui/material/styles";
import { SContainerDiv, SLoadingSequenceDiv, SLoadingTypography, SExpandedDiv, SMobileDiv } from "./emotion";
import { t } from "i18next";
const COMPONENT_NAME = "stories";
const history = createBrowserHistory();

const RenderLoading = () => {
  return (
    <SContainerDiv>
      <SLoadingSequenceDiv>
        <SLoadingTypography variant="caption">{t("loading.data")}</SLoadingTypography>
      </SLoadingSequenceDiv>
    </SContainerDiv>
  );
};

const Stories = () => {
  const { i18n, t } = useTranslation();
  const { story } = useParams();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [panels, setPanels] = useState([]);
  const { visible, expanded, storyIndex, panelIndex } = useAppSelector((state) => state.ui[COMPONENT_NAME]);
  const { isMobile, screen } = useAppSelector((state) => state.ui);
  const storyData = useAppSelector((state) => state.cancerAtlas.stories);
  const storyLoading = useAppSelector((state) => state.cancerAtlas.loadingStories);
  const cancerData = useAppSelector((state) => state.cancerAtlas.cancerData);
  let storyIndexRef = useRef<any>(undefined);
  storyIndexRef.current = storyIndex;

  useEffect(() => {
    cancerData != null && storyData.length === 0 && !storyLoading && dispatch(CancerAtlasActionsThunk.requestStoryData({ locale: i18n.language }));
  }, [cancerData, storyData, storyLoading]);

  useEffect(() => {
    if (storyData.length > 0) {
      story && handleSlug(story);
    }
  }, [storyData, story]);

  useEffect(() => {
    return () => {
      setTimeout(() => {
        dispatch(UIActions.windowResize({ screen: { width: window.innerWidth + 1, height: window.innerHeight } }));
      }, 250);
    };
  }, []);

  useEffect(() => {
    if (storyData.length > 0 && storyIndex !== -1) {
      // Update Panels
      updatePanels();
      // Update Slug
      updateSlug(storyIndex);
      // Update Panel State
      applyPanelState(panelIndex);
    }
  }, [storyIndex, storyData]);

  useEffect(() => {
    if (storyData.length > 0) {
      applyPanelState(panelIndex);
    }
  }, [panelIndex, storyData]);

  useEffect(() => {
    if (storyData.length > 0) {
      story && handleSlug(story);
      handleOpen();
    }
  }, [story, storyData]);

  const handleOpen = () => {
    if (!visible) dispatch(UIActions.updateComponent({ name: COMPONENT_NAME, data: { visible: true } }));
  };

  const handleClose = () => {
    if (visible) {
      history.push(`/`);
      dispatch(UIActions.updateComponent({ name: COMPONENT_NAME, data: { expanded: false, visible: false } }));
      dispatch(URIActions.resetURIState());
    }
  };

  const handleSlug = (slug: string) => {
    // Get story index from slug
    const storyIndex = storyData.map((x) => x.slug).indexOf(slug);
    if (!isNaN(storyIndex) && storyIndex > -1) {
      // Update redux with valid story index
      dispatch(UIActions.updateComponent({ name: COMPONENT_NAME, data: { storyIndex, panelIndex: 0 } }));
    }
  };

  const updateSlug = (index: number) => {
    // Update slug if slug doesn't match new index
    const slug = storyData[index].slug;
    if (story !== slug) {
      history.push(slug);
    }
  };

  const handleResize = () => {
    if (!visible) dispatch(GlobeActions.resizeRequest());
    // Reapply panel state on entered (resized)
    if (visible && panelIndex !== 1) applyPanelState(panelIndex);
  };

  const updatePanels = () => {
    // Create Panel Components from Story Data
    let panelsLocal: any = [];
    panelsLocal = storyData[storyIndex].attributes.panels.map((panelData: any, index: number) => {
      return <Panel key={`panel_${index}`} index={index} panelData={panelData} />;
    });
    setPanels(panelsLocal);
  };

  const applyPanelState = (index: number) => {
    if (storyIndex !== -1 && !expanded) {
      const data = storyData[storyIndex].attributes.panels[index];
      if (data && data.appState) {
        dispatch(URIActionsThunk.applyStoryPanelState({ storyURI: data.appState, screen }));
      }
    }
  };

  if (storyIndex < 0) return <></>;

  return (
    <div id={"stories-container"}>
      {!isMobile ? (
        <Slide in={visible} direction="right" mountOnEnter unmountOnExit onEntered={handleResize} onExited={handleResize}>
          <SExpandedDiv>{panels.length < 1 ? <RenderLoading /> : <DesktopView panels={panels} handleOpen={handleOpen} handleClose={handleClose} />}</SExpandedDiv>
        </Slide>
      ) : (
        //Mobile View
        <Fade in={visible} mountOnEnter unmountOnExit onEntered={handleResize} onExited={handleResize}>
          <SMobileDiv className={"mobile"} theme={theme} expanded={expanded}>
            {panels.length < 1 ? <RenderLoading /> : <MobileView panels={panels} handleOpen={handleOpen} handleClose={handleClose} />}
          </SMobileDiv>
        </Fade>
      )}
    </div>
  );
};

export default Stories;
