import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/system";

export const SScrollAreaDiv: any = styled("div")({
  width: "calc(100% - 0.25rem)",
  height: "100%",
  position: "relative",
});

export const STable = styled(Table)({
  width: "calc(100% - 6px)",
  "& thead tr": {
    position: "sticky",
  },
  "& tbody": {
    overflowY: "auto",
    overflowX: "hidden",
  },
});

export const STableRow = styled(TableRow)({
  height: "1rem",
  scrollMarginTop: "40px",
  "& td": {},
  "&:last-child": {
    width: "10rem",
  },
  "&.selected": {
    padding: "1rem",
  },
});

export const SHeaderAndCellTableCell = styled(TableCell)(({ theme }: { theme: any }) => ({
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  position: "sticky",
  top: 0,
  padding: ".25rem",
  borderBottom: "0.05rem solid " + theme.palette.text.primary,
  textAlign: "center",
  "& h4": {
    textAlign: "left",
    fontSize: "0.95rem",
    lineHeight: "1rem",
  },
  "& div": {
    height: "0.75rem",
  },
  "&.selected": {
    fontSize: "0.9rem",
    "& h4": {
      color: theme.palette.text.hint,
      marginBottom: "0.5rem",
      textAlign: "left",
    },
    "& p": {
      fontSize: "0.8rem",
      textAlign: "left",
    },
    "& div": {
      width: "2.5rem",
      height: "2.5rem",
    },
  },
}));

export const SCellTableCell = styled(TableCell)(({ theme }: { theme: any }) => ({
  borderBottom: "0.05rem solid " + theme.palette.text.primary,
  padding: "0.25rem",
  textAlign: "center",
  "& h4": {
    textAlign: "left",
    fontSize: "0.95rem",
    lineHeight: "1rem",
  },
  "& div": {
    height: "0.75rem",
  },
  "&.selected": {
    fontSize: "0.9rem",
    "& h4": {
      color: theme.palette.text.hint,
      marginBottom: "0.5rem",
      textAlign: "left",
    },
    "& p": {
      fontSize: "0.8rem",
      textAlign: "left",
    },
    "& div": {
      width: "2.5rem",
      height: "2.5rem",
    },
  },
}));
