import { useTranslation } from "react-i18next";
import * as GlobeActions from "../../redux/features/globeSlice";

import * as GlobeActionsThunk from "../../redux/features/globeSlice/thunk";

import { useAppDispatch, useAppSelector } from "../../redux/types";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { css } from "@emotion/css";
import { SButton, SGpsButton, SGPSIcon, SNavigationDiv, SResetIcon, SZoomButton, SZoomInIcon, SZoomOutIcon } from "./emotion";
import React from "react";

interface NavigationProps {
  className?: string;
}
const Navigation = ({ className }: NavigationProps) => {
  const dispatch = useAppDispatch();
  const { map } = useAppSelector((state) => state.globe);
  const { isMobile } = useAppSelector((state) => state.ui);
  const { t } = useTranslation();
  const theme: any = useTheme();
  const TOOLTIP_STYLE = {
    tooltip: css(theme.components.tooltip.tooltip),
    tooltipPlacementTop: css(theme.components.tooltip.tooltipPlacementTop),
    tooltipPlacementBottom: css(theme.components.tooltip.tooltipPlacementBottom),
  };

  const zoom = (value: number) => {
    dispatch(GlobeActions.setZoomLevel(value));
  };

  const reset = () => {
    dispatch(GlobeActionsThunk.applyResetThunk());
  };

  const goToCurrentLocation = () => {
    if (navigator.geolocation)
      navigator.geolocation.getCurrentPosition((position) => {
        dispatch(GlobeActions.updateMap({ center: [position.coords.longitude, position.coords.latitude], zoom: 12 }));
      });
  };

  return (
    <SNavigationDiv className={className}>
      {!isMobile && (
        <Tooltip id={`navigation_btnZoomIn_tooltip`} title={t("navigation.zoom_in")} classes={TOOLTIP_STYLE} placement="top" arrow disableFocusListener>
          <SZoomButton aria-label={t("navigation.zoom_in")} onClick={() => zoom(1)}>
            <SZoomInIcon />
          </SZoomButton>
        </Tooltip>
      )}
      <Tooltip id={`navigation_btnReset_tooltip`} title={t("navigation.reset")} classes={TOOLTIP_STYLE} placement="top" arrow disableFocusListener>
        <SButton aria-label={t("navigation.reset")} onClick={reset}>
          <SResetIcon />
        </SButton>
      </Tooltip>
      {!isMobile && (
        <Tooltip id={`navigation_btnZoomOut_tooltip`} title={t("navigation.zoom_out")} classes={TOOLTIP_STYLE} placement="top" arrow disableFocusListener>
          <SZoomButton onClick={() => zoom(-1)} aria-label={t("navigation.zoom_out")}>
            <SZoomOutIcon />
          </SZoomButton>
        </Tooltip>
      )}
    </SNavigationDiv>
  );
};

export default Navigation;
