import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material/styles";
import { css } from "@emotion/css";
import * as URIActionsThunk from "../../redux/features/uriSlice/thunk";
import * as URIActions from "../../redux/features/uriSlice";
import * as UIActions from "../../redux/features/uiSlice";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/types";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const COMPONENT_NAME = "options";

const SCategoryLabelTypography = styled(Typography)(({ theme }: { theme: any }) => ({
  fontSize: "1.2rem",
  color: theme.palette.text.hint,
}));

const SEmailTextField = styled(TextareaAutosize)(({ theme }: { theme: any }) => ({
  display: "inline-block",
  overflow: "hidden",
  width: "100%",
  ...theme.components.border,
  backgroundColor: theme.palette.background.dark,
  color: theme.palette.primary.main,
  boxSizing: "border-box",
}));

const SCloseIconButton = styled(IconButton)(({ theme }: { theme: any }) => ({
  position: "absolute",
  right: "1rem",
  top: "1rem",
  color: theme.palette.text.primary,
}));

const OptionsMenu = () => {
  const component = useAppSelector((state) => state.ui[COMPONENT_NAME]);
  const { visible } = component;
  const { screen } = useAppSelector((state) => state.ui);
  const { uriObj } = useAppSelector((store) => store.uri);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme: any = useTheme();
  const [url, setUrl] = useState("");
  const [didCopy, setDidCopy] = useState(false);

  useEffect(() => {
    if (visible) {
      dispatch(URIActionsThunk.generateUri({ location: null, screen }));
    }
  }, [visible]);

  useEffect(() => {
    if (uriObj.location && uriObj.data) {
      var url = window.location.origin;
      url += "/#/@" + uriObj.location.center[1] + "," + uriObj.location.center[0] + "," + uriObj.location.zoom + "z" + "&data=" + uriObj.data;
      setUrl(url);
    }
  }, [uriObj.location, uriObj.data]);

  const handleClose = () => {
    dispatch(URIActions.setURLVisibility(false));
    dispatch(UIActions.updateComponent({ name: COMPONENT_NAME, data: { visible: false } }));
  };

  const TOOLTIP_STYLE = {
    tooltip: css(theme.components.tooltip.tooltip),
    tooltipPlacementTop: css(theme.components.tooltip.tooltipPlacementTop),
    tooltipPlacementBottom: css(theme.components.tooltip.tooltipPlacementBottom),
  };

  const copyText = (evt: React.MouseEvent) => {
    const target: HTMLTextAreaElement = evt.target as HTMLTextAreaElement;
    target.select();
    target.setSelectionRange(0, 99999); /* For mobile devices */
    /* Copy the text inside the text field */
    navigator.clipboard.writeText(target.value);
    setDidCopy(true);
  };

  return (
    <Dialog open={visible} aria-labelledby={"test"} fullWidth={true} maxWidth={"sm"}>
      <DialogTitle>
        {t(`share.shareBtn`)}
        <Tooltip id={`options_btnClose_tooltip`} classes={TOOLTIP_STYLE} title={t(`close`)} placement="top" disableFocusListener>
          <SCloseIconButton theme={theme} onClick={() => handleClose()} aria-label={t(`close`)}>
            <CloseIcon />
          </SCloseIconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <SCategoryLabelTypography theme={theme}>{t(`share.shareDefault`)}</SCategoryLabelTypography>
        <Tooltip
          id={`options_btnShareDefault_tooltip`}
          classes={TOOLTIP_STYLE}
          title={didCopy ? t(`share.copied`) : t(`share.copy`)}
          placement="top"
          disableFocusListener
          onClose={() => setDidCopy(false)}
        >
          <SEmailTextField theme={theme} value={window.location.origin} onClick={copyText} />
        </Tooltip>
        <SCategoryLabelTypography theme={theme}>{t(`share.sharePage`)}</SCategoryLabelTypography>
        <Tooltip
          id={`options_btnShareView_tooltip`}
          classes={TOOLTIP_STYLE}
          title={didCopy ? t(`share.copied`) : t(`share.copy`)}
          placement="top"
          disableFocusListener
          onClose={() => setDidCopy(false)}
        >
          <SEmailTextField theme={theme} value={url} onClick={copyText} />
        </Tooltip>
        <SCategoryLabelTypography theme={theme}>{t(`share.shareSocials`)}</SCategoryLabelTypography>
        <Tooltip classes={TOOLTIP_STYLE} title={t(`share.shareFacebook`)} placement="top" disableFocusListener>
          <IconButton color="primary" href={`https://www.facebook.com/sharer/sharer.php?u=${url}&p[title]=Kankeratlas`} target="_blank">
            <FacebookIcon />
          </IconButton>
        </Tooltip>
        <Tooltip classes={TOOLTIP_STYLE} title={t(`share.shareTwitter`)} placement="top" disableFocusListener>
          <IconButton color="primary" href={`https://twitter.com/intent/tweet?text=Kankeratlas&url=${url}`} target="_blank">
            <TwitterIcon />
          </IconButton>
        </Tooltip>
        <Tooltip classes={TOOLTIP_STYLE} title={t(`share.shareLinkedIn`)} placement="top" disableFocusListener>
          <IconButton color="primary" href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`} target="_blank">
            <LinkedInIcon />
          </IconButton>
        </Tooltip>
      </DialogContent>
    </Dialog>
  );
};

export default OptionsMenu;
