import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import TableBody from "@mui/material/TableBody";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { css } from "@emotion/css";
import { OrderTypes } from "../../../../types";
import { DataPieceType } from "../../../../types";
import { SCellTableCell, SHeaderAndCellTableCell, STable, STableRow } from "./emotion";

interface ListTableProps {
  selected: number;
  data: Array<DataPieceType>;
  boxes: Array<Array<JSX.Element>>;
  indicatorIndex: number;
  orderIndex: string;
  order: OrderTypes;
  handleSort: Function;
  cancerGroups: any;
  sa2: string | undefined;
}

const ListTable = ({ selected, data, boxes, indicatorIndex, orderIndex, order, handleSort, cancerGroups, sa2 }: ListTableProps) => {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const TOOLTIP_STYLE = {
    tooltip: css(theme.components.tooltip.tooltip),
    tooltipPlacementTop: css(theme.components.tooltip.tooltipPlacementTop),
    tooltipPlacementBottom: css(theme.components.tooltip.tooltipPlacementBottom),
  };

  let tableHead: any = [];
  let ind = 0;

  let tableBody = useMemo(
    () =>
      data.map((row) => {
        return (
          <STableRow key={`popop_row_${row.cancer_grp}`} selected={row.cancer_grp === selected} data-grp={row.cancer_grp}>
            <SCellTableCell theme={theme}>{boxes[indicatorIndex][row.cancer_grp]}</SCellTableCell>
            <SCellTableCell theme={theme} key={`cell_${row.cancer_grp}_${sa2}}`} className={row.cancer_grp === selected ? "selected" : ""}>
              <Typography component="h4" key="cancer_title">
                {cancerGroups?.find((x: any) => x.grp === row.cancer_grp)?.name}
              </Typography>
              {row.cancer_grp === selected && <p key="cancer_info">{row.content}</p>}
            </SCellTableCell>
          </STableRow>
        );
      }),
    [data, boxes, indicatorIndex, selected, cancerGroups, sa2]
  );

  tableHead = useMemo(
    () => (
      <>
        <Tooltip key={`popop_category_indicator_0`} id={`popup_category_0_tooltip`} classes={TOOLTIP_STYLE} title={t("popup.list.sort")} placement="top" arrow disableFocusListener>
          <SHeaderAndCellTableCell sortDirection={order === OrderTypes.ASC ? "asc" : "desc"} theme={theme}>
            <TableSortLabel active={orderIndex === "0"} direction={order === OrderTypes.ASC ? "asc" : "desc"} onClick={() => handleSort("0")}>
              {t(`filters:indicator.values.${"0"}`)}
            </TableSortLabel>
          </SHeaderAndCellTableCell>
        </Tooltip>
        <Tooltip key={`popop_category_${"cancer_grp"}_${1}`} id={`popup_category_${1}_tooltip`} classes={TOOLTIP_STYLE} title={t("popup.list.sort")} placement="top" arrow disableFocusListener>
          <SHeaderAndCellTableCell sortDirection={order === OrderTypes.ASC ? "asc" : "desc"} theme={theme}>
            <TableSortLabel active={orderIndex === "cancer_grp"} direction={order === OrderTypes.ASC ? "asc" : "desc"} onClick={() => handleSort("cancer_grp")}>
              {t("popup.cancer_header")}
            </TableSortLabel>
          </SHeaderAndCellTableCell>
        </Tooltip>
      </>
    ),
    [data, order, orderIndex]
  );
  return (
    <>
      <STable>
        <STableRow>{tableHead}</STableRow>
        <TableBody>{tableBody}</TableBody>
      </STable>
    </>
  );
};

export default ListTable;
