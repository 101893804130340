import { BREAKPOINTS } from "../../../constants";
import { useAppDispatch } from "../../../redux/types";
import * as UIActions from "../../../redux/features/uiSlice";
import { debounce } from "lodash";
import { useEffect } from "react";

export const useWindowResizeHook = () => {
  const dispatch = useAppDispatch();

  const handleResize = debounce(() => {
    if (window.innerWidth >= BREAKPOINTS.WIDTH && window.innerHeight >= BREAKPOINTS.HEIGHT) {
      dispatch(UIActions.updateIsMobile({ isMobile: false }));
    } else {
      dispatch(UIActions.updateIsMobile({ isMobile: true }));
    }
    dispatch(UIActions.windowResize({ screen: { width: window.innerWidth, height: window.innerHeight } }));
  }, 250);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
};
