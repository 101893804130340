///<reference types="chrome"/>

export enum ERROR_ACTIONS {
  REFRESH = "REFRESH",
  NONE = "NONE",
  TRIGGER = "TRIGGER",
  HOMEPAGE = "HOMEPAGE",
}

export enum OrderTypes {
  ASC = "ASC",
  DESC = "DESC",
}

export enum PopupOrigin {
  SCATTERPLOT = "SCATTERPLOT",
  MAP = "MAP",
}

export type DataPieceType = {
  cancer_grp: number;
  colorHex: string;
  colorIdx: number;
  createdAt: string;
  id: number;
  logp10: number;
  logp20: number;
  logp50: number;
  logp80: number;
  logp90: number;
  p10: number;
  p20: number;
  p50: number;
  p80: number;
  p90: number;
  pc: number;
  postcode: number;
  publishedAt: string;
  sex: number;
  updatedAt: string;
  v: number;
  xvalues: string;
  yvalues: string;
  content?: string;
};

export type CancerGroupPiece = {
  grp: number;
  name: string;
  pc3: boolean;
  pc4: boolean;
  validsex:number;
};

export type ScreenType = {
  width: number;
  height: number;
};
export type IndicatorType = {
  active: boolean;
  value: number;
  view: number;
  sex: number;
  pc: number;
};

export type StoryPanel = {
  abstract: string;
  appState: any;
  content: string;
  id: number;
  state: any;
  title: string;
};

export type StoryAttribute = {
  createdAt: string;
  locale: string;
  localizations: any;
  order: number;
  panels: Array<StoryPanel>;
  publishedAt: string;
  title: string;
  updatedAt: string;
};

export type StoryDataType = {
  id: number;
  slug: any;
  attributes: StoryAttribute;
};

export type CancerFilterType = {
  FieldName: string;
  Title: string;
  Type: string;
  loading: boolean;
  value: number;
  values: Array<CancerGroupPiece>;
};

export type PCFilterType = {
  FieldName: string;
  Title: string;
  Type: string;
  value: number;
  values: Array<{ Title: string }>;
};

export type ComponentType = {
  open?: boolean;
  draggable: boolean;
  expanded: boolean;
  mode?: number;
  id: number;
  order: number;
  pos: { x: number; y: number };
  visible: boolean;
};

export type SA2DataPieceType = {
  id: number;
  areacode: number;
  createdAt: string;
  geo: { pt: Array<number> };
  name: string;
  parent: number | null;
  province: string;
  publishedAt: string;
  updatedAt: string;
  wnpalt: string;
};

export type MatrixType = {
  scaleX: number;
  scaleY: number;
  skewX: number;
  skewY: number;
  translateX: number;
  translateY: number;
};

export type InteractablePieceType = {
  x: number;
  y: number;
  r: number;
  d: number;
};

export type IndicatorFilterType = {
  FieldName: string;
  Title: string;
  Type: string;
  value: number;
  values: Array<{ Id: number; Title: string; PropName: string; value: number }>;
};

export type SummaryDataPiece = {
  id: number;
  attributes: {
    exdthsyr?: number;
    exdthspct?: number;
    survmeet: number;
    avgct?: number;
    cancer_grp: number;
    createdAt: string;
    incasr: number;
    incmeet: number;
    pc: number;
    publishedAt: string;
    sex: number;
    updatedAt: string;
    year_grp: string;
  };
};

export type FilterType = {
  cancergrp: {
    FieldName: string;
    Title: string;
    Type: string;
    value: number;
    loading: boolean;
    values: Array<CancerGroupPiece>;
  };
  confidence: {
    FieldName: string;
    Title: string;
    Type: string;
    inverted: boolean;
    range: Array<number>;
    step: number;
    value: Array<number>;
  };
  diagnoses: {
    FieldName: string;
    Title: string;
    Type: string;
    inverted: boolean;
    range: Array<number>;
    step: number;
    value: Array<number>;
  };
  indicator: IndicatorFilterType;
  pc: PCFilterType;
  sex: {
    FieldName: string;
    Title: string;
    Type: string;
    value: number;
    values: Array<{
      Id: number;
      Title: string;
      PropName: string;
      value: number;
    }>;
  };
  transparency: {
    FieldName: string;
    Title: string;
    Type: string;
    value: boolean;
  };
};
